@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 100;
  src: local("HelveticaNeue-Thin"), url("/assets/fonts/HelveticaNeue-Thin.otf") format('opentype');
  font-display: block;
}

@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 200;
  src: local("HelveticaNeue-Ultralight"), url("/assets/fonts/HelveticaNeue-Ultralight.otf") format('opentype');
  font-display: block;
}

@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 300;
  src: local("HelveticaNeue-Light"), url("/assets/fonts/HelveticaNeue-Light.otf") format('opentype');
  font-display: block;
}
 
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 400;
  src: local("HelveticaNeue-Regular"), url("/assets/fonts/HelveticaNeue-Regular.otf") format('opentype');
  font-display: block;
}
@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"), url("/assets/fonts/HelveticaNeue.eot?#iefix") format('embedded-opentype'),
       url("/assets/fonts/HelveticaNeue.woff2") format('woff2'),
       url("/assets/fonts/HelveticaNeue.woff") format('woff'),
       url("/assets/fonts/HelveticaNeue.ttf")  format('truetype');
  font-display: block;
}

@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 400;
  src: local("HelveticaNeue-RegularItalic"), url("/assets/fonts/HelveticaNeue-RegularItalic.otf") format('opentype');
  font-display: block;
}

@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  src: local("HelveticaNeue-Medium"), url("/assets/fonts/HelveticaNeue-Medium.otf") format('opentype');
  font-display: block;
}

@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 700;
  src: local("HelveticaNeue-Bold"), url("/assets/fonts/HelveticaNeue-Bold.otf") format('opentype');
  font-display: block;
}