/** Custom CSS from Amanda **/


.pulseTable .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
    background: var(--primary-color-ultralight);
    color: var(--text-color);
}

.pulseTable .p-datatable-tbody>tr>td {
    text-align: left;
    /* border: 1px solid var(--medium-gray); */
    border-width: 0 0 1px 0;
    padding: 0rem;
    font-size: 14px;
    overflow: hidden;
}

/* Pagination */

.pagination {
    padding-top: 0;
    padding-bottom: 80px;
    color: #797979;
    font-size: 14px;
}

.pagination .flex-row {
    gap: 25px;
}

@media only screen and (max-width: 1023px) {
    .pagination .flex-row {
        flex-direction: column;
    }
}

.pagination .number-of-results {
    flex: 1 1 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .pagination .number-of-results {
        justify-content: center;
    }
}

.pagination .number-of-results select {
    width: 90px;
    border-radius: 100px;
    margin-left: 10px;
    border: 1px solid #CCCBCB;
    padding: 3px 8px;
    text-align: center;
    color: #797979;
}

.pagination .number-of-results select:focus {
    outline: 0;
    border: 1px solid #9957f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.pagination .paging {
    flex: 2 1 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-paginator-pages {
    background: #F5F5F5;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 24px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    color: #313131;
    font-weight: bold;
    font-size: 14px;
    min-width: 2rem;
    height: 2rem;
}

.pagination .paging .prev,
.pagination .paging .next,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
    color: #313131;
    text-decoration: none;
    padding: 2px 3px;
    border-radius: 50px;
    background: #F5F5F5;
    height: 30px;
    width: 30px;
    text-align: center;
    font-weight: bold;
}

.pagination .paging .prev:hover,
.pagination .paging .next:hover,
.p-paginator .p-paginator-first:hover,
.p-paginator .p-paginator-last:hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #fff;
    background: var(--primary-color-pulse);
}

.pagination .paging ul {
    list-style: none;
    margin: 0 10px;
    padding: 12px;
    background: #F5F5F5;
    border-radius: 50px;
}

.pagination ul li {
    display: inline-block;
    padding: 0;
    font-weight: bold;
}

.pagination ul li .extend {
    color: #a2a2a2;
}

.pagination ul li a {
    color: #313131;
    text-decoration: none;
    padding: 5px 9px;
    border-radius: 50px;
    background: #F5F5F5;
}

.pagination ul li a:hover,
.pagination ul li a.current {
    color: #fff;
    background: var(--primary-color-pulse);
}

.p-paginator .p-dropdown {
    border-radius: 24px !important;
}

.pagination .go-to-page {
    flex: 1 1 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.p-paginator .p-paginator-current {
    display: none;
}

.p-dialog .p-dialog-header {
    background-color: #260655 !important;
    color: #fff;
}

.p-dialog .form-label {
    display: table-row;
    font-size: 14px;
    font-weight: bold;
    margin: 10px auto;
    color: var(--text-color-dark);

}

.p-dialog .form-label-small {
    display: table-row;
    font-size: 12px;
    margin: 10px auto;

}

.p-dialog .form-input {
    display: table-cell;
    width: 100%;
    margin: 10px auto;

}

.p-dialog .form-input.half {
    display: table-cell;
    width: 50%;
    margin: 10px auto;

}

.p-datatable {
    margin: 0px auto;
    /* max-width: 95%; */
}

.p-datatable-filters table {
    margin: 0px auto;
    width: 95%;
    margin-bottom: 10px;
}

.p-datatable-filters {
    background: var(--primary-color-ultralight);
    /* color: var(--text-color); */
    color: var(--text-color-dark);
    border: 1px solid var(--primary-background-color);
    border-width: 2px 0 1px 0;
    padding: 0.85rem 1rem 0.5rem;
    font-weight: 600;
}

@media only screen and (max-width: 1023px) {
    .pagination .go-to-page {
        justify-content: center;
    }
}

.pagination .go-to-page input {
    width: 50px;
    border-radius: 100px;
    margin-left: 10px;
    border: 1px solid #CCCBCB;
    padding: 3px 8px 3px 6px;
    text-align: center;
    outline: 0;
}

.pagination .go-to-page input:focus,
.pagination .go-to-page input:active {
    border: 1px solid #9957f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}



/* Custom*/
body {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    font-family: var(--font-family-pulse);
    background: white !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    transition: .3s ease all;
}

.p-button:enabled:hover {
    background: #4b0ca5;
    border: 1px solid #4b0ca5;
}

.p-button {
    font-size: 14px;
    font-weight: 500;
    font-family: "HelveticaNeue";
}

#btnCargridTrash.p-button {
    background: white;
    color: var(--primary-color-pulse);
    border: none;
}

.left-right-padding {
    padding-left: 40px;
    padding-right: 40px;
}

@media only screen and (max-width: 600px) {
    .left-right-padding {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.container.main-wrapper {
    max-width: 100%;
    margin: auto;
    margin-top: 0;
}

.wrapper {
    width: 98%;
    max-width: 1300px;
    margin: 0 auto;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}


/* 47223: Pagination */

.pagination {
    padding-top: 0;
    padding-bottom: 80px;
    color: #797979;
    font-size: 14px;
}

.pagination .flex-row {
    gap: 25px;
}

@media only screen and (max-width: 1023px) {
    .pagination .flex-row {
        flex-direction: column;
    }
}

.pagination .number-of-results {
    flex: 1 1 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.pagination .p-paginator {
    padding: 0;
}

.pagination .number-of-results .p-paginator .p-paginator-first,
.pagination .number-of-results .p-paginator .p-paginator-prev,
.pagination .number-of-results .p-paginator .p-paginator-next,
.pagination .number-of-results .p-paginator .p-paginator-last,
.pagination .number-of-results .p-paginator-pages {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .pagination .number-of-results {
        justify-content: center;
    }
}

.pagination .number-of-results select {
    width: 90px;
    border-radius: 100px;
    margin-left: 10px;
    border: 1px solid #CCCBCB;
    padding: 3px 8px;
    text-align: center;
    color: #797979;
}

.p-paginator .p-dropdown {
    width: 105px;
    border-radius: 100px;
    margin-left: 10px;
    /* border: 1px solid #CCCBCB; */
    border: 1px solid #e0e0e0;
    padding: 0;
    text-align: center;
    color: #797979;
    height: 30px;
}

.p-dropdown .p-dropdown-label {
    padding: 10px;
    font-size: 14px;
    height: 40px !important;
    color: #797979;
}

.p-paginator .p-dropdown .p-dropdown-label:after {
    content: '/page'
}

.pagination .number-of-results select:focus {
    outline: 0;
    border: 1px solid #9957f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.pagination .p-dropdown-panel {
    background: var(--primary-background-color);
    color: var(--text-color);
    border: 0 none;
    border-radius: 3px;
    z-index: 99999999999999999999 !important;
    box-shadow: 0 0px 5px rgb(0 0 0 / 15%);
}

.pagination .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: #797979;
    text-align: left;
}

.pagination .p-dropdown-panel .p-dropdown-items .p-dropdown-item span:after {
    content: '/page';
}

.pagination .paging {
    flex: 2 1 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pagination .paging .prev,
.pagination .paging .next {
    color: #313131;
    text-decoration: none;
    padding: 5px 9px;
    border-radius: 50px;
    background: #F5F5F5;
    height: 40px;
    width: 40px;
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
}

.pagination .paging .prev:hover,
.pagination .paging .next:hover {
    color: #fff;
    background: var(--primary-color-pulse);
}

.p-paginator-pages {
    padding: 8px 10px;
    background: #F5F5F5;
    border-radius: 50px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #313131;
    font-weight: bold;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 23px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background-color: #F5F5F5;
    color: #313131;
    min-width: 3.7rem;
    height: 3.7rem;
    margin: 0.5rem;
    border-radius: 50px;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: var(--primary-color-pulse);
    color: #fff;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
    display: none;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    color: #fff;
    background: var(--primary-color-pulse);
}

.pagination .go-to-page {
    flex: 1 1 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .pagination .go-to-page {
        justify-content: center;
    }
}

.pagination .go-to-page input {
    width: 50px;
    border-radius: 100px;
    margin-left: 10px;
    border: 1px solid #CCCBCB;
    padding: 3px 8px 3px 6px;
    text-align: center;
    outline: 0;
}

.pagination .go-to-page input:focus,
.pagination .go-to-page input:active {
    border: 1px solid #9957f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.pagination .p-paginator-icon .pi-angle-right:before {
    content: '';
}

.p-dropdown:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover {
    border: 1px solid #9957f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
    background: #fff;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0;
}

.p-dropdown .p-dropdown-trigger {
    color: #9F9F9F;
}

.p-dropdown .p-dropdown-trigger span {
    font-size: 10px;
}

.p-dropdown:not(.p-disabled):hover .p-dropdown-trigger {
    color: var(--primary-color-pulse);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:not(.p-disabled):hover {
    background: #F5F5F5;
    color: #797979;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: #F5F5F5;
    color: #797979;
}



/* Styles for Theme: List Grid */

.cardGrid {
    gap: 30px;
}

.cardGrid .card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    border: none;
}

.cardGrid .card .cardTitleLink {
    display: block;
    text-decoration: none;
    transition: .3s ease all;
    background: var(--primary-color-ultralight);
    border-radius: 5px 5px 0 0;
}

.cardGrid .card .cardTitleLink:hover {
    background: var(--primary-color-pulse);
}

.cardGrid .card .cardTitleLink .cardTitle {
    display: flex;
    flex-direction: column;
    height: 100px;
    padding: 20px;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 1.4em;
}

.cardGrid .card .cardTitleLink .cardTitle span {
    font-weight: bold;
    color: var(--primary-color-pulse);
    text-align: center;
}

.cardGrid .card .cardTitleLink:hover .cardTitle span {
    color: #fff;
}

.cardThumbnail {
    padding: 20px;
}

.cardThumbnail .cardThumbnailLink {
    height: 165px;
    width: 100%;
    background-color: #f5f5f5;
    display: block;
    text-decoration: none;
}

.cardThumbnail .cardThumbnailLink .previewOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgba(39, 105, 172, .9);
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease all;
}

.cardThumbnail .cardThumbnailLink .previewOverlay .pi {
    font-size: 20px;
}

.cardThumbnail .cardThumbnailLink:hover .previewOverlay {
    opacity: 1;
    visibility: visible;
}

.cardName {
    padding: 0 20px 10px 20px;
}

.cardName a {
    font-size: 18px;
    line-height: 1.4em;
    font-weight: bold;
    color: var(--primary-color-pulse);
    text-decoration: none;
}

.cardName a:hover {
    color: #4b0ca5;
}

.cardMeta {
    padding: 0 20px 15px 20px;
    gap: 10px;
    display: flex;
    color: #AAAAAA;
}

.cardMeta .numberMessages {
    padding-left: 5px;
    text-decoration: none;
    color: #919191;
}

.cardMeta .numberMessages:hover {
    color: var(--primary-color-pulse);
}

.cardGrid .card .cardDescription {
    padding: 25px 20px 20px 20px;
    color: #7F7F7F;
    font-size: 16px;
    line-height: 1.5em;
}

.cardGrid .card .cardDescription p {
    overflow-y: auto;
}

.cardGrid .cardCard .cardDescription p {
    margin: 0;
}

.cardGrid .card .cardActions {
    padding: 0 20px 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
}

.cardGrid .card .cardActions .p-button.p-button-secondary {
    padding: 4px 18px 3px;
    border: 1px solid var(--primary-color-pulse);
    border-radius: 50px;
    color: var(--primary-color-pulse);
    text-decoration: none;
    transition: .3s ease all;
    font-size: 14px;
    font-weight: 500;
    height: 31px;
    min-height: 31px;
    max-height: 31px;
}

.cardGrid .card .cardActions .p-button.p-button-secondary:hover {
    background: var(--primary-color-pulse);
    color: #fff;
}

.cardGrid .card .cardActions .p-button.p-button-secondary.blue {
    border: 1px solid var(--primary-color-pulse);
    background-color: var(--primary-color-pulse);
    color: #fff;
}

.cardGrid .card .cardActions .p-button.p-button-secondary.blue:hover {
    border: 1px solid #4b0ca5;
    background-color: #4b0ca5;
    color: #fff;
}

.cardGrid .cardCard .cardActions .p-button-icon-only {
    background: transparent;
    color: var(--primary-color-pulse);
    border: none;
}

.cardGrid .card .cardActions .p-button-icon-only:hover {
    background: var(--primary-color-pulse);
    color: #fff;
}

.cardGrid .card .cardActions .p-button-icon-only .pi {
    font-size: 1.25rem;
}

.cardGrid .card .cardActions a.button:hover {
    background: var(--primary-color-pulse);
    color: #fff;
}


/* Styles for Theme: Message & Campaign Templates */

.message-campaign-templates {
    padding: 40px 0;
}

.template-categories {
    flex: 1;
}

.template-categories h2 {
    font-size: 18px;
    color: #2E2E2E;
    margin-top: 0;
}

.template-categories .p-menu {
    padding: 0;
    color: var(--text-color);
    border-left: 0 0 0 1px #D6D6D6 solid;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
    margin: 20px 0 25px;
}

.template-categories .p-menu .p-menuitem-link {
    padding: 0.75rem 1rem;
    border-left: 3px solid #fff;
    transition: .3s ease all;
}

.template-categories .p-menu .p-menuitem-link:hover {
    padding: 0.75rem 1rem;
    border-left: 3px solid #028292;
    background: #fff;
}

.template-categories .p-menu .p-menuitem-link span {
    color: #2E2E2E;
    font-weight: normal;
}

.template-categories .p-menu .p-menuitem-link:hover span {
    color: #028292 !important;
}

.template-categories button {
    color: var(--primary-color-pulse);
    background: #fff;
    border: 1px solid var(--primary-color-pulse);
}

.template-categories button:hover {
    color: #fff;
    background: var(--primary-color-pulse) !important;
    border: 1px solid var(--primary-color-pulse) !important;
}

.template-listings {
    flex: 4;
}

.template-listings .cardGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 0;
}

.template-listings .cardGrid .card {
    display: flex;
    flex-direction: column;
    flex: 1 0 30%;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
}

@media only screen and (max-width: 768px) {
    .cardGrid .card {
        flex: 1 0 47%;
    }
}



/* Footer */

footer {
    background: #F5F5F5;
    /* This color needs to be added as a variable */
    padding: 15px 0;
    text-align: center;
    font-size: 12px;
}


/* Page Title */

.page-title {
    background: #F5F5F5;
    padding-top: 34px;
    padding-bottom: 34px;
}

.page-title .flex-row {
    gap: 30px;
    justify-content: space-between;
}

.page-title .title-column {
    display: flex;
    flex-direction: column;
}

.page-title .title-phone-wrapper {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    padding-bottom: 8px;
}

.page-title .title-phone-wrapper .title {
    color: #6F767E;
}

.page-title .title-phone-wrapper .phone {
    text-decoration: none;
    color: var(--primary-color-pulse);
    transition: .3s ease all;
}

.page-title .title-phone-wrapper .phone:hover {
    text-decoration: underline;
}

.page-title .label {
    font-size: 14px;
    color: #7F7F7F;
    display: block;
}

.page-title .title-column .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.page-title .title-column .title-wrapper .search-wrapper {
    margin-top: 6px;
}

.page-title .title-column h1 {
    margin: 8px 20px 8px 0;
    color: #2E2E2E;
}

.page-title .title-wrapper .p-input-icon-right>i:last-of-type {
    right: 14px;
    color: var(--text-color);
    top: 17px;
}

.page-title .title-column .title-wrapper input {
    margin: 0;
    padding: 9px 10px 9px 13px;
    border-radius: 50px;
    width: 27.5em;
    border: 1px solid #E0E0E0;
    font-size: 1rem;
}

.page-title .title-column .title-wrapper input:focus {
    border: 1px solid #9957f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.page-title .title-column .title-wrapper .filters {
    border: 1px solid #E0E0E0;
    margin-left: 10px;
    color: #797979;
    font-weight: normal;
}

.page-title .title-column .title-wrapper .filters:hover,
.page-title .title-column .title-wrapper .filters:focus {
    background: var(--primary-color-pulse);
    color: #fff;
    border-color: var(--primary-color-pulse);
    box-shadow: none;
}

.page-title .title-column .title-wrapper .filters span {
    color: #797979;
}

.page-title .title-column .title-wrapper .filters:hover span,
.page-title .title-column .title-wrapper .filters:focus span {
    color: #fff;
}

.page-title .details-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
}

.page-title .details-column .detail-set {
    display: flex;
    flex-direction: column;
}

.page-title .details-column .detail-set span {
    display: block;
    width: 100%;
}

.page-title .details-column .detail-set .detail {
    font-size: 15px;
    color: #2E2E2E;
    padding-top: 8px;
}

.page-title .actions-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.page-title .actions-column .switch {
    font-weight: bold;
    padding-right: 10px;
    margin-top: -2px;
}

.page-title .actions-column .upload {
    width: 2.5rem;
}

.page-title .actions-column .p-button-label {
    white-space: nowrap;
}

.contact-detail-body {
    padding-top: 5px;
    padding-bottom: 80px;
}

.contact-detail-body .wrapper {
    gap: 65px;
    justify-content: space-between;
}

.contact-detail-body .details-sidebar {
    flex: 1;
}

.contact-detail-body .details-sidebar span {
    width: 100%;
    display: block;
}

.contact-detail-body .details-sidebar .contact-since {
    padding-bottom: 20px;
    color: #6F767E;
    font-style: italic;
}

.contact-detail-body .details-sidebar .label {
    color: #6F767E;
    font-size: 13px;
    padding-bottom: 6px;
}

.contact-detail-body .details-sidebar .detail-set {
    padding-bottom: 20px;
}

.contact-detail-body .details-sidebar .p-inputtext,
.contact-detail-body .details-sidebar .p-dropdown {
    width: 90%;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #D5DEE6;
    background: #F5F5F5;
    font-size: 16px;
    color: #2E2E2E;
    padding: 0 0 3px 0;
    min-height: auto;
}

.contact-detail-body .details-sidebar .p-dropdown {
    box-shadow: none;
}

.contact-detail-body .details-sidebar .p-inputtext:hover,
.contact-detail-body .details-sidebar .p-inputtext:focus,
.contact-detail-body .details-sidebar .p-dropdown:hover,
.contact-detail-body .details-sidebar .p-dropdown:focus {
    border-bottom: var(--primary-color-pulse);
}

.contact-detail-body .details-sidebar .p-dropdown:hover {
    box-shadow: none;
    border-bottom: 1px solid var(--primary-color-pulse);
}

.contact-detail-body .details-sidebar .p-dropdown .p-dropdown-trigger-icon {
    text-align: center;
}

.contact-detail-body .details-sidebar .p-input-icon-right>i {
    margin-top: -10px;
}

.contact-detail-body .details-sidebar .p-input-icon-right:hover .p-inputtext {
    border-bottom: 1px solid var(--primary-color-pulse);
}

.contact-detail-body .details-sidebar .p-inputtext:enabled:focus {
    box-shadow: none;
    border-bottom: 1px solid var(--primary-color-pulse);
}

.contact-detail-body .details-sidebar .p-input-icon-right i {
    visibility: hidden;
    opacity: 0;
    transition: .3s ease all;
    color: var(--primary-color-pulse);
}

.contact-detail-body .details-sidebar .p-input-icon-right:hover i {
    visibility: visible;
    opacity: 1;
}

.contact-detail-body .details-sidebar .p-panel {
    text-align: left;
}

.p-panel .p-panel-header .p-panel-header-icon {
    color: var(--primary-color-pulse);
}

.p-panel .p-panel-header .p-panel-header-icon {
    color: var(--primary-color-pulse);
    border: none;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: var(--primary-color-pulse);
    border: none;
    background: transparent;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
    box-shadow: none;
}

.contact-detail-body .details-body-main {
    flex: 2.5;
    padding-top: 20px;
}

.contact-detail-body .details-body-main .stats-wrapper {
    gap: 30px;
    justify-content: space-between;
}

.contact-detail-body .details-body-main .stat {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    gap: 7px;
}

.contact-detail-body .details-body-main .stat .label {
    color: #505050;
}

.contact-detail-body .details-body-main .stat .result {
    color: #028292;
    font-size: 28px;
    font-weight: bold;
}

.contact-detail-body .details-body-main .stat .result:hover {
    color: var(--primary-color-pulse);
}

.contact-detail-body .details-body-main .message-history {
    padding-top: 15px;
}

.contact-detail-body .details-body-main .message-history h2 {
    color: #2E2E2E;
    font-size: 22px;
}

/* 46193: Note Log */

.contact-detail-body.notes {
    padding-top: 40px;
}

.contact-detail-body.notes .wrapper {
    gap: 100px;
}

.contact-detail-body .save-note {
    flex: 1;
}

.contact-detail-body .save-note h2 {
    margin: 0 0 20px;
    color: #2E2E2E;
}

.contact-detail-body .save-note textarea {
    width: 100%;
    margin-bottom: 20px;
    min-height: 200px;
}

.contact-detail-body .note-log {
    flex: 1.4;
}

.contact-detail-body .note-log input {
    margin: 0;
    padding: 9px 10px 9px 13px;
    border-radius: 50px;
    width: 300px;
    border: 1px solid #E0E0E0;
    font-size: 1rem;
}

.contact-detail-body .note-log input:focus {
    border: 1px solid #9957f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}


/* Save Bar */

.save-bar {
    background-color: #fff;
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .15);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
}

.save-bar .p-button.p-button-link:enabled {
    color: #2E2E2E;
    transition: .3s ease all;
    border: none;
    text-decoration: underline;
    margin-left: 10px;
    font-weight: normal;
}

.save-bar .p-button.p-button-link:enabled:hover {
    color: var(--primary-color-pulse);
    border: none;
}



/* Theme Styles: Secondary Nav */

.secondary-nav {
    border-bottom: none;
    max-height: 50px;
    min-height: 50px;
    padding-top: 17px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .08);
}

/* Theme Styles: Toggle Switch */
/* .p-inputswitch:not(p-inputswitch-checked):not(.p-disabled) .p-inputswitch-slider,.p-inputswitch.p-disabled:not(p-inputswitch-checked) .p-inputswitch-slider {
    background: #C3C3C3 !important;
} */

/* .p-component:disabled, .p-disabled{
    opacity: 1 !important;
} */

.p-inputswitch .p-inputswitch-slider {
    border-radius: 30px;
    border: none;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: var(--primary-color-pulse);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled) .p-inputswitch-slider::before {
    left: -3px;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.4rem);
}

/* Theme Styles: Contact Sidebar Panel */

.p-panel .p-panel-header {
    border: none;
    background: #F5F5F5;
    color: #2E2E2E;
    font-size: 18px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 15px 20px;
    margin-top: 20px;
}

.p-panel.p-panel-toggleable {
    border: none;
}

.p-panel .p-panel-content {

    background: #ffffff;
    border: none;
    align-items: center;
    text-align: center;
    padding: 15px 3px;
    /* box-shadow: 0 0 5px rgb(0 0 0 / 11%); */
    border-radius: 5px;
    min-height: 90px;
    max-height: 90px;
}



/* Styles for Theme: Template Preview */

.backToTemplates {
    border-bottom: 1px solid #D6D6D6;
    padding-top: 5px;
    padding-bottom: 5px;
}

.backToTemplates .p-button.p-button-link {
    padding: 0;
    color: var(--primary-color-pulse);
    border: none;
    transition: .3s ease all;
}

.backToTemplates .p-button.p-button-link:hover {
    color: #4b0ca5;
    border: none;
}

.backToTemplates .p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: none;
}

.template-details {
    flex: 1;
    background: #f5f5f5;
    padding: 40px;
    max-height: 800px;
    overflow-y: scroll;
}

.template-details h2 {
    color: #2E2E2E;
    margin: 5px 0 15px;
}

.template-details .p-button.p-button-secondary {
    margin-bottom: 40px;
    background: #f5f5f5;
}

.template-details .p-button.p-button-secondary:hover {
    background: var(--primary-color-pulse);
    color: #fff;
}

.template-details .campaignDetails {
    border: 1px solid #D1D1D1;
    border-radius: 3px;
}

.template-details .campaignMeta {
    padding: 0 20px 20px 2px;
    gap: 10px;
    display: flex;
    color: #919191;
}

.campaignMeta .numberMessages {
    padding-left: 5px;
    color: #643a3a;
}

.template-details .campaignDetails h3 {
    margin: 0;
    padding: 15px 20px;
    border-bottom: 1px solid #D1D1D1;
    color: #2E2E2E;
}

.template-details .detailsContainer {
    padding: 15px 20px;
}

.template-details .detailsContainer span {
    width: 100%;
    display: block;
}

.template-details .detailsContainer .label {
    color: #7F7F7F;
    font-size: 14px;
    padding-bottom: 5px;
}

.template-details .detailsContainer .detail {
    color: #2E2E2E;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: bold;
}

.template-message-preview {
    display: flex;
    justify-content: center;
    flex: 3;
    padding: 40px;
    max-height: 800px;
    overflow-y: scroll;
}

.template-message-preview .preview-container {
    width: 100%;
    max-width: 600px;
}

.template-message-preview .preview-container .navigator {
    justify-content: space-between;
    align-items: center;
}

.template-message-preview .preview-container .navigator .pager {
    color: #919191;
}

.template-message-preview .preview-container .navigator .p-button.p-button-link:enabled {
    color: var(--primary-color-pulse);
    text-decoration: none;
    transition: .3s ease all;
    border: none;
}

.template-message-preview .preview-container .navigator .p-button.p-button-link:enabled:hover {
    color: #4b0ca5;
    border: none;
}

.template-message-preview .preview-container .navigator .p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: none;
}

.template-message-preview .preview-container .messageDetails {
    margin-bottom: 15px;
}

.template-message-preview .preview-container .messageDetails h4 {
    color: #2E2E2E;
    font-size: 18px;
    padding-bottom: 15px;
    border-bottom: 1px solid #D8D8D8;
}

.template-message-preview .preview-container .messageDetails div {
    padding-bottom: 10px;
}

.template-message-preview .preview-container .messageDetails span {
    color: #797979;
    font-size: 15px;
}

.template-message-preview .preview-container .messageDetails .label {
    font-weight: bold;
}

.template-message-preview .preview-container .messagePreview {
    width: 100%;
    height: 1200px;
    background: #F5F5F5;
}

.preview .previewActions {
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid #DEDEDE;
}

.preview .previewActions .p-button.p-button-link:enabled {
    color: #2E2E2E;
    transition: .3s ease all;
    border: none;
    text-decoration: underline;
    margin-right: 10px;
}

.preview .previewActions .p-button.p-button-link:enabled:hover {
    color: var(--primary-color-pulse);
    border: none;
}

.preview .previewActions .p-button.p-button-link:enabled .p-button-label {
    color: #2E2E2E;
    font-weight: normal;
    text-decoration: underline;
}

.preview .previewActions .p-button.p-button-link:enabled:hover .p-button-label {
    color: var(--primary-color-pulse);
    font-weight: normal;
    text-decoration: underline;
}


/* 47115: No Messages */

.noMessages {
    padding: 60px 40px;
    font-style: italic;
    text-align: center;
    font-size: 15px;
}

/** Form Input **/
.form-section-gray {
    background: var(--ultralight-gray);
    padding: 20px;

}

.form-section div.p-dropdown {
    border-radius: 0px !important;
    border: 1px solid var(--light-gray);

}

.form-section .p-inputtext {
    border: 1px solid var(--light-gray);
    border-radius: 0px !important;
}

.form-section {
    padding: 20px;
    border-top: .5px solid var(--light-gray);
}

.form-section .instruction-text {
    color: var(--medium-gray);
    font-size: 10px;
    font-style: italic;
}

.form-section .control-label {
    color: var(--text-color-dark);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    padding-top: 10px;
}

.form-section-title {
    color: var(--text-color);
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.form-section .control-div.buttons {
    padding-top: 10px !important;
}

/*------------added June 03/2022 -------------------*/
.btn-primary {
    border-radius: 50px;
    padding: 10px 20px;

}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.no-focus {
    box-shadow: none !important;
}

.tag-thumbnail {
    display: inline-block;
    width: auto;
    margin-bottom: 8px;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #1b96ca;
    border-radius: 10px;
    margin-right: 8px;
    font-weight: 500;
}

/*Add New Css*/
.btn-rounded {
    background-color: #6e11f2;
    border-radius: 50px;
    color: #fff;
    border: solid #fff 2px;
    padding: 5px 20px;
}

.title_campaign {
    color: #7F7F7F;
    font-size: 14px;
}

/* .campaign-card { width:400px;
} */
.text-blue {
    color: #0b3c7d;
}

#tableImage tbody td:nth-child(1) {
    width: 90px;
    min-width: 90px;
}

#tableImage td:nth-child(3) {
    text-transform: uppercase;
    width: 10%;
}

#tableImage td:nth-child(4) {
    width: 10%;
}

#tableImage td:nth-child(5) {
    width: 10%;
}

#tableImage td:nth-child(6) {
    width: 15%;
}

#tableImage td:nth-child(7) {
    width: 10%;
}

#tableImage td:nth-child(8) {
    width: 5% !important;
}

#tableImage .p-datatable-scrollable-header-box {
    padding-right: 0 !important;
}

.footer-section li {
    list-style: none;
    float: left;
}

.footer-section li a {
    list-style: none;
    text-decoration: underline;
    color: inherit;
    padding: 6px;
}

.footer-section ul {
    padding-left: 6px !important;
    float: left;
}

/*  button.p-button.p-component .pi {
    color: #fff;
    font-size: 16px;
}*/
@media (max-width:1660px) {
    #TemplateLibrary td:nth-child(1) {
        width: 17% !important;
        font-family: "HelveticaNeue";
        font-weight: 500;
        min-width: 150px;
    }
}

#TemplateLibrary td:nth-child(1) {
    /* width: 22%; */
    font-family: "HelveticaNeue";
    font-weight: 500;
    min-width: 150px;
}

#TemplateLibrary tbody td:nth-child(1) {
    /* width: 22%; */
    color: #6e11f2;
    font-family: "HelveticaNeue";
    font-weight: 500;
    min-width: 150px;
}

#TemplateLibrary td:nth-child(2) {
    width: 15%;
}

#TemplateLibrary td:nth-child(3) {
    width: 15%;
}

#TemplateLibrary td:nth-child(4) {
    width: 10%;
}

#TemplateLibrary td:nth-child(5) {
    width: auto;
}

.custom-modal .p-dialog-header {
    background-color: #fff !important;
}

.custom-modal p.confirm-sms {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
}

.droppos .ng-trigger.ng-trigger-overlayAnimation {
    top: 37px !important;
    left: 0 !important;
}

.content {
    padding-bottom: 10px;
}

.dropdown-toggle.menu-header.avatar-dropdown-menu-header:after {
    filter: inherit !important;
}


.custom-alert .p-dialog-header {
    background-color: white !important;
}

.custom-alert .p-dialog-header-close {
    position: absolute !important;
    right: 15px;
    top: 15px;
    color: #6e11f2;

}

.custom-alert .p-dialog-content {
    padding: 0 50px 50px;
    border-radius: 0 0 5px 5px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-35 {
    padding-bottom: 35px;
}

/* .pi-times:before
  {
    color: #6e11f2;
  } */

p-inputSwitch.inputSwithActive {
    top: 5px !important;
    height: 17px;
}

p-inputSwitch.inputSwithActive .p-inputswitch {
    height: 1.5rem;
}


.grid-section {
    margin-top: 120px;
}

.grid-section_new {
    margin-top: 6rem;
}

#imageLibraryDiv .p-datatable-scrollable-view {
    width: 100% !important;
}

.esdev-app .esdev-option-panel {
    z-index: 0 !important;
}

.p-paginator-right-content input.p-inputtext {
    border: solid #b1b1b1 1px !important;
    border-radius: 22px !important;
    height: 31px;
    min-height: 31px;
}

.p-paginator-right-content input.p-inputtext:hover {
    border: solid #6e11f2 1px !important;
    border-radius: 22px !important;
}

.p-paginator-right-content input.p-inputtext:focus {
    border: solid #6e11f2 1px !important;
    border-radius: 22px !important;
}

.p-disabled {
    pointer-events: none;
    /* border:solid red 1px; */
    opacity: 0.6;
}
.modal-title-copy {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: 500;
    border-bottom: solid 1px #dfdfdf;
    width: 100%;
    padding-bottom: 05px;
}

.p-dialog .p-dialog-footer {
    padding-top: 15px;
}

/* .footermodal{padding-top: 15px;} */

.confirm-modal .p-dialog-header {
    display: none;
}

.custom-alert .p-dialog-content {
    padding: 0 50px 50px;
    border-radius: 0 0 5px 5px;
}

.confirm-modal .p-dialog-content {
    padding: 0;
}

.mt-30 {
    margin-top: 30px;
}

/* @media screen and ( max-width:1600px) {
    #imageThumbnails{ max-height: 350px !important;}
    
} */
.dropdown-toggle.menu-header.active::after {
    filter: inherit !important;
}

#btnDuplicateCanpaign {
    margin-right: 10px;
}

#btnDeleteCanpaign {
    margin-right: 10px;
}

select.form-control {
    /* appearance:revert!important; */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background-position: calc(100% - 12px) center !important;
    background: url("/assets/images/grey-triangle.svg") no-repeat;

}

select.form-control:hover {
    /* appearance:revert!important; */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border: solid #6e11f2 1px !important;
    background-position: calc(100% - 12px) center !important;
    background: url("/assets/images/blue-triangle.svg") no-repeat;

}

.email-label {
    font-size: 16px;
    color: #2E2E2E;
    font-weight: bold;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

/* .col-form-label {
    font-weight: 700;
} */

.p-paginator .p-dropdown {
    left: 140px;
    position: absolute;
    margin-bottom: 2px;
    margin-top: -15px;
}

.search-fa {
    color: #818181 !important;
    font-weight: 500 !important;
}

#create-new-dropdown.dropdown-toggle::after {
    content: url('/assets/images/blue-triangle.svg') !important;
    display: inline-block;
    vertical-align: initial;
    margin-left: 4px;
    position: relative;
    top: -2px;
    width: 11px;
    height: 6px;
    border-top: 0px dashed !important;
    border-top: 0px solid !important;
    border-right: 0px solid transparent !important;
    border-left: 0px solid transparent !important;
}

#create-new-dropdown.dropdown-toggle:hover:after,
#create-new-dropdown.dropdown-toggle:focus:after,
#create-new-dropdown.dropdown-toggle:active:after {
    content: url('/assets/images/white-triangle.svg') !important;
    display: inline-block;
    vertical-align: initial;
    margin-left: 4px;
    border-top: 0px dashed !important;
    border-top: 0px solid !important;
    border-right: 0px solid transparent !important;
    border-left: 0px solid transparent !important;
}

.emoji-mart-scroll {
    max-height: 150px !important;
}

#create-new-dropdown:hover {
    border: solid #6e11f2 1px !important;
    background-color: #6e11f2;
    color: white;
}

#create-new-dropdown {
    color: #6e11f2;
    border-color: #6e11f2;
}

#create-new-dropdown:active {
    color: white !important;
    background: #6e11f2 !important;
}

#create-new-dropdown:focus {
    outline: none;
    box-shadow: none !important;
    color: white !important;
    background: #6e11f2 !important;
}

.disable-toggleed[disabled=true] .p-inputswitch-slider:before {
    left: -3px;
}

.link-text {
    margin-top: 7px;
    font-weight: 500;
}

/* .p-dialog .p-dialog-header .pi-times:before{color:#fff;} */
.link-text:hover {
    color: #6e11f2 !important;
}

a:hover {
    color: #6e11f2 ;
}
a {
    color: #6e11f2 ;
}
.pencil.fa {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.fa-comment-lines::before {
    color: #aaa;
    font-size: 17px;
}

.facomment::before {
    font-size: 22px;
    color: #6e11f2;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid #d7d7d7 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #6e11f2 !important;
    background: #6e11f2 !important;
}

.template-listings {
    background-color: #ffffff;
}

.template-listings .card {
    box-shadow: 0 0 5px rgb(0 0 0 / 15%);
}

.template-listings .card .facomment::before {
    font-size: 32px;
}

#create-new-dropdown {
    padding-bottom: 7px;
    background: white;
}

.pl-0 {
    padding-left: 0 !important;
}

.p-button:disabled {
    opacity: .5;
}


.p-paginator-rpp-options .p-inputtext {
    width: 100%;
    text-overflow: initial;
    padding: 3px 0.9rem !important;
}

.col-3 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-2 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-3 .p-hidden-accessible+.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-3 .p-hidden-accessible>.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-5 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-4 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-4 .p-hidden-accessible+.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-4 .p-hidden-accessible>.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-7 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.pt-13 {
    padding-top: 13px;
}

.radius4 button {
    border-radius: 4px !important;
}

.list_none li.p-chips-token .lhs,
.list_none li.p-chips-token .rhs {
    color: #6e11f2 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
}

.list_none li.p-chips-token .rhs {
    color: #6e11f2 !important;
}

.p-treenode-children {
    color: #6e11f2 !important;
}


.p-paginator-rpp-options .p-inputtext {
    width: 100%;
    text-overflow: initial;
    padding: 3px 0.9rem !important;
}
.font-weight-bold {
    font-weight: bold;
}

.col-3 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-2 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-3 .p-hidden-accessible+.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-3 .p-hidden-accessible>.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-5 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-4 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-4 .p-hidden-accessible+.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-4 .p-hidden-accessible>.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.col-7 span.p-dropdown-label.p-inputtext {
    padding: 10px !important;
}

.pt-13 {
    padding-top: 13px;
}

.radius4 button {
    border-radius: 4px !important;
}

.list_none li.p-chips-token .lhs,
.list_none li.p-chips-token .rhs {
    color: #6e11f2 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
}

.list_none li.p-chips-token .rhs {
    color: #6e11f2 !important;
}

#tagsChip li.p-chips-token .lhs,
#tagsChip li.p-chips-token .rhs {
    color: #6e11f2 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
}

#tagsChip li.p-chips-token .rhs {
    color: #6e11f2 !important;
}

.p-treenode-children {
    color: #6e11f2 !important;
}

.font-weight-bold {
    font-weight: bold;
}

/* .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon::before{content: "\e90b" !important;} */
.p-chips-token-icon {
    color: #6e11f2 !important;
    font-weight: 700;
    font-size: 13px;
    display: none;
}

.p-treenode-label {
    font-size: 14px;
    line-height: 34px;
}

.p-tree-toggler .pi-chevron-down {
    margin-top: -5px;
}

.member-section {
    color: #6e11f2;
    border-bottom: solid #a0a0a0 1px;
    width: 90%;
}

.text-primary {
    color: #6e11f2 !important;
}

#updateSubscription .modal-content li {
    font-weight: normal !important;
    margin-top: 7px;
    text-align: left;
}

#updateSubscription .modal-content .subscription-box-accessPre li {
    font-weight: normal !important;
    list-style: none;
    margin-top: 7px;
    text-align: left;
}

#updateSubscription .modal-content .subscription-box-accessLimit li {
    font-weight: normal !important;
    list-style: none;
    margin-top: 7px;
    text-align: left;
}

#updateSubscription .modal-content .p-dropdown {
    border-radius: 50px !important;
    border: 1px solid var(--primary-color-dark);
}

.font-weight-normal {
    font-weight: normal !important;
}

.font-18 {
    font-size: 18px !important;
}

#updateSubscription .modal-content ul.user-menu {
    padding-left: 17px !important;
    margin-top: 10px;
}

.ml-50 {
    margin-left: 50px;
}

#updateSubscription .modal-content .p-dropdown .p-dropdown-label {
    color: #6e11f2 !important;
}

.dropdown-menu a.dropdown-item {
    font-weight: 500 ;
}

/* #notification-dropdown .dropdown-menu a.dropdown-item {
    font-weight: 400 !important;
} */


/* Onboarding CSS */
.container-section {
    margin: 0 auto;
}
.middle-section{ height: 83vh;}
 .logo img {
    max-width: 110px;
}

.container-middle {
    height: calc(100vh - 26vh);
    overflow-y: auto;
    overflow-x: hidden;
   
}
.container-welcome {
    height: calc(100vh - 14vh) !important;
    overflow-y: auto;
    overflow-x: hidden;
    
}
.progress-wrapper .progress {
    max-height: 10px !important;
}

.container-middle::-webkit-scrollbar-thumb {
    background: #c1c1c1 !important;
    width: 8px;
    border-radius: 10px
}

.container-middle::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.container-middle ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.container-size {
    max-width: 800px;
    margin: 0 auto;

}

.footer-section-onbording {
    box-shadow: 5px 5px 10px 1px #000;
    position: absolute;
    width: 100%;
    bottom: 4px;
}


.container-middle::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.container-middle::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.container-middle::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #D62929;
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.current-email {
    background-color: #f5f5f5;
    margin-top: 15px;
    padding: 10px 15px;
    border-radius: 4px;
}

.test-span {
    padding: 0 7px;
}

.mt-two {
    margin-top: 2px;
}

.Signature-panel {
    min-height: 56px;
    margin-top: 30px;
}

.Signature-panel h4 {
    float: left;
    margin-top: 15px;
}

.inputSwitchActive {
    margin: 2px 15px 0px 6px;
}

.profileinput input.form-control {
    border-radius: 4px !important;
}


#subscriptionType .p-dropdown .p-dropdown-label {
    color: #6e11f2 !important;
    min-height: 32px !important;
    max-height: 32px !important;
    padding: 6px 12px !important;
}

#billingOwner .p-dropdown .p-dropdown-label {
    color: #6e11f2 !important;
    min-height: 32px !important;
    max-height: 32px !important;
    padding: 6px 12px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.gray-section {
    background-color: #f5f5f5;
    padding: 16px;
    border-radius: 4px;
}

h4 {
    color: #2e2e2e;
    font-size: 22px;
}

.radiobtn-custom {
    border-radius: 4px !important;
    color: #6e11f2;
    font-weight: bold;
    background-color: #fff;
    margin-right: 15px;
    max-width: 80px !important;
    border:solid #ccc 1px
}

.radiobtn-custom.active {
    background-color: #6e11f2;
    font-weight: bold;
    color: #fff !important;
}

.radiobtn-custom:hover {
    border-radius: 4px !important;
    border: #6e11f2 solid 1px !important;
    color: #fff !important;
    font-weight: bold;
    background-color: #6e11f2;
    margin-right: 15px;
    max-width: 80px !important;
}


.radiobtn-custom-cal {
    border-radius: 4px !important;
    color: #6e11f2;
    font-weight: bold;
    background-color: #fff;
    margin-right: 15px;
    max-width: 150px !important;
    margin-top: 15px;
    border:solid #ccc 1px;
    display: block;
}

.radiobtn-custom-cal.active {
    background-color: #6e11f2;
    font-weight: bold;
    color: #fff !important;
}

.radiobtn-custom-cal:hover {
    border-radius: 4px !important;
    border: #6e11f2 solid 1px !important;
    color: #fff !important;
    font-weight: bold;
    background-color: #6e11f2;
    margin-right: 15px;
    max-width: 150px !important;
    margin-top: 15px;
    display: block;
}
.divider-hor{ width: 100%; height: 1px; background-color: #ebebeb;}

.eyesection .inner-addon .far, .inner-addon .fas {
    position: absolute;
    padding: 16px 20px;
    z-index: 100;
    cursor: pointer;
    color: var(--primary-color-pulse);
  }
  .btn-premium{ background-color: #f4edfe; color: #6e1af2; padding: 5px 10px;
 border-radius: 4px; border:0; font-weight: 500; min-width: 130px;
}
.btn-limited{ background-color: #f5f5f5; color:#505050; padding: 5px 10px;
    border-radius: 4px; border:0; font-weight: 500; min-width: 130px;
   }
.requirements-text{ color: #848484; font-size: 15px;}
.profileinput input.form-control{ border-radius: 6px !important;}
.profileinput label{ font-size: 16px; font-weight: 600;}
.mt-10{ margin-top: 10px;}
.container-section label{ font-size: 16px;}
.cal-outer{ max-width: 800px;}

@media only screen and (max-width: 1440px) {
    .container-middle {
        height: calc(100vh - 30vh);
        overflow-y: auto;
        overflow-x: hidden;
       
    }
}

.tileBackground {
    background-color: #f4edfe;
    margin: 0px -21px;
    height: 100px;
    padding: 5px 23px;
}

.tileBackground label {
    color: #6E11F2;
    font-weight: bold;
}

#preview .p-dialog .p-dialog-content {
    padding: 0px !important;
}

.ti-text ul li {
    font-weight: 400;
    padding-left: 20px;
    list-style-position: inside;
}

.stripofixed {
    overflow-y: hidden;
}

body.stripofixed .footer-section {
    display: none;
}

 .full-height {
    height: calc(100vh - 215px) !important;
}

.esdev-preview-splitter.full-height.splitter_panel .stripo-preview-frame.top_panel {
    height: calc(100vh - 215px) ;
   
}
.stripo-preview-frame:not(.top_panel) {
    height: calc(100vh - 215px) !important;
 
}

#stripoSettingsContainer {
    height: calc(100vh - 215px) !important;
    overflow-y: auto;
}

.sticky-footer{
    position: fixed;
    width: 100%;
    background-color: #fff;
    left: 0;
    bottom: 32px;
    padding-left: 105px !important;
    border-top: solid #e6e6e6 1px;
    z-index: 9;
}

.footer-section{
    /* position: fixed; */
    width: 100%;
    border: 0;
    z-index: 9;
    bottom: -4px;
}
@media screen and (max-width: 1440px){
    .sticky-footer{
        padding-left: 75px !important;
    }
    }
.mb-100{ margin-bottom: 100px;}
#modalDivPanel + #divBody #modalfilter #chip-Section .show-more-height { height: auto!important;}
::ng-deep #disID .division.list_none ul.p-inputtext { max-width: 88%;}

#modalDivPanel + #divBody #modalfilter .rgtbrd{ border-right: 0 !important;}

.grey-background-card {
    background: var(--very-light-gray) !important;
    box-shadow: none;
}

#p-accordiontab-0-content > div, #p-accordiontab-1-content > div {
    border: none !important;
  }

.footer-section {
    box-shadow: 0px -2px rgba(0, 0, 0, 0.08);
    position: fixed;
}

.font-16-bold {
    font-size: 16px !important;
    font-weight: bold !important;
  }