/* You can add global styles to this file, and also import other style files */

@import "theme/primeicons.css";
@import "theme/material-icons-outlined.css";
@import "theme/pulseicons.css";
@import "theme/IconMoon.css";
@import "theme/helvetica-neue.css";
@import "theme/primeflex.css";
@import "theme/primeng.min.css";
@import "theme/theme.css";
@import "custom-styles.css";
@import "autocomplete.css";

@import url(../node_modules//quill/dist/quill.snow.css);
@import url(../node_modules//quill/dist/quill.core.css);

$fa-font-path : "../../font-awesome/fonts";
@import '../node_modules/font-awesome/scss/font-awesome.scss';

@import '~@ctrl/ngx-emoji-mart/picker';


@media screen and (min-width: 1280px) {
    .text-wrapper{
        width:1200px;
        margin-left: auto;
        margin-right:auto;
    }
  }
  @media screen and (min-width: 1440px) {
    .text-wrapper{
        width:1310px;
        margin-left: auto;
        margin-right:auto;
        
    }
  }

  @media screen and (min-width: 1920px) {
    .text-wrapper{
        width:1500px;
        margin-left: auto;
        margin-right:auto;
        
    }
  }

.second-header-fixed {
  position: fixed;
  top: 79px;
  z-index: 999;
  width: 100%;
}
.content.table-search-row.second-header-fixed.title_header {
  padding: 10px 40px!important;
}


.ico-pr-5 {
  margin-right: .5rem !important;
}
  .ico-pl-5{
    margin-left:.5rem !important;
  }

#externalMergeTags {
  .autoComplete_wrapper {
    width: 100%;

    #autoComplete {
      &::placeholder {
        color: #2E2E2E;
      }
    }
  }
}

.modal-backdrop {
  z-index: -1;
}

.p-inputtext{
  // min-height: 44px;
  // max-height: 44px;
  height: 44px;
  font-size: 16px;
  padding: 8px 10px;
}

#messageCategory .p-inputtext{margin: 0 !important;}
#messageCategory .p-dropdown .p-dropdown-label{padding: 10px !important;}
.btn:hover{border: none !important;}
.p-dialog .p-dialog-content{
  padding: 50px;
}
/* .p-dialog .p-dialog-footer button{
  margin: 0px 17.5px;
} */
.form-control{
  height: 45px;
  min-height: 45px;
  border:solid #c3c3c3 1px ;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
  background-image:none;
}
textarea.form-control:hover{

  border:solid #6e11f2 1px !important;
}

.progress-bar{
  background-color:  #6e11f2 !important;
}

.p-button-outlined, .p-button{
white-space: nowrap;
}

#preview .p-dialog-content{
  overflow: auto;
  background-color: #F5f5F5;
}
 #createMessageDialog .p-paginator-rpp-options .p-inputtext {
  padding: 3px 0.25rem !important;
  margin:0 !important;
 }

 #oneTime .p-button,#RepeaTing .p-button{
  border-radius: 3px !important;
  width: 145px;
  font-size: 16px !important;
}

/* #RepeaTing .p-button-label{
  color: #4b0ca5;
} */

#oneTime .p-button-label,#RepeaTing .p-button-label{ margin-left: 15px !important;}
/* #RepeaTing .p-button{
  background: white;
  border: 2px solid #4b0ca5;
} */

#RepeaTing.tabselected .p-button, #oneTime.tabselected .p-button{
  color: #6e11f2 !important;
  background: #fff !important;
  border: 2px solid #6e11f2;
}

#RepeaTing .fa-repeat, #oneTime .fa-paper-plane
{
  position: absolute;
z-index: 10;
left: 20px;}

.text-blue{
  color: #6e11f2 !important;
}


 .p-inputswitch {
  width: 32px !important;
  height: 17px !important;
}

.p-dialog .p-dialog-footer {
  padding: 30px 50px !important;
  display: flex;
  justify-content: end;
  align-items: center;
}

#campaignCategory .p-inputtext, #ddlStatus .p-inputtext, #divRepeatingComponenets .p-inputtext{margin: 0 !important;}

#campaign_details .p-dialog-content{
  overflow: auto;
}

#divRepeatingComponenets .p-dropdown{
  margin-bottom: 20px;
  margin-right: 0px !important;
  z-index: 100;
}

#delete_campaign .p-dialog-content, #upgradeSubscriptionDialog .p-dialog-content,#delete_user .p-dialog-content, #delete_camp .p-dialog-content{
  padding: 0 50px 50px !important;
}

#delete_campaign .p-dialog-header-close, #upgradeSubscriptionDialog .p-dialog-header-close, #delete_user .p-dialog-header-close, #delete_camp .p-dialog-header-close{
  right: 25px !important;
  top: 25px !important;
}

.alert-header,.confirm-sms,.p-dropdown .p-dropdown-label.p-placeholder, .all_Color {
  color: #2e2e2e !important;
}

.form-control:focus, .p-inputtext:enabled:focus{
  border-color: #9957f6;
  box-shadow:none !important;
}
input.form-control:hover
{ 
  border:solid #6e11f2 1px !important
}


.p-dropdown{
  border-radius: 2px;
  border: 1px solid #cccccc;
}

// #divOneTimeComponenets .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover .p-radiobutton-icon,
// #divOneTimeComponenets .p-radiobutton .p-radiobutton-box .p-radiobutton-icon,
// #divRepeatingComponenets .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover .p-radiobutton-icon,
// #divRepeatingComponenets .p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
//     width: 20px;
//     //height: 20px;
//     transition-duration: .2s;
//     background-color: #fff;
// }

// #divOneTimeComponenets .p-radiobutton .p-radiobutton-box .p-radiobutton-icon,
// #divRepeatingComponenets .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
//     border: 4px solid #fff;
//     box-shadow: 0 0 0 0.1rem #6e11f2;}

    #holiday-msg-schedule .p-inputtext, #holiday-time-schedule .p-inputtext{
      margin: 0px !important;
    }

#date-msg-schedule .pi-calendar:before, #startDate .pi-calendar:before,#endDate .pi-calendar:before,
#week_End_date .pi-calendar:before, #week_Start_date .pi-calendar:before {
  color: #6e11f2!important;
  font-size: 20px;
}

.back-button-new{
  color:#6e11f2;
  background: #fff;
}

#date-msg-schedule .p-button-icon-only,#startDate .p-button-icon-only,#endDate .p-button-icon-only,
#week_End_date .p-button-icon-only,#week_Start_date .p-button-icon-only {
  background: transparent;
  border: none;
  height: 45px;
  position: absolute;
  top: 5px;
}

#startDate .p-button-icon-only,#endDate .p-button-icon-only,
#week_End_date .p-button-icon-only,#week_Start_date .p-button-icon-only{
  top: 5px;
}

#date-msg-schedule .p-calendar-w-btn .p-inputtext,#startDate .p-calendar-w-btn .p-inputtext,
#endDate .p-calendar-w-btn .p-inputtext,#week_End_date .p-calendar-w-btn .p-inputtext,
#week_Start_date .p-calendar-w-btn .p-inputtext {
  padding-left: 35px;
}

#divOneTimeComponenets .pi-chevron-down:before, #divRepeatingComponenets .pi-chevron-down:before
{
  content:  url('/assets/images/blue-triangle.svg')!important;
}

.text-blue{
  color: #6e11f2 !important;
}

// #divOneTimeComponenets .p-radiobutton .p-radiobutton-box, 
// #divRepeatingComponenets .p-radiobutton .p-radiobutton-box
// {
//   box-shadow: 0 0 0 0.1rem #dfdfdf;
//   background: transparent;}

#ddlStatus .p-dropdown, #holiday-time-schedule .p-dropdown,
 #wait-to-send .p-dropdown, #specific-time-schedule .p-dropdown,
 #contact-time-schedule .p-dropdown, #holiday-msg-schedule .p-dropdown{
  margin-right: 0px !important;
  width:100%;
}

// .custom-dialog .p-inputtext{
//   margin: 8px 0px !important;
// }

.p-button.p-button-icon-only{
  border-radius: 5px;
}

#loanGrid .p-datatable .p-datatable-header{
  background: transparent !important;
  display: flex !important;
  justify-content: end !important;
  padding-right:0px !important;
}

.radius4 .p-button.p-button-icon-only,.btn-no-radius .p-button.p-button-icon-only{
  width: 40px !important;
}

#loanGrid .create-btn{
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #F4EDFE;
  color:var(--primary-color-pulse);
  font-weight: bold;
  position: relative;
  left: 28px;
  outline: none;
}
#loanGrid .create-btn:focus{
  outline: none;
  box-shadow: none;
}

#loanGrid .p-datatable-resizable>.p-datatable-wrapper{
  // overflow-x: hidden;


}


/* .p-calendar-new-class .p-calendar{
  margin-bottom: 10px !important;
  position: relative;
  top: 3px;
} */

#loanGrid .p-datatable .p-multiselect{
  border-radius: 50px;
}

#loanGrid .p-multiselect .p-multiselect-label.p-placeholder{
  font-size: 14px !important;
  line-height: 14px !important;
  color: #797979 !important;
}

.loan-status-type-new .p-calendar-new-class .p-calendar{
  margin-bottom: 10px !important;
  position: relative;
  top: 3px;
}

#loanGrid .table-actions .p-multiselect .p-multiselect-panel{
  z-index: 1 !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler .pi-chevron-down::before{
  content: url("/assets/images/blue-triangle.svg")!important;
}

/* #loanGrid .create-btn.dropdown-toggle:hover:after,  */
#loanGrid .create-btn:hover{
    color: #fff!important;
    background: #6e11f2!important;
  /* content: url("/assets/images/blue-triangle.svg")!important; */
}

#people_list_filter_new .p-new-added .p-calendar{
  margin-bottom: 5px;
}

// told by Varsha, remove if needed.

// .p-dropdown-panel{
//  z-index: 1 !important;

// }

// .p-multiselect-panel{
//   z-index: 1 !important;
// }
// .p-multiselect-label.p-placeholder{
//   z-index: 1 !important;
// }


// create new campaign 



// .grid-section-details .p-multiselect-label.p-placeholder {
//   z-index: 1 !important;
// }
.selected-item-container{
  word-wrap: break-word;
  word-break: break-all;
}

.p-inputswitch.p-disabled:not(p-inputswitch-checked) .p-inputswitch-slider {
  background: #C3C3C3;

}

.header-button{
  height:44px !important; min-height: 44px !important; max-height: 44px !important;
}

// .es-wrapper{
//   background-color: red;
// }

#loanGrid .p-datatable-resizable>.p-datatable-wrapper::-webkit-scrollbar {
  height: 6px;
}

/* Track */
#loanGrid .p-datatable-resizable>.p-datatable-wrapper::-webkit-scrollbar-track {
  border-radius: 9px;
  background: #f7f7f7;
}
 
/* Handle */
#loanGrid .p-datatable-resizable>.p-datatable-wrapper::-webkit-scrollbar-thumb {
  background: lightgray; 
  border-radius: 10px;
}

.filter_selectcontainer{
  border: 1px solid #dfdfdf !important;
  box-shadow: 0.5px 0.5px 2px rgb(0 0 0 / 29%);
  background-color: #fff;
  margin-bottom: 100px;  
}

.filter_selectcontainer_advance{
  // border: 1px solid #dfdfdf !important;
  // box-shadow: 0.5px 0.5px 2px rgb(0 0 0 / 29%);
  // background-color: #fff;
  // margin-bottom: 100px;  
  // position: absolute;
  // z-index: 999;
   max-width: 390px;
   min-width: 390px;
}

.replytosection{
  transform: rotate(180deg) translate(-7px, 13px) scaleX(-1);
  color:#6610f2;
}

.replytosubsection{
  transform: rotate(180deg) translate(-50px,20px) scaleX(-1);
  color:#6610f2;
}

.rounded {
  border-radius: 20px !important;
}

.rounded {
  border-radius: 20px !important;
}



#summary {
  font-size: 14px;
  line-height: 1.5;
}

#summary p.collapse:not(.show) {
    height: 200px !important;
    overflow: hidden;
  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
}

#summary p.collapsing {
    min-height: 90px !important;
}

#summary a.collapsed:after  {
    content: '+ Read More';
}

#summary a:not(.collapsed):after {
    content: '- Read Less';
}
.ng-trigger-overlayAnimation.p-multiselect-panel.p-component { max-width: 265px; min-width: 200px;}
button.p-multiselect-close{ 
  display: none !important;
  margin-bottom: 100px;
}

p-dataview.contentLibraryDataViewPadding > 
  div.p-dataview.p-component.p-dataview-grid > 
    div.p-dataview-content > 
      div.p-grid {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}


.content-tabs a.p-tabview-nav-link {
  border-color: transparent transparent var(--light-gray) transparent !important;
  color: unset !important;
}

.content-tabs.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link[aria-selected="true"] > span {
  background-color: var(--very-light-gray);
  border-radius: 25px;
  padding: 5px 10px;
  border: none;
  color: unset !important;
}

.content-tabs.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link[aria-selected="false"] > span, 
.content-tabs.p-tabview .p-tabview-nav-link > span.tab-label {
  background-color: none;
  border-radius: 25px;
  padding: 5px 10px;
  border: none;
  color: unset !important;
}

.content-tabs a.p-tabview-nav-link:focus {
  box-shadow: none !important;
}

div.content-library-filter-tabs > ul, div.content-library-filter-tabs > ul > li > a.p-tabview-nav-link {
  border-bottom: none !important;
}

app-content-library-filter > div.short-grid-section > div:nth-child(1) > p-tabview > div > div{
  padding: 0;
}

.short-grid-section {
  margin-top: 75px;
}

app-custom-grid, app-filters {
  display: flex;
  flex-direction: column;
}
.add-tag-container {
  height: 150px;
}

.add-tag-container > p-chips ,
.add-tag-container > p-chips > div.custom-chip-bag.p-chips.p-component,
.add-tag-container > p-chips > div.custom-chip-bag.p-chips.p-component > ul.p-inputtext.p-chips-multiple-container {
    width: 100%;
    height: 100%;
}

.add-tag-container > p-chips > div.custom-chip-bag.p-chips.p-component > ul.p-inputtext.p-chips-multiple-container{
  padding: .5rem;
  display: flex;
  flex-direction: row;
  align-items: unset;
  align-content: flex-start;
}

.add-tag-container > p-chips > div.custom-chip-bag.p-chips.p-component > ul.p-inputtext.p-chips-multiple-container > li{
  margin-top: 0;
  margin-bottom: .5rem;
  height: 2rem;
}

.add-tag-container .p-chips-token {
  background-color: #F4EDFE !important;
}

.add-tag-container .p-chips-token-label {
  color: #6e11f2 !important;
  font-weight: bold;
}


.currency-filter-input, .currency-filter-input span.p-inputnumber.p-component {
  width: 100% !important;
}

.currency-filter-input-short {
  width: 100% !important;
}

.currency-filter-input-short span.p-inputnumber.p-component {
  display: flex !important;
  flex-direction: row !important;
  box-sizing: border-box !important;
}

.currency-filter-input-short span.p-inputnumber.p-component input{
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
}

.rhs-input-one,  .rhs-input-two {
  width: auto !important;
}

.rhs-input-one-extended, .rhs-input-one > span.p-inputnumber.p-component {
  width: 100%;
}

.rhs-input-one > span.p-inputnumber.p-component > input.p-inputnumber-input.p-inputtext.p-component,
.rhs-input-two > span.p-inputnumber.p-component > input.p-inputnumber-input.p-inputtext.p-component{
  width: 100% !important;
}

.header-button-center {
  display: flex;
}

.header-button-center > button {
  margin: auto;
}

.mr-0 {
  margin-right: 0 !important;
}


.send-campaign-text {
  font-size: 16px;
  font-weight: bold;
}

.column-select > div.p-multiselect.p-component{
  border-color: #E0E0E0;
}

.column-select > div.p-multiselect.p-component > div.p-multiselect-trigger {
  justify-content: left;
}
.column-select > div.p-multiselect.p-component > div.p-multiselect-trigger > span.p-multiselect-trigger-icon.pi.pi-chevron-down::before {

  content: url('../src/assets/images/grey-triangle.svg') !important;

}

p-sorticon.column-sort-icon i.p-sortable-column-icon.pi.pi-fw.pi-sort-alt::before,
p-sorticon.column-sort-icon i.p-sortable-column-icon.pi.pi-fw.pi-sort-amount-up-alt::before,
p-sorticon.column-sort-icon i.p-sortable-column-icon.pi.pi-fw.pi-sort-amount-down::before {
  color: #B687F8;
  opacity: 0.85;
}

p-sorticon.column-sort-icon i.p-sortable-column-icon.pi.pi-fw.pi-sort-alt:hover::before,
p-sorticon.column-sort-icon i.p-sortable-column-icon.pi.pi-fw.pi-sort-amount-up-alt:hover::before,
p-sorticon.column-sort-icon i.p-sortable-column-icon.pi.pi-fw.pi-sort-amount-down:hover::before {
  opacity: 1;
}


.aduvo-purple-text {
  color: #6e11f2 !important;
  font-weight: bold;
}

.font-weight-normal{
  font-weight: normal;
}

.flex-column-between {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.viewport-view {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-section {
  flex: 1;
}

.input-text-color, #veteran > div > span {
  color: #2E2E2E;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5-half {
  margin-left: .5rem !important;
}

.mr-5-half {
  margin-right: .5rem !important;
}


.loan-table td, .loan-table tr{
  height: 30.66%;
}

.loan-table tr:first-child, .loan-table tr:last-child, .loan-table tr:first-child td, .loan-table tr:last-child td{
  height: 3%;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.flex {
  display: flex;
}

.p-tabview-panels{
  border: none;
  box-shadow:  0px -2px rgb(0 0 0 / 8%);
}

.message-history-text {
  color: #2E2E2E;
  font-size: 22px;
  font-weight: bold;
}

.content-card-avatar > div.p-avatar.p-component.p-avatar-circle > span.p-avatar-text{
  width: 100%;
  text-align: center;
}

.campaign-editor-buttons {
  height: 50px;
}

.campaign-editor-buttons button {
  max-height: unset;
}

.intro-text {
  font-size: 16px !important;
  font-weight: bold !important;
}

.remove-cursor {
  cursor: default !important;
}

.addnewuser-button .p-button-label {
  font-size: 16px !important;
  font-weight: normal !important;
}

.sub-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-manager-table-text {
  color: #505050;
  font-weight: normal;
}

.company-profile-input-text {
  font-size: 16px !important;
  color: #2E2E2E !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.custom-accordion .p-accordion-header-link,.custom-accordion .p-accordion-content{
  background-color: #f4edfe !important;
}

.custom-accordion .p-accordion-header-link {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  border:none !important;
}

.custom-accordion .p-accordion-header-link:focus{
  border: none !important;
}

.custom-accordion input:focus {
  background-color: #f4edfe !important;
}

.background-none {
  background-color: unset !important;
}

.company-profile-logo {
  border-radius: 3px !important;
  border-color: #6E1AF2 !important;
}


.content-library-grid .card:nth-child(3n+1)  {
  margin-left: auto !important;
  margin-right: auto !important;
}

.content-library-grid .card:nth-child(3n)  {
  margin-right: auto !important;
  margin-left: auto !important;
}

.content-library-grid .card:not(:nth-child(3n+1)):not(:nth-child(3n)) {
  /* Your CSS rules for elements that are neither 4n+1 nor 4n */
  margin-right: auto !important;
  margin-left: auto !important;
}

.mb-8 {
  margin-bottom: 4rem !important;
}

div.p-blockui-document.p-blockui.p-component-overlay{
  z-index: 10000 !important;
}

.create-contact-dialog .p-dialog-header-close-icon {
  font-weight: bolder !important;
  font-size: 14px !important;
  color: #4b0ca5 !important;
}