@charset "UTF-8";

/**************************************
:root {
  --surface-a:#ffffff;
  --surface-b:#f4f4f4;
  --surface-c:#e9ecef;
  --surface-d:#34A835;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:#495057;
  --text-color-secondary:#6c757d;
  --primary-color:#2196F3;
  --primary-color-text:#ffffff;
  --font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --font-family:Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont;
}

    box-shadow #A6D5FA light blue 166, 213, 250

  --font-family: HelveticaNeue-Medium, HelveticaNeue-Thin, HelveticaNeue-Ultralight, HelveticaNeue-Light, HelveticaNeue-Italic, HelveticaNeue-Medium, HelveticaNeue-Bold;

    success green #34A835
    success hover green #107D11

    help purple #9C27B0;
    help hover purple #8E24AA;
***************************************/
/* html,body, login-root, forgotPassword-root, .container-fluid-log{
  height: 100%;
} */
.container.main-wrapper {
  max-width: 92%;
  margin: auto;
  margin-top: 10px;
}
:root {
  --surface-a: #ffffff;
  --surface-b: #f4f4f4;
  --surface-c: #eaeaea;
  --surface-d: #c3c3c3;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  /**********************************
   Font.
  ***********************************/
  --font-size-pulse: 14px;
  font-size: var(--font-size-pulse);
  font-weight: 100;
  --font-family-pulse: "HelveticaNeue", Helvetica, Arial, sans-serif;
  /*--font-family: HelveticaNeue-Medium, HelveticaNeue-Thin, HelveticaNeue-Ultralight, HelveticaNeue-Light, HelveticaNeue-Italic, HelveticaNeue-Medium, HelveticaNeue-Bold;*/
  /**********************************
   Primary Colors.
  ***********************************/
  --text-color: #666666;
  --text-color-dark: #000000;
  --text-color-secondary: #848484;
  --primary-color-text: #ffffff; /*default background*/
  --primary-color-pulse: #6e11f2;
  --primary-color-ultralight: #f4edfe;
  --primary-color-light: #63ced8;
  --primary-color-dark: #6e11f2; /* primary color hover, focus border (box-shadow), focus inset */
  --primary-color-very-dark: #6e11f2;
  --primary-background-color: #ffffff;
  --stats-background-color: #F5F5F5;
  --link-alt-color: #6e11f2;
  --table-container-color: #F5F5F5;
  --primary-card-title: #F5F5F5;
  /**********************************
   Button Colors (non-status).
  ***********************************/
  --secondary-color-foreground: var(--primary-color-pulse);
  --secondary-color-background: #ffffff;
  --secondary-color-depressed: #e9ecef;
  --secondary-color-border: #6e11f2;
  --secondary-color-background-dark: #dfdfdf;
  --secondary-color-border-dark: #6e11f2;
  --link-color: #617c8a;
  --link-color-box-shadow: #e4e9ec;
  --remove-rule-color: #ff6347;
  --primary-dark-blue: #4b0ca5;
  /**********************************
   Status Colors.
  ***********************************/
  --info-color: var(--text-color);
  --info-color-background: #a3def8;
  --info-color-border: #79c8eb;
  --info-color-background-dark: #81cbec;
  --info-color-border-dark: #60b7de;
  --success-color: var(--text-color);
  --success-color-background: #a3e2c6;
  --success-color-border: #80caaa;
  --success-color-background-dark: #80caaa;
  --success-color-border-dark: #5ea285;
  --help-color: var(--text-color);
  --help-color-background: #e9bef1;
  --help-color-border: #de9eea;
  --help-color-background-dark: #de9eea;
  --help-color-border-dark: #d37de3;
  --warn-color: var(--text-color);
  --warn-color-background: #ffe38e;
  --warn-color-border: #ffd95e;
  --warn-color-background-dark: #ffd95e;
  --warn-color-border-dark: #ffce3c;
  --error-color: #e24c4c;
  --error-color-background: #f4b6b6;
  --error-color-border: #e38787;
  --error-color-background-dark: #ef9999;
  --error-color-border-dark: #cb5858;
  /*--required-color-background: #fef7be;*/
  /*--required-color-background: #fcf8d2;*/
  --required-color-background: var(--primary-background-color);
  /**********************************
   Gray Colors.
  ***********************************/
  --ultralight-gray: #f8f8f8; /*default gray background*/
  --very-light-gray: #f5f5f5; /*hover gray*/
  --light-gray: #dfdfdf;
  --normal-gray: #c3c3c3;
  --medium-gray: #a8a8a8;
  --dark-gray: #333333;
  --very-dark-gray: #222222;
  /**********************************
   Disabled Body and Overlay.
  ***********************************/
  --overlay-background-color: rgba(0, 0, 0, 0.8);
  /**********************************
   Obsolete (unused).
  ***********************************/
  --error-color-org: #73000c;
  --error-color-background-org: #ffcdd2;
  --error-color-border-org: #e60017;
  /*--primary-color-dark: #008b98;*/
  /*--primary-color-dark: 6e11f2;*/
  /*--primary-color-dark: #00a3b3;*/
}

* {
  box-sizing: border-box;
}
.page-header span{
  /*margin-left: 0.5em;*/
  font-size: 22px;
  font-family: "HelveticaNeue", serif;
  color: #2E2E2E;
  font-weight: bold;
}
.p-component {
  font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  /* background-color: var(--overlay-background-color); */
  transition-duration: 0.2s;
}
/* 
.p-disabled, .p-component:disabled {
  opacity: 0.6;
} */

.p-error, .p-invalid {
  color: var(--error-color);
}

.p-text-secondary {
  color: var(--text-color);
}

.pi {
  font-size: 16px;
}

.pi-clone {
  color: var(--primary-color-pulse);
}

.pi-trash {
  color: var(--primary-color-pulse);
}

.pulseTable .fa-envelope {
  color: #AAAAAA;
  font-size: 16px;
}

.pulseTable .pi-comment {
  color: #AAAAAA;
}

.mio {
  font-size: 1.2rem;
  margin-left:-.15em;
}

.p-link {
  font-size: 1rem;
  font-family: var(--font-family-pulse);
  border-radius: 3px;
}

  .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 .1em var(--primary-color-dark); */
  }

.p-autocomplete .p-autocomplete-loader {
  right: 0.5rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}

.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  padding: 0.25rem 0.5rem;
}

  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: var(--primary-color-pulse);
  }

  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    border-color: var(--primary-color-pulse);
  }

  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
  }

    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
      font-family: var(--font-family-pulse);
      font-size: 1rem;
      color: var(--text-color);
      padding: 0;
      margin: 0;
    }

  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: var(--primary-color-ultralight);
    color: var(--text-color);
    border-radius: 3px;
  }

    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
      margin-left: 0.5rem;
    }

.p-autocomplete.p-error > .p-inputtext, .p-autocomplete.p-invalid > .p-inputtext {
  border-color: var(--error-color);
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: var(--error-color);
}

.p-autocomplete-panel {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0.5rem 0;
  }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
      margin: 0;
      padding: 0.5rem 1rem;
      border: 0 none;
      color: var(--text-color);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

      .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
        color: var(--text-color);
        background: var(--very-light-gray);
      }

      .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
        color: var(--text-color);
        background: var(--primary-color-ultralight);
      }

.p-calendar.p-error > .p-inputtext, .p-calendar.p-invalid > .p-inputtext {
  border-color: var(--error-color);
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: var(--error-color);
}

.p-datepicker {
  padding: 0.5rem;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color-dark);
  border-radius: 3px;
}

  .p-datepicker:not(.p-datepicker-inline) {
    background: var(--primary-background-color);
  z-index: 99999999999999999999 !important;
  border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
      background: var(--primary-background-color);
    }

  .p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: var(--text-color);
    background: var(--primary-background-color);
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid var(--light-gray);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

    .p-datepicker .p-datepicker-header .p-datepicker-prev,
    .p-datepicker .p-datepicker-header .p-datepicker-next {
      width: 2rem;
      height: 2rem;
      color: var(--text-color);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

      .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
      .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
        color: var(--text-color);
        border-color: transparent;
        background: var(--very-light-gray);
      }

      .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
      .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

    .p-datepicker .p-datepicker-header .p-datepicker-title {
      line-height: 2rem;
    }

      .p-datepicker .p-datepicker-header .p-datepicker-title select {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      }

        .p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
          border-color: var(--primary-color-pulse);
        }

      .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        margin-right: 0.5rem;
      }

  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
  }

    .p-datepicker table th {
      padding: 2px 8px;
    }

      .p-datepicker table th > span {
        width: 2.5rem;
        height: 2.5rem;
      }

    .p-datepicker table td {
      padding: 2px;
    }

      .p-datepicker table td > span {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        transition: box-shadow 0.2s;
        border: 1px solid transparent;
      }

        .p-datepicker table td > span.p-highlight {
          color: var(--text-color);
          /*background: #E3F2FD;*/
          background: var(--primary-color-ultralight);
        }

        .p-datepicker table td > span:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
        }

      .p-datepicker table td.p-datepicker-today > span {
        /*background: var(--primary-color-dark);*/
        /*color: var(--text-color);*/
        border-color: transparent;
        background: var(--primary-color-pulse);
        color: var(--primary-color-text);
      }

        .p-datepicker table td.p-datepicker-today > span.p-highlight {
          color: var(--text-color);
          /*background: #E3F2FD;*/
          background: var(--primary-color-light);
          box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
        }

  .p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid var(--light-gray);
  }

    .p-datepicker .p-datepicker-buttonbar .p-button {
      width: auto;
    }

  .p-datepicker .p-timepicker {
    border-top: 1px solid var(--light-gray);
    padding: 0.5rem;
  }

    .p-datepicker .p-timepicker button {
      width: 2rem;
      height: 2rem;
      color: var(--text-color);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

      .p-datepicker .p-timepicker button:enabled:hover {
        color: var(--text-color);
        border-color: transparent;
        background: var(--very-light-gray);
      }

      .p-datepicker .p-timepicker button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

      .p-datepicker .p-timepicker button:last-child {
        margin-top: 0.2em;
      }

    .p-datepicker .p-timepicker span {
      font-size: 1.25rem;
    }

    .p-datepicker .p-timepicker > div {
      padding: 0 0.5rem;
    }

  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }

  .p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
  }

    .p-datepicker .p-monthpicker .p-monthpicker-month {
      padding: 0.5rem;
      transition: box-shadow 0.2s;
      border-radius: 3px;
    }

      .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
        color: var(--text-color);
        /*background: #E3F2FD;*/
        background: var(--primary-color-ultralight);
      }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid var(--light-gray);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
      padding-left: 0;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
      padding-right: 0;
      border-right: 0 none;
    }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    color: var(--text-color);
    background: var(--very-light-gray);
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    color: var(--text-color);
    background: var(--very-light-gray);
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}

.p-cascadeselect {
  background: var(--primary-background-color);
  border: 1px solid var(--primary-color-dark);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

  .p-cascadeselect:not(.p-disabled):hover {
    border-color: var(--primary-color-pulse);
  }

  .p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    border-color: var(--primary-color-pulse);
  }

  .p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.5rem;
  }

    .p-cascadeselect .p-cascadeselect-label.p-placeholder {
      color: var(--text-color);
    }

    .p-cascadeselect .p-cascadeselect-label:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }

  .p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: var(--text-color);
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .p-cascadeselect.p-error, .p-cascadeselect.p-invalid {
    border-color: var(--error-color);
  }

.p-cascadeselect-panel {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.5rem 0;
  }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
      margin: 0;
      border: 0 none;
      color: var(--text-color);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

      .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
        padding: 0.5rem 1rem;
      }

        .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
        }

      .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
        color: var(--text-color);
        background: var(--primary-color-ultralight);
      }

      .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
        color: var(--text-color);
        background: var(--very-light-gray);
      }

      .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
        font-size: 0.875rem;
      }

.p-input-filled .p-cascadeselect {
  background: var(--ultralight-gray);
}

  .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: var(--ultralight-gray);
  }

  .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: var(--primary-background-color);
  }

.p-checkbox {
  width: 1.25em;
  height: 1.25em;
}

  .p-checkbox .p-checkbox-box {
    border: 1px solid var(--primary-color-dark);
    background: var(--primary-background-color);
    width: 1.25em;
    height: 1.25em;
    /*color: var(--text-color);*/
    color: var(--text-color);
    border-radius: 0px;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s ;
  }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      transition-duration: 0.2s;
      color: var(--primary-color-text);
      /*font-size: 14px;*/
      font-size: 1em;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: var(--primary-color-pulse);
      background: var(--primary-color-pulse);
    }

  .p-checkbox .p-checkbox.p-checkbox-disabled {
    border-color: var(--primary-color-text);
    background: var(--primary-color-text);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--primary-color-pulse);
    background: var(--light-gray);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--primary-color-pulse);
    border-color: var(--primary-color-dark);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary-color-dark);
    background: var(--primary-color-dark);
    color: var(--primary-color-text);
  }

  .p-checkbox.p-error > .p-checkbox-box, .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: var(--error-color);
  }

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: var(--error-color);
}


.checkboxspacing .p-checkbox .p-checkbox-box{
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.checkboxspacing .p-checkbox .p-checkbox-box .p-checkbox-icon{
  font-size: 10px;
}

.checkboxspacing .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
  border: 1px solid #ebebeb;
}

.checkboxspacing .p-checkbox.p-checkbox-focused .p-checkbox-box.p-focus{
  border: 1px solid #ebebeb;
}


.checkboxspacing .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
box-shadow: 0 0 0 1px white;
}


.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: var(--ultralight-gray);
}

  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: var(--primary-color-pulse);
  }

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: var(--ultralight-gray);
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--primary-color-dark);
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.5rem;
}

  /* .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: var(--primary-color-pulse);
  } */

  .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    border-color: var(--primary-color-pulse); */
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0rem 0.5rem;
    margin-right: 0.5rem;
    background: #fff;
    color: #6e11f2;
    border-radius: 3px;
    margin-top: 10px;
  }

    .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
      margin-left: 0.5rem;
    }

  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
  }

    .p-chips .p-chips-multiple-container .p-chips-input-token input {
      font-family: var(--font-family-pulse);
      font-size: 1rem;
      color: var(--text-color);
      padding: 0;
      margin: 0;
    }

.p-chips.p-error > .p-inputtext, .p-chips.p-invalid > .p-inputtext {
  border-color: var(--error-color);
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: var(--error-color);
}

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}

  .p-colorpicker-panel .p-colorpicker-color-handle,
  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: var(--primary-background-color);
  }

.p-colorpicker-overlay-panel {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
  background: var(--primary-background-color);
  border: 1px solid var(--primary-color-dark);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 7px;
  font-weight: 400;
  margin-right: 10px;
}

  .p-dropdown:not(.p-disabled):hover {
    border-color: var(--primary-color-pulse);
    background: var(--very-light-gray);
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    border-color: var(--primary-color-pulse);
  }

  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.5rem;
  }

  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 10px;
    height: 42px!important;
    min-height: 42px!important;
  }

    .p-dropdown .p-dropdown-label.p-placeholder {
      color: var(--text-color);
    }

    .p-dropdown .p-dropdown-label:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }

  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: var(--text-color);
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .p-dropdown .p-dropdown-clear-icon {
    color: var(--text-color);
    right: 2.357rem;
  }

.p-dropdown-panel {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  border-radius: 3px;
  z-index: 9999999999 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}



  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: var(--text-color);
    background: var(--ultralight-gray);
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      padding-right: 1.5rem;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
      right: 0.5rem;
      color: var(--text-color);
    }

  .p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
  }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      margin: 0;
      padding: 0.5rem 1rem;
      border: 0 none;
      color: var(--text-color);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

      .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        /*color: var(--text-color);*/
        /*background: var(--primary-color-ultralight);*/
        color: var(--primary-color-pulse);
        background: transparent;
      }

        .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:not(.p-disabled):hover {
          /*color: var(--text-color);*/
          /*background: var(--primary-color-ultralight);*/
          color: var(--primary-color-pulse);
          background: var(--very-light-gray);
        }

      .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: var(--text-color);
        background: var(--very-light-gray);
      }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
      padding: 0.5rem 1rem;
      color: var(--text-color);
      background: transparent;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: var(--text-color);
      background: var(--primary-background-color);
      font-weight: 600;
    }

  .p-dropdown-panel.p-error, .p-dropdown-panel.p-invalid {
    border-color: var(--error-color);
  }

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: var(--error-color);
}

p-dropdown.ng-invalid > .p-dropdown {
  border-color: var(--error-color-border-dark)!important;
}

.p-input-filled .p-dropdown {
  background: var(--ultralight-gray);
}

  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: var(--ultralight-gray);
  }

  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: var(--primary-background-color);
  }

.groundDropdown .p-dropdown-panel{
  z-index: 1 !important;
}  

.p-editor-container .p-editor-toolbar {
  background: var(--ultralight-gray);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

  .p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid var(--light-gray);
  }

    .p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
      stroke: var(--text-color);
    }

    .p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
      fill: var(--text-color);
    }

    .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
      border: 0 none;
      color: var(--text-color);
    }

      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
        color: var(--text-color);
      }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
          stroke: var(--text-color);
        }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
          fill: var(--text-color);
        }

    .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      color: var(--text-color);
    }

      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
        stroke: var(--text-color);
      }

      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
        fill: var(--text-color);
      }

    .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      background: var(--primary-background-color);
      border: 0 none;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 3px;
    }

      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
        color: var(--text-color);
        padding: 0.5rem 1rem;
        border-radius: 3px;
      }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
          color: var(--text-color);
          background: var(--very-light-gray);
        }

.p-editor-container .p-editor-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

  .p-editor-container .p-editor-content.ql-snow {
    border: 1px solid var(--light-gray);
  }

  .p-editor-container .p-editor-content .ql-editor {
    background: var(--primary-background-color);
    color: var(--text-color);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: var(--text-color);
}

  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: var(--text-color);
  }

  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: var(--text-color);
  }

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color-pulse);
}

  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: var(--primary-color-pulse);
  }

  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: var(--primary-color-pulse);
  }

  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: var(--primary-color-pulse);
  }

.p-inputgroup-addon {
  background: var(--very-light-gray);
  color: var(--text-color);
  border-top: 1px solid var(--primary-color-dark);
  border-left: 1px solid var(--primary-color-dark);
  border-bottom: 1px solid var(--primary-color-dark);
  padding: 0.5rem 0.5rem;
  min-width: 2.357rem;
}

  .p-inputgroup-addon:last-child {
    border-right: 1px solid var(--primary-color-dark);
  }

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

  .p-inputgroup > .p-component + .p-inputgroup-addon,
  .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }

  .p-inputgroup > .p-component:focus,
  .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
  }

    .p-inputgroup > .p-component:focus ~ label,
    .p-inputgroup > .p-float-label > .p-component:focus ~ label {
      z-index: 1;
    }

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
  }

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: var(--error-color);
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: var(--error-color);
}
  
.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
} 
/*
.p-inputswitch {
  width: 32px;
  height: 17px;
}

p-inputSwitch.inputSwithActive .p-inputswitch {
  height: 17px;
}

.p-inputswitch.p-disabled:not(p-inputswitch-checked) .p-inputswitch-slider {
  background: #B5B5B5;
}
*/

  .p-inputswitch .p-inputswitch-slider {
    background: var(--primary-color-pulse);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
    border: 1px solid var(--primary-color-dark);
  }

    .p-inputswitch .p-inputswitch-slider:before {
      background: var(--primary-background-color);
      width: 12px;    
      height: 12px;    
      left: 4px;    
      margin-top: -6px;
      border-radius: 50%;
      transition-duration: 0.2s;
    }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.1rem);
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-pulse);
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled) .p-inputswitch-slider {
    background: var(--primary-color-pulse);
  }

  .p-inputswitch:not(p-inputswitch-checked):not(.p-disabled) .p-inputswitch-slider {
    background: #C3C3C3;
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: var(--primary-color-light);
  }

  .p-inputswitch:not(p-inputswitch-checked):not(.p-disabled):hover .p-inputswitch-slider {
    /* background: var(--normal-gray); */
    background: #B5B5B5;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary-color-pulse);
  }

  .p-inputswitch.p-disabled.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary-color-pulse);
  }

  .p-inputswitch.p-disabled:not(p-inputswitch-checked) .p-inputswitch-slider {
    background: #C3C3C3;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: var(--primary-background-color);
  }

  .p-inputswitch.p-error, .p-inputswitch.p-invalid {
    border-color: var(--error-color);
  }

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: var(--error-color);
}

.p-datatable-header .p-inputtext {
  font-family: var(--font-family-pulse);
  font-size: .85rem;
  color: var(--medium-gray);
  background: var(--primary-background-color);
  padding: 0.25rem 0.25rem;
  border: 1px solid var(--very-light-gray);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  /*appearance: none;*/
  border-radius: 3px;
  min-height: 32px;
}
.control-label, .t-grid-label-text{
  margin: 7px !important;
  position: relative;
  display: block;

}
.p-inputtext {
  font-family: var(--font-family-pulse);
  font-size: .85rem;
  color: var(--text-color);
  background: var(--primary-background-color);
  padding: 0.25rem 0.25rem;
  border: 1px solid #E0E0E0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  /*appearance: none;*/
  border-radius: 3px;
  min-height: 32px;
}

  .p-inputtext:enabled:hover {
    border-color: var(--primary-color-pulse);
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.1rem var(--primary-color-dark); */
    border-color: var(--primary-color-pulse);
  }
  .p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label
  {
    font-size:14px;
  }
  .p-inputtext.p-error, .p-inputtext.p-invalid, .p-inputtext.ng-dirty.ng-invalid {
    border-color: var(--error-color);
  }

  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }

  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }

.p-float-label > label {
  left: 1.2rem;
  color: var(--text-color);
  transition-duration: 0.2s;
}
.input-login:-webkit-autofill ~ label {
  top:-33%
}
.p-input-icon-left > i:first-of-type {
  left: 0.5rem;
  color: var(--text-color);
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.5rem;
  color: var(--text-color);
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2rem;
}

::-webkit-input-placeholder {
  color: var(--text-color);
}

:-moz-placeholder {
  color: var(--text-color);
}

::-moz-placeholder {
  color: var(--text-color);
}

:-ms-input-placeholder {
  color: var(--text-color);
}

.p-input-filled .p-inputtext {
  background-color: var(--ultralight-gray);
}

  .p-input-filled .p-inputtext:enabled:hover {
    background-color: var(--ultralight-gray);
  }

  .p-input-filled .p-inputtext:enabled:focus {
    background-color: var(--primary-background-color);
  }

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

.p-listbox {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color-dark);
  border-radius: 3px;
}

  .p-listbox .p-listbox-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: var(--text-color);
    background: var(--ultralight-gray);
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

    .p-listbox .p-listbox-header .p-listbox-filter {
      padding-right: 1.5rem;
    }

    .p-listbox .p-listbox-header .p-listbox-filter-icon {
      right: 0.5rem;
      color: var(--text-color);
    }

    .p-listbox .p-listbox-header .p-checkbox {
      margin-right: 0.5rem;
    }

  .p-listbox .p-listbox-list {
    padding: 0.5rem 0;
  }

    .p-listbox .p-listbox-list .p-listbox-item {
      margin: 0;
      padding: 0.5rem 1rem;
      border: 0 none;
      color: var(--text-color);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

      .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
        color: var(--text-color);
        background: var(--primary-color-ultralight);
      }

      .p-listbox .p-listbox-list .p-listbox-item:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
      }

      .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
        margin-right: 0.5rem;
      }

  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--text-color);
    background: var(--very-light-gray);
  }

  .p-listbox.p-error, .p-listbox.p-invalid {
    border-color: var(--error-color);
  }

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: var(--error-color);
}

.p-multiselect {
  background: var(--primary-background-color);
  border: 1px solid var(--primary-color-dark);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

  .p-multiselect:not(.p-disabled):hover {
    border-color: var(--primary-color-pulse);
    background: var(--very-light-gray);
  }

  .p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    border-color: var(--primary-color-pulse);
  }

  .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

    .p-multiselect .p-multiselect-label.p-placeholder {
      color: var(--text-color);
    }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: var(--primary-color-ultralight);
    color: var(--text-color);
    border-radius: 3px;
  }

    .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
      margin-left: 0.5rem;
    }

  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: var(--primary-color-pulse);
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .p-multiselect.p-error, .p-multiselect.p-invalid {
    border-color: var(--error-color);
  }

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.5rem;
}

.p-multiselect-panel {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

  .p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: var(--text-color);
    background: var(--ultralight-gray);
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
      padding-right: 1.5rem;
      height:35px;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
      right: 0.5rem;
      color: var(--text-color);
    }

    .p-multiselect-panel .p-multiselect-header .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
      color: var(--text-color);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

      .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
        color: var(--text-color);
        border-color: transparent;
        background: var(--very-light-gray);
      }

      .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

  .p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
  }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      margin: 0;
      padding: 0.5rem 1rem;
      border: 0 none;
      color: var(--text-color);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

      .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        color: var(--text-color);
        background: var(--primary-color-ultralight);
      }

      .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        color: var(--text-color);
        background: var(--very-light-gray);
      }

      .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight:not(.p-disabled):hover {
        color: var(--primary-color-text); /* Added */
        background: var(--primary-color-light); /* Added */
      }

      .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        outline: 0 none;
        outline-offset: 0;
        /*box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);*/
        box-shadow: unset; /* Added */
      }

      .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
        margin-right: 0.5rem;
      }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
      padding: 0.5rem 1rem;
      color: var(--text-color);
      background: transparent;
    }

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: var(--error-color);
}

.groundSelect .p-multiselect-panel     {
  z-index: 2 !important;
}
.dialogSelect .p-multiselect-panel     {
  z-index: 9997 !important;
}
.p-input-filled .p-multiselect {
  background: var(--ultralight-gray);
}

  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: var(--ultralight-gray);
  }

  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: var(--primary-background-color);
  }

.p-password-panel {
  padding: 1rem;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

  .p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
  }

.p-radiobutton {
  width: 18px;
  height: 18px;
}

  .p-radiobutton .p-radiobutton-box {
    /* border: 1px solid var(--primary-color-dark); */
    box-shadow: 0 0 0 0.1px #fff;
    background: transparent;
    width: 18px;
    height: 18px;
    color: var(--text-color);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border: 1px solid var(--light-gray);
  }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
      border-color: transparent;
     /* background: var(--light-gray); added*/
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1px #fff;
      border-color: var(--primary-color-dark);
    }

    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
      width: 18px;
      /* height: 14px; */
      transition-duration: 0.2s;
      background-color: var(--primary-background-color);
      /* background-color: var(--primary-color-pulse);  */
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
      /* border-color: var(--primary-color-dark); */
      border: 4px solid #6e11f2;
      box-shadow: 0 0 0 0.1px #fff;
      background: var(--primary-background-color); /*added*/
    }

      .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        /* border-color: var(--primary-color-dark);
        background: var(--primary-color-dark);
        background: var(--primary-background-color); 
        color: var(--primary-color-text); */
        box-shadow: 0 0 0 0.1px #fff;
        background: transparent;
      }

        .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover .p-radiobutton-icon {
          width: 18px;
          /* height: 12px; */
          transition-duration: 0.2s;
          background-color: var(--primary-background-color);
          /* background-color: var(--primary-color-light); */
        }

  .p-radiobutton.p-error > .p-radiobutton-box, .p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: var(--error-color);
  }

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: var(--error-color);
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: var(--ultralight-gray);
}

  .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: var(--ultralight-gray);
  }

  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: var(--primary-color-pulse);
  }

    .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      background: var(--primary-color-dark);
    }

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: var(--text-color);
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.143rem;
}

  .p-rating .p-rating-icon.p-rating-cancel {
    color: #e74c3c;
  }

  .p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

  .p-rating .p-rating-icon:first-child {
    margin-left: 0;
  }

  .p-rating .p-rating-icon.pi-star {
    color: var(--primary-color-pulse);
  }

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: var(--primary-color-pulse);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-selectbutton .p-button {
  background: var(--primary-background-color);
  border: 1px solid var(--primary-color-dark);
  color: var(--text-color);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.5rem 15px;
}

  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: var(--text-color);
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: var(--very-light-gray);
    border-color: var(--primary-color-dark);
    color: var(--text-color);
  }

    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
      color: var(--text-color);
    }

  .p-selectbutton .p-button.p-highlight {
    background: var(--primary-color-pulse);
    border-color: var(--primary-color-pulse);
    color: var(--primary-color-text);
  }

    .p-selectbutton .p-button.p-highlight .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight .p-button-icon-right {
      color: var(--primary-color-text);
    }

    .p-selectbutton .p-button.p-highlight:hover {
      background: var(--primary-color-dark);
      border-color: var(--primary-color-dark);
      color: var(--primary-color-text);
    }

      .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
      .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
        color: var(--primary-color-text);
      }

.p-selectbutton.p-error > .p-button, .p-selectbutton.p-invalid > .p-button {
  border-color: var(--error-color);
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: var(--error-color);
}

.p-slider {
  background: var(--light-gray);
  border: 0 none;
  border-radius: 3px;
}

  .p-slider.p-slider-horizontal {
    height: 0.286rem;
  }

    .p-slider.p-slider-horizontal .p-slider-handle {
      margin-top: -0.5715rem;
      margin-left: -0.5715rem;
    }

  .p-slider.p-slider-vertical {
    width: 0.286rem;
  }

    .p-slider.p-slider-vertical .p-slider-handle {
      margin-left: -0.5715rem;
      margin-bottom: -0.5715rem;
    }

  .p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: var(--primary-background-color);
    border: 1px solid var(--primary-color-pulse);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

    .p-slider .p-slider-handle:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

  .p-slider .p-slider-range {
    background: var(--primary-color-pulse);
  }

  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: var(--primary-color-pulse);
    border-color: var(--primary-color-pulse);
  }

  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
  }

  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width 0.2s;
  }

  .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
  }

  .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height 0.2s;
  }

.p-togglebutton.p-button {
  background: var(--primary-background-color);
  border: 1px solid var(--primary-color-dark);
  color: var(--text-color);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button .p-button-icon-right {
    color: var(--text-color);
  }

  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: var(--very-light-gray);
    border-color: var(--primary-color-dark);
    color: var(--text-color);
  }

    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
      color: var(--text-color);
    }

  .p-togglebutton.p-button.p-highlight {
    background: var(--primary-color-pulse);
    border-color: var(--primary-color-pulse);
    color: var(--primary-color-text);
  }

    .p-togglebutton.p-button.p-highlight .p-button-icon-left,
    .p-togglebutton.p-button.p-highlight .p-button-icon-right {
      color: var(--primary-color-text);
    }

    .p-togglebutton.p-button.p-highlight:hover {
      background: var(--primary-color-dark);
      border-color: var(--primary-color-dark);
      color: var(--primary-color-text);
    }

      .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
      .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
        color: var(--primary-color-text);
      }

  .p-togglebutton.p-button.p-error, .p-togglebutton.p-button.p-invalid {
    border-color: var(--error-color);
  }

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: var(--error-color);
}

.p-button {
  color: var(--primary-color-text);
  background: var(--primary-color-pulse);
  border: 1px solid var(--primary-color-dark);
  padding: 0.5rem 15px;
  font-size: .85rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 23px;
  min-height: 2.5rem;
  max-height: 2.5rem;
  font-weight: bold;
}

  .p-button:enabled:hover {
    background: var(--primary-color-light);
    color: var(--primary-color-text);
    border-color: var(--primary-color-pulse);
  }
/*
  .p-button:enabled:active {
    background: var(--primary-color-dark);
    color: var(--primary-color-text);
    border-color: var(--primary-color-pulse);
  }
*/
  .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--primary-color-pulse);
    background-color: var(--primary-background-color); /*Added*/
    border: 1px solid var(--primary-color-dark); /*Added color part*/
  }

    .p-button.p-button-outlined:enabled:hover {
      background: var(--primary-color-dark);
      opacity: 0.04;
      color: var(--primary-color-pulse);
      opacity: 1; /*Added*/
      border: 1px solid var(--primary-color-dark); /*Added color part*/
      background: var(--very-light-gray); /*Added*/
    }

    .p-button.p-button-outlined:enabled:active {
      background: var(--primary-color-dark);
      opacity: 0.16;
      color: var(--primary-color-pulse);
      border: 1px solid;
      opacity: 1; /*Added*/
      background: var(--light-gray); /*Added*/
      border: 1px solid var(--primary-color-dark); /*Added color part*/
    }

    .p-button.p-button-outlined.p-button-plain {
      color: var(--text-color);
      border-color: var(--text-color);
    }

      .p-button.p-button-outlined.p-button-plain:enabled:hover {
        background: var(--very-light-gray);
        color: var(--text-color);
      }

      .p-button.p-button-outlined.p-button-plain:enabled:active {
        background: var(--light-gray);
        color: var(--text-color);
      }

  .p-button.p-button-text {
    background-color: transparent;
    color: var(--primary-color-pulse);
    border-color: transparent;
  }

    .p-button.p-button-text:enabled:hover {
      /*background: rgba(33, 150, 243, 0.04);*/
      background: var(--primary-color-dark);
      /* opacity: 0.04; */
      /* color: var(--primary-color-pulse); */
      /*border-color: transparent;*/
    }

    .p-button.p-button-text:enabled:active {
      /*background: rgba(33, 150, 243, 0.16);*/
      background: var(--primary-color-dark);
      opacity: 0.16;
      color: var(--primary-color-pulse);
      /*border-color: transparent;*/
    }

    .p-button.p-button-text.p-button-plain {
      color: var(--text-color);
    }

      .p-button.p-button-text.p-button-plain:enabled:hover {
        background: var(--very-light-gray);
        color: var(--text-color);
      }

      .p-button.p-button-text.p-button-plain:enabled:active {
        background: var(--light-gray);
        color: var(--text-color);
      }

  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    /*box-shadow: 0 0 0 0.2rem #a6d5fa;*/
    /* box-shadow: 0 0 0 0.1rem var(--primary-color-pulse); */
  }

  .p-button .p-button-icon-left {
    margin-right: 1rem;
  }

  .p-button .p-button-icon-right {
    margin-left: 0.5rem;
  }

  .p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
  }

  .p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
  }

  .p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: var(--primary-color-pulse);
    background-color: var(--primary-background-color);
  }

  .p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .p-button.p-button-rounded {
    border-radius: 2rem;
  }

  .p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
  }

    .p-button.p-button-icon-only .p-button-icon-left,
    .p-button.p-button-icon-only .p-button-icon-right {
      margin: 0;
    }

    .p-button.p-button-icon-only.p-button-rounded {
      border-radius: 50%;
      height: 2.357rem;
    }

  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
  }

    .p-button.p-button-sm .p-button-icon {
      font-size: 0.875rem;
    }

  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
  }

    .p-button.p-button-lg .p-button-icon {
      font-size: 1.25rem;
    }

.p-fluid .p-button {
  width: 100%;
}

.p-fluid .p-button-icon-only {
  width: 2.357rem;
}

.p-fluid .p-buttonset {
  display: flex;
}

  .p-fluid .p-buttonset .p-button {
    flex: 1;
  }
.create-new{
  margin-right: 10px;
  border-color: var(--medium-gray);
  border: 1px solid var(--medium-gray);
}
.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: var(--secondary-color-foreground);
  background: var(--secondary-color-background);
  border: 1px solid var(--secondary-color-border);
}

  .p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: var(--secondary-color-foreground);
    color: #fff;
    border-color: var(--secondary-color-border-dark);
  }

  .p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    /*box-shadow: 0 0 0 0.2rem #d2effc;*/
    box-shadow: 0 0 0 0.1rem var(--primary-color-pulse);
    border-color: var(--primary-color-dark);
  }

  .p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: var(--secondary-color-background-dark);
    color: var(--secondary-color-foreground);
    border-color: var(--secondary-color-border-dark);
  }

  .p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--secondary-color-background);
    border: 1px solid;
  }

    .p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
      /*background: rgba(163, 222, 248, 0.04);*/
      background: var(--secondary-color-background);
      opacity: 0.04;
      color: var(--secondary-color-background);
      border: 1px solid;
    }

    .p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
      /*background: rgba(163, 222, 248, 0.16);*/
      background: var(--secondary-color-background);
      opacity: 0.16;
      color: var(--secondary-color-background);
      border: 1px solid;
    }

  .p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: var(--secondary-color-background);
    border-color: transparent;
  }

    .p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
      /*background: rgba(163, 222, 248, 0.04);*/
      background: var(--secondary-color-background);
      opacity: 0.04;
      border-color: transparent;
      color: var(--secondary-color-background);
    }

    .p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
      /*background: rgba(163, 222, 248, 0.16);*/
      background: var(--secondary-color-background);
      opacity: 0.16;
      border-color: transparent;
      color: var(--secondary-color-background);
    }

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: var(--info-color);
  background: var(--info-color-background);
  border: 1px solid var(--info-color-border);
}

  .p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: var(--info-color-background-dark);
    color: var(--info-color);
    border-color: var(--info-color-border-dark);
  }

  .p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
    /*box-shadow: 0 0 0 0.1rem #d2effc;*/
    box-shadow: 0 0 0 0.1rem var(--info-color-background);
  }

  .p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
    background: var(--info-color-border-dark);
    color: var(--info-color);
    border-color: var(--info-color-border-dark);
  }

  .p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--info-color-background);
    border: 1px solid;
  }

    .p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
      /*background: rgba(163, 222, 248, 0.04);*/
      background: var(--info-color-background);
      opacity: 0.04;
      color: var(--info-color-background);
      border: 1px solid;
    }

    .p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
      /*background: rgba(163, 222, 248, 0.16);*/
      background: var(--info-color-background);
      opacity: 0.16;
      color: var(--info-color-background);
      border: 1px solid;
    }

  .p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: var(--info-color-background);
    border-color: transparent;
  }

    .p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
      /*background: rgba(163, 222, 248, 0.04);*/
      background: var(--info-color-background);
      opacity: 0.04;
      border-color: transparent;
      color: var(--info-color-background);
    }

    .p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
      /*background: rgba(163, 222, 248, 0.16);*/
      background: var(--info-color-background);
      opacity: 0.16;
      border-color: transparent;
      color: var(--info-color-background);
    }

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: var(--success-color);
  background: var(--success-color-background);
  border: 1px solid var(--success-color-border);
}

  .p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: var(--success-color-background-dark);
    color: var(--success-color);
    border-color: var(--success-color-border-dark);
  }

  .p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
    /*box-shadow: 0 0 0 0.1rem #caeede;*/
    box-shadow: 0 0 0 0.1rem var(--success-color-background);
  }

  .p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
    background: var(--success-color-border-dark);
    color: var(--success-color);
    border-color: var(--success-color-border-dark);
  }

  .p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--success-color-background);
    border: 1px solid;
  }

    .p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
      /*background: rgba(163, 226, 198, 0.04);*/
      background: var(--success-color-background);
      opacity: 0.04;
      color: var(--success-color-background);
      border: 1px solid;
    }

    .p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
      /*background: rgba(163, 226, 198, 0.16);*/
      background: var(--success-color-background);
      opacity: 0.16;
      color: var(--success-color-background);
      border: 1px solid;
    }

  .p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: var(--success-color-background);
    border-color: transparent;
  }

    .p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
      /*background: rgba(163, 226, 198, 0.04);*/
      background: var(--success-color-background);
      opacity: 0.04;
      border-color: transparent;
      color: var(--success-color-background);
    }

    .p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
      /*background: rgba(163, 226, 198, 0.16);*/
      background: var(--success-color-background);
      opacity: 0.16;
      border-color: transparent;
      color: var(--success-color-background);
    }

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: var(--warn-color);
  background: var(--warn-color-background);
  border: 1px solid var(--warn-color-border);
}

  .p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: var(--warn-color-background-dark);
    color: var(--warn-color);
    border-color: var(--warn-color-border-dark);
  }

  .p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
    /*box-shadow: 0 0 0 0.1rem #ffe9a8;*/
    box-shadow: 0 0 0 0.1rem var(--warn-color-background);
  }

  .p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: var(--warn-color-border-dark);
    color: var(--warn-color);
    border-color: var(--warn-color-border-dark);
  }

  .p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--warn-color-background);
    border: 1px solid;
  }

    .p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
      /*background: rgba(255, 227, 142, 0.04);*/
      background: var(--warn-color-background);
      opacity: 0.04;
      color: var(--warn-color-background);
      border: 1px solid;
    }

    .p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
      /*background: rgba(255, 227, 142, 0.16);*/
      background: var(--warn-color-background);
      opacity: 0.16;
      color: var(--warn-color-background);
      border: 1px solid;
    }

  .p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: var(--warn-color-background);
    border-color: transparent;
  }

    .p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
      /*background: rgba(255, 227, 142, 0.04);*/
      background: var(--warn-color-background);
      opacity: 0.04;
      border-color: transparent;
      color: var(--warn-color-background);
    }

    .p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
      /*background: rgba(255, 227, 142, 0.16);*/
      background: var(--warn-color-background);
      opacity: 0.16;
      border-color: transparent;
      color: var(--warn-color-background);
    }

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: var(--help-color);
  background: var(--help-color-background);
  border: 1px solid var(--help-color-border);
}

  .p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: var(--help-color-background-dark);
    color: var(--help-color);
    border-color: var(--help-color-border-dark);
  }

  .p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
    /*box-shadow: 0 0 0 0.1rem #f0d3f6;*/
    box-shadow: 0 0 0 0.1rem var(--help-color-background);
  }

  .p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
    background: var(--help-color-border-dark);
    color: var(--help-color);
    border-color: var(--help-color-border-dark);
  }

  .p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--help-color-background);
    border: 1px solid;
  }

    .p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
      /*background: rgba(233, 190, 241, 0.04);*/
      background: var(--help-color-background);
      opacity: 0.04;
      color: var(--help-color-background);
      border: 1px solid;
    }

    .p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
      /*background: rgba(233, 190, 241, 0.16);*/
      background: var(--help-color-background);
      opacity: 0.16;
      color: var(--help-color-background);
      border: 1px solid;
    }

  .p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: var(--help-color-background);
    border-color: transparent;
  }

    .p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
      /*background: rgba(233, 190, 241, 0.04);*/
      background: var(--help-color-background);
      opacity: 0.04;
      border-color: transparent;
      color: var(--help-color-background);
    }

    .p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
      /*background: rgba(233, 190, 241, 0.16);*/
      background: var(--help-color-background);
      opacity: 0.16;
      border-color: transparent;
      color: var(--help-color-background);
    }

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: var(--error-color);
  background: var(--error-color-background);
  border: 1px solid var(--error-color-border);
}

  .p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: var(--error-color-background-dark);
    color: var(--error-color);
    border-color: var(--error-color-border-dark);
  }

  .p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
    /*box-shadow: 0 0 0 0.1rem #fbe2e2;*/
    box-shadow: 0 0 0 0.1rem var(--error-color-background);
  }

  .p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: var(--error-color-border-dark);
    color: var(--error-color);
    border-color: var(--error-color-border-dark);
  }

  .p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--error-color-background);
    border: 1px solid;
  }

    .p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
      /*background: rgba(244, 182, 182, 0.04);*/
      background: var(--error-color-background);
      opacity: 0.04;
      color: var(--error-color-background);
      border: 1px solid;
    }

    .p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
      background: var(--error-color-background);
      opacity: 0.16;
      color: var(--error-color-background);
      border: 1px solid;
    }

  .p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: var(--error-color-background);
    border-color: transparent;
  }

    .p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
      /*background: rgba(244, 182, 182, 0.04);*/
      background: var(--error-color-background);
      opacity: 0.04;
      border-color: transparent;
      color: var(--error-color-background);
    }

    .p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
      /*background: rgba(244, 182, 182, 0.16);*/
      background: var(--error-color-background);
      opacity: 0.16;
      border-color: transparent;
      color: var(--error-color-background);
    }

.p-button.p-button-link {
  color: var(--link-color);
  background: transparent;
  border: transparent;
}

  .p-button.p-button-link:enabled:hover {
    background: transparent;
    color: var(--link-color);
    border-color: transparent;
  }

    .p-button.p-button-link:enabled:hover .p-button-label {
      text-decoration: underline;
    }

  .p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: 0 0 0 0.1rem var(--link-color-box-shadow);
    border-color: transparent;
  }

  .p-button.p-button-link:enabled:active {
    background: transparent;
    color: var(--link-color);
    border-color: transparent;
  }

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: var(--primary-color-pulse);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}

  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: var(--text-color);
    border-color: transparent;
    background: var(--very-light-gray);
  }

  .p-carousel .p-carousel-content .p-carousel-prev:focus,
  .p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

.p-carousel .p-carousel-indicators {
  padding-bottom: 61px;
}

  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
      background-color: var(--very-light-gray);
      width: 2rem;
      height: 0.5rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 0;
      display: none;
    }

      .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
        background: var(--light-gray);
      }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background: var(--primary-color-ultralight);
      color: var(--text-color);
      display: none;
    }
.p-datatable table {
  border-collapse: collapse;
  table-layout: auto !important;
  width: 100%;
  font-size: 14px !important;
}
.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-datatable-header {
  background: var(--primary-color-ultralight);
  /*color: var(--text-color);*/
  color: var(--text-color-dark);
  border: 1px solid var(--primary-background-color);
  border-width: 2px 0 1px 0;
  padding: .85rem 1rem .5rem;
  font-weight: 600;
}
.p-datatable-wrapper{
  overflow: auto;
}
.p-datatable .p-datatable-footer {
  background: var(--ultralight-gray);
  /*color: var(--text-color);*/
  color: var(--text-color-dark);
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: .55rem .55rem .55rem .55rem !important;
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  font-weight: 600;
  /*color: var(--text-color);*/
  color: var(--text-color-dark);
  background: var(--primary-background-color);
  transition: box-shadow 0.2s;
  text-transform: uppercase;
}

.p-datatable .p-datatable-thead > tr > td {
  /*color: var(--text-color);*/
  color: var(--text-color-dark);
  text-transform: uppercase;
}

.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: var(--text-color);
  background: var(--ultralight-gray);
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  /*color: var(--text-color);*/
  color: var(--text-color-dark);
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  /*color: var(--text-color);*/
  color: var(--text-color-dark);
  background: var(--primary-color-ultralight);
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  /*background: var(--very-light-gray);
  color: var(--text-color);*/
  color: var(--text-color-dark);
}

  .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    /*color: var(--text-color);*/
    color: var(--text-color-dark);
  }

.p-datatable .p-sortable-column.p-highlight {
  background: var(--ultralight-gray);
  color: var(--primary-color-pulse);
}

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color-pulse);
  }

  .p-datatable .p-sortable-column.p-highlight:hover {
    /* background: var(--very-light-gray); */
    color: var(--primary-color-pulse);
  }

    .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
      color: var(--primary-color-pulse);
    }

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
  background: var(--primary-background-color);
  color: var(--text-color);
  transition: box-shadow 0.2s;
  /*outline-color: var(--primary-color-dark);*/
  outline: 0 0 1px 0 var(--medium-gray);
}

  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid var(--very-light-gray);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
  }

    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
      width: 2rem;
      height: 2rem;
      color: var(--text-color);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

      .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
        color: var(--text-color);
        border-color: transparent;
        background: var(--very-light-gray);
      }

      .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
      margin-right: 0.5rem;
    }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: var(--primary-color-ultralight);
    color: var(--text-color);
  }

  /* .p-datatable .p-datatable-tbody > tr.p-highlight:hover {
    background: var(--primary-color-light);
    color: var(--primary-color-text);
  } */

  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 var(--primary-color-ultralight);
  }

  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 var(--primary-color-ultralight);
  }

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: var(--very-light-gray);
  color: var(--text-color);
}

.p-datatable .p-column-resizer-helper {
  background: var(--primary-color-pulse);
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: none;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: var(--primary-color-ultralight);
    color: var(--text-color);
  }

    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
      color: var(--text-color);
    }

      .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
        color: var(--text-color);
      }

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-dataview-header {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--very-light-gray);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-dataview .p-dataview-content {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid var(--very-light-gray);
  border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: var(--text-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

  .p-column-filter-menu-button:hover {
    color: var(--text-color);
    border-color: transparent;
    background: var(--very-light-gray);
  }

  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: var(--very-light-gray);
    color: var(--text-color);
  }

  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: var(--primary-color-ultralight);
    color: var(--text-color);
  }

  .p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: var(--text-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border: 1px solid var(--primary-color-dark) !important; /*Added*/
}

  .p-column-filter-clear-button:hover {
    color: var(--text-color);
    border-color: transparent;
    background: var(--very-light-gray);
    border: 1px solid var(--primary-color-dark) !important; /*Added*/
  }

  .p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    color: var(--text-color) !important; /*Added*/
    background: var(--primary-background-color) !important; /*Added*/
    border: 1px solid var(--primary-color-dark) !important; /*Added*/
  }

.p-column-filter-overlay {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
}

  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.5rem 0;
  }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
      margin: 0;
      padding: 0.5rem 1rem;
      border: 0 none;
      color: var(--text-color);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

      .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
        color: var(--text-color);
        background: var(--primary-color-ultralight);
      }

      .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
        color: var(--text-color);
        background: var(--very-light-gray);
      }

      .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
      }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
      border-top: 1px solid var(--light-gray);
      margin: 0.25rem 0;
    }

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: var(--text-color);
  background: var(--ultralight-gray);
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1rem;
  border-bottom: 1px solid var(--light-gray);
}

  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
    color: var(--remove-rule-color) !important; /*Added*/
    background: var(--primary-background-color) !important; /*Added*/
  }
    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button:hover {
      margin-top: 0.5rem;
      opacity: 1 !important; /*Added*/
      color: var(--remove-rule-color) !important; /*Added*/
      background: var(--primary-background-color) !important; /*Added*/
      text-decoration: underline; /*Added*/
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button:active {
      margin-top: 0.5rem;
      opacity: 1 !important; /*Added*/
      color: var(--remove-rule-color) !important; /*Added*/
      background: var(--primary-background-color) !important; /*Added*/
    }

.p-column-filter-overlay-menu .p-column-filter-add-button {
  margin-top: 0.5rem;
  color: var(--primary-color-pulse) !important; /*Added*/
  border: 0px solid !important; /*Added*/
  background: var(--primary-background-color) !important; /*Added*/
}

  .p-column-filter-overlay-menu .p-column-filter-add-button:hover {
    margin-top: 0.5rem;
    opacity: 1 !important; /*Added*/
    color: var(--primary-color-pulse) !important; /*Added*/
    border: 0px solid !important; /*Added*/
    background: var(--primary-background-color) !important; /*Added*/
    text-decoration: underline; /*Added*/
  }

  .p-column-filter-overlay-menu .p-column-filter-add-button:active {
    margin-top: 0.5rem;
    opacity: 1; /*Added*/
    color: var(--primary-color-pulse) !important; /*Added*/
    border: 0px solid !important; /*Added*/
    background: var(--primary-background-color) !important; /*Added*/
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1rem;
  color: var(--primary-color-pulse) !important; /*Added*/
  border: 0px solid !important; /*Added*/
  background: var(--primary-background-color) !important; /*Added*/
}
  .p-column-filter-overlay-menu .p-column-filter-add-rule:hover {
    padding: 0.5rem 1rem;
    color: var(--primary-color-pulse) !important; /*Added*/
    border: 0px solid !important; /*Added*/
    background: var(--primary-background-color) !important; /*Added*/
    text-decoration: underline; /*Added*/
  }

  .p-column-filter-overlay-menu .p-column-filter-add-rule:active {
    padding: 0.5rem 1rem;
    color: var(--primary-color-pulse) !important; /*Added*/
    border: 0px solid !important; /*Added*/
    background: var(--primary-background-color) !important; /*Added*/
  }

  .p-column-filter-overlay-menu .p-column-filter-add-rule:focus {
    padding: 0.5rem 1rem;
    color: var(--primary-color-pulse) !important; /*Added*/
    border: 0px solid !important; /*Added*/
    border: 0px solid red !important; /*Added*/
    background: var(--primary-background-color) !important; /*Added*/
    box-shadow: unset !important; /*Added*/
  }

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1rem;
}

.fc .fc-view-container th {
  background: var(--ultralight-gray);
  border: 1px solid var(--light-gray);
  color: var(--text-color);
}

.fc .fc-view-container td.fc-widget-content {
  background: var(--primary-background-color);
  border: 1px solid var(--light-gray);
  color: var(--text-color);
}

.fc .fc-view-container td.fc-head-container {
  border: 1px solid var(--light-gray);
}

.fc .fc-view-container .fc-row {
  border-right: 1px solid var(--light-gray);
}

.fc .fc-view-container .fc-event {
  background: var(--primary-color-dark);
  border: 1px solid var(--primary-color-dark);
  color: var(--primary-color-text);
}

.fc .fc-view-container .fc-divider {
  background: var(--ultralight-gray);
  border: 1px solid var(--light-gray);
}

.fc .fc-toolbar .fc-button {
  color: var(--primary-color-text);
  background: var(--primary-color-pulse);
  border: 1px solid var(--primary-color-pulse);
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

  .fc .fc-toolbar .fc-button:enabled:hover {
    background: var(--primary-color-dark);
    color: var(--primary-color-text);
    border-color: var(--primary-color-dark);
  }

  .fc .fc-toolbar .fc-button:enabled:active {
    background: var(--primary-color-dark);
    color: var(--primary-color-text);
    border-color: var(--primary-color-dark);
  }

    .fc .fc-toolbar .fc-button:enabled:active:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

  .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
  }

    .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
      content: "";
    }

  .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
  }

    .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
      content: "";
    }

  .fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

  .fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: var(--primary-background-color);
    border: 1px solid var(--primary-color-dark);
    color: var(--text-color);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

    .fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
      background: var(--very-light-gray);
      border-color: var(--primary-color-dark);
      color: var(--text-color);
    }

    .fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
      background: var(--primary-color-pulse);
      border-color: var(--primary-color-pulse);
      color: var(--primary-color-text);
    }

      .fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
        background: var(--primary-color-dark);
        border-color: var(--primary-color-dark);
        color: var(--primary-color-text);
      }

    .fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      z-index: 1;
    }

.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

  .fc .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .fc .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}

  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
  }

.p-orderlist .p-orderlist-header {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

  .p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 600;
  }

.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: var(--primary-background-color);
  border: 1px solid var(--light-gray);
  border-bottom: 0 none;
}

  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.5rem;
  }

  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.5rem;
    color: var(--text-color);
  }

.p-orderlist .p-orderlist-list {
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  padding: 0.5rem 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: var(--text-color);
    background: transparent;
    transition: transform 0.2s, box-shadow 0.2s;
  }

    .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
      background: var(--very-light-gray);
      color: var(--text-color);
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
      color: var(--text-color);
      background: var(--primary-color-ultralight);
    }

  .p-orderlist .p-orderlist-list .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
    background-color: #89c8f7;
  }

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }

    .p-orderlist .p-orderlist-controls {
      padding: 1rem;
      flex-direction: row;
    }

      .p-orderlist .p-orderlist-controls .p-button {
        margin-right: 0.5rem;
        margin-bottom: 0;
      }

        .p-orderlist .p-orderlist-controls .p-button:last-child {
          margin-right: 0;
        }
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: var(--very-light-gray);
  color: var(--text-color);
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: var(--primary-color-ultralight);
  color: var(--text-color);
}

  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #6cbbf5;
  }

.p-organizationchart .p-organizationchart-line-down {
  background: var(--light-gray);
}

.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid var(--light-gray);
  border-color: var(--light-gray);
}

.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid var(--light-gray);
  border-color: var(--light-gray);
}

.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  padding: 1rem;
}

  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
  }

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

.p-paginator {
  background: var(--primary-background-color);
  color: var(--text-color);
  /* border: solid var(--very-light-gray); */
  border-width: 0;
  padding: 0.5rem 1rem 0.5rem 2rem;
  border-radius: 23px;
  min-width: 800px;
}

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: var(--text-color);
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
  }

    .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
      background: var(--very-light-gray);
      border-color: transparent;
      color: var(--text-color);
    }

  .p-paginator .p-paginator-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .p-paginator .p-paginator-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    height: 2.357rem;
  }

    .p-paginator .p-dropdown .p-dropdown-label {
      padding-right: 0;
    }

  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: var(--text-color);
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: var(--text-color);
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 23px;
  }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      background: var(--primary-color-ultralight);
      border-color: var(--primary-color-ultralight);
      color: var(--text-color);
    }

    .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
      background: var(--very-light-gray);
      border-color: transparent;
      color: var(--text-color);
    }

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}

  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
  }

.p-picklist .p-picklist-header {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

  .p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 600;
  }

.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: var(--primary-background-color);
  border: 1px solid var(--light-gray);
  border-bottom: 0 none;
}

  .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.5rem;
  }

  .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.5rem;
    color: var(--text-color);
  }

.p-picklist .p-picklist-list {
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  padding: 0.5rem 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: var(--text-color);
    background: transparent;
    transition: transform 0.2s, box-shadow 0.2s;
  }

    .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
      background: var(--very-light-gray);
      color: var(--text-color);
    }

    .p-picklist .p-picklist-list .p-picklist-item:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

    .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
      color: var(--text-color);
      background: var(--primary-color-ultralight);
    }

  .p-picklist .p-picklist-list .p-picklist-droppoint.p-picklist-droppoint-highlight {
    background-color: #89c8f7;
  }

  .p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: 0.5rem 1rem;
    color: var(--text-color);
  }

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }

    .p-picklist .p-picklist-buttons {
      padding: 1rem;
      flex-direction: row;
    }

      .p-picklist .p-picklist-buttons .p-button {
        margin-right: 0.5rem;
        margin-bottom: 0;
      }

        .p-picklist .p-picklist-buttons .p-button:last-child {
          margin-right: 0;
        }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
      content: "";
    }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
      content: "";
    }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
      content: "";
    }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
      content: "";
    }
}

.p-timeline .p-timeline-event-marker {
  border: 1px solid var(--primary-color-pulse);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: var(--primary-background-color);
}

.p-timeline .p-timeline-event-connector {
  background-color: var(--light-gray);
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  padding: 1rem;
  border-radius: 3px;
}

  .p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
  }

    .p-tree .p-tree-container .p-treenode .p-treenode-content {
      border-radius: 3px;
      transition: box-shadow 0.2s;
      padding: 0.2rem;
    }

      .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
        margin-right: 0.5rem;
        width: 2rem;
        height: 2rem;
        color: #6e11f2;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      }

        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
          color: var(--text-color);
          border-color: transparent;
          background: var(--very-light-gray);
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
        }

      .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
        margin-right: 0.5rem;
        color: var(--text-color);
      }

      .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
        margin-right: 0.5rem;
      }

        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
          color: var(--text-color);
        }

      .p-tree .p-tree-container .p-treenode .p-treenode-content:active {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

      .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight:focus {
        background: var(--primary-color-ultralight);
        color: var(--text-color);
      }

        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
          color: var(--text-color);
        }

          .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
          .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
            color: var(--text-color);
          }

      .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background: var(--very-light-gray);
        color: var(--text-color);
      }

      .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
        background: var(--very-light-gray);
        color: var(--text-color);
      }

  .p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
  }

    .p-tree .p-tree-filter-container .p-tree-filter {
      width: 100%;
      padding-right: 1.5rem;
    }

    .p-tree .p-tree-filter-container .p-tree-filter-icon {
      right: 0.5rem;
      color: var(--text-color);
    }

  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
  }

  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }

  .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #89c8f7;
  }

  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 3px;
    border: 1px solid var(--light-gray);
    background-color: var(--primary-background-color);
    color: var(--text-color);
    padding: 0.5rem;
    transition: box-shadow 0.2s;
  }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
      background-color: var(--primary-color-ultralight);
      color: var(--text-color);
    }

      .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
        color: var(--text-color);
      }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
      margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
      background-color: inherit;
      color: #6e11f2;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: var(--very-light-gray);
      color: var(--text-color);
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

/* Page Header and Search Row */

div.table-search-row {
  padding: 20px 40px 20px;
  background: var(--very-light-gray);
  max-height: 87px;
}

div.table-search-row #lblPageHeader {
  font-size: 22px;
  color: #2E2E2E;
  font-family: "HelveticaNeue";
  font-weight: bold;
  position: relative;
}
.title-main{
  font-size: 22px;
  color: #2E2E2E !important;
  font-family: "HelveticaNeue";
  font-weight: 500;
  position: relative;
}

.title-main-26{
  font-size: 26px;
  color: #2E2E2E !important;
  font-family: "HelveticaNeue";
  position: relative;
  font-weight: bold;
}

div.table-search-row .field-title {
  font-size: 12px;
  color: var(--text-color);
  position: relative;
}

div.table-search-row .search-input {
  margin-left: 20px;
  margin-bottom: 3px;
  padding: 10px 50px 10px 15px;
  width: 27.5em;
  height: 44px !important;
}
        div.table-search-row .search-label
        {
          padding-left:1.35em; color:var(--medium-gray); font-weight: 100; font-size: 14px
        }
        div.table-search-row .execute-search
        {
          border: none;
          margin-left: -45px;
          color: var(--medium-gray) !important;
          padding: 0rem 0 0px 0;
          min-height: 10px;
          background: transparent !important;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
        div.table-search-row .p-button.filters{
        border-color: var(--light-gray);
        color: var(--medium-gray);
        margin-left: 1em;
        }
        div.table-search-row .p-button.filters span.p-button-icon{
          color: var(--medium-gray) !important;
        }
/* Filter Row */
        .p-datatable-filter-header{
          background: var(--primary-color-ultralight);
          /* color: var(--text-color); */
          color: var(--text-color-dark);
          border: 1px solid var(--primary-background-color);
          border-width: 2px 0 1px 0;
          padding: 0.85rem 1rem 0.5rem;
          font-weight: 600;
        }
        .p-datatable-filter-header table{
        margin: 0px auto;
        width: 90%;
        }
        .p-datatable-filter-header .filter-add{width:2.5rem; margin-left:0.5rem; margin-right:0.5rem;border-radius:0px;min-height: 2rem;}



.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--very-light-gray);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-footer {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: var(--text-color);
  background: var(--ultralight-gray);
  transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: var(--text-color);
  background: var(--ultralight-gray);
}

.p-treetable .p-sortable-column {
  outline-color: var(--primary-color-dark);
}

  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: var(--text-color);
    margin-left: 0.5rem;
  }

  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: var(--text-color);
    background: var(--primary-color-ultralight);
    margin-left: 0.5rem;
  }

  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: var(--very-light-gray);
    color: var(--text-color);
  }

    .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: var(--text-color);
    }

  .p-treetable .p-sortable-column.p-highlight {
    background: var(--ultralight-gray);
    color: var(--primary-color-pulse);
  }

    .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: var(--primary-color-pulse);
    }

.p-treetable .p-treetable-tbody > tr {
  background: var(--primary-background-color);
  color: var(--text-color);
  transition: box-shadow 0.2s;
  outline-color: var(--primary-color-dark);
}

  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid var(--very-light-gray);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
  }

    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
      width: 2rem;
      height: 2rem;
      color: var(--text-color);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;
    }

      .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
        color: var(--text-color);
        border-color: transparent;
        background: var(--very-light-gray);
      }

      .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

    .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
      margin-right: 0.5rem;
    }

      .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
        color: var(--text-color);
      }

  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: var(--primary-color-ultralight);
    color: var(--text-color);
  }

    .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
      color: var(--text-color);
    }

      .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
        color: var(--text-color);
      }

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: var(--very-light-gray);
  color: var(--text-color);
}

  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: var(--text-color);
  }

.p-treetable .p-column-resizer-helper {
  background: var(--primary-color-pulse);
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: var(--ultralight-gray);
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--very-light-gray);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-virtualscroller .p-virtualscroller-content {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  padding: 0;
}

.p-virtualscroller .p-virtualscroller-footer {
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--very-light-gray);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid var(--light-gray);
  color: var(--text-color);
  background: var(--ultralight-gray);
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem;
  }

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: var(--very-light-gray);
  border-color: var(--light-gray);
  color: var(--text-color);
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: var(--ultralight-gray);
  border-color: var(--light-gray);
  color: var(--text-color);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: var(--light-gray);
  background: var(--very-light-gray);
  color: var(--text-color);
}

.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-card {
  background: var(--primary-background-color);
  color: var(--text-color);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

  .p-card .p-card-body {
    padding: 2rem;
  }

  .p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }

  .p-card .p-card-content {
    padding: 1rem 0;
  }

  .p-card .p-card-footer {
    padding: 1rem 0 0 0;
  }

.p-divider .p-divider-content {
  background-color: var(--primary-background-color);
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}

  .p-divider.p-divider-horizontal:before {
    border-top: 1px var(--light-gray);
  }

  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
  }

.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}

  .p-divider.p-divider-vertical:before {
    border-left: 1px var(--light-gray);
  }

  .p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
  }

.p-fieldset {
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  border-radius: 3px;
}

  .p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid var(--light-gray);
    color: var(--text-color);
    background: var(--ultralight-gray);
    font-weight: 600;
    border-radius: 3px;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
      padding: 1rem;
      color: var(--text-color);
      border-radius: 3px;
      transition: box-shadow 0.2s;
    }

      .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
        margin-right: 0.5rem;
      }

      .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
      background: var(--very-light-gray);
      border-color: var(--light-gray);
      color: var(--text-color);
    }

  .p-fieldset .p-fieldset-content {
    padding: 1rem;
  }

.p-panel .p-panel-header {
  border: 1px solid var(--light-gray);
  padding: 1rem;
  background: var(--ultralight-gray);
  color: var(--text-color);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

  .p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
  }

  .p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: var(--text-color);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
      color: var(--text-color);
      border-color: transparent;
      background: var(--very-light-gray);
    }

    .p-panel .p-panel-header .p-panel-header-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}

/* .p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 0 none;
} */

.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: var(--ultralight-gray);
  border: 0 none;
}

.p-splitter {
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  border-radius: 3px;
  color: var(--text-color);
}

  .p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: var(--ultralight-gray);
  }

    .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
      background: var(--light-gray);
    }

  .p-splitter .p-splitter-gutter-resizing {
    background: var(--light-gray);
  }

.p-tabview .p-tabview-nav {
  background: var(--primary-background-color);
  border: 1px solid var(--light-gray);
  border-width: 0 0 2px 0;
}

  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      border-width: 0 0 2px 0;
      border-color: transparent transparent var(--light-gray) transparent;
      background: var(--primary-background-color);
      color: var(--text-color);
      padding: 1rem;
      font-weight: 600;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      transition: box-shadow 0.2s;
      margin: 0 0 2px 0;
    }

      .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      background: var(--primary-background-color);
      border-color: var(--text-color);
      color: var(--text-color);
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: var(--primary-background-color);
      border-color: var(--primary-color-pulse);
      color: var(--primary-color-pulse);
    }

.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}

.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-panels {
  background: var(--primary-background-color);
  padding: 1rem;
  border: 0 none;
  color: var(--text-color);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-toolbar {
  background: var(--ultralight-gray);
  border: 1px solid var(--light-gray);
  padding: 1rem;
  border-radius: 3px;
}

  .p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
  }
.secondary-nav{
  border-bottom: 0.5px solid #ccc;
  max-height: 3rem;
  min-height: 3rem;
  padding-top: 10px;
}
.secondary-nav table{
  margin-left:30px;
}
.secondary-nav a{
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: none;
  color: var(--text-color-dark);
  padding: 5px 10px 5px 10px;


}
.secondary-nav a:hover, .secondary-nav a:active, .secondary-nav a.active{
background-color: var(--stats-background-color);
border-radius: 22px;
padding: 5px 10px 5px 10px;
color: var(--link-alt-color);
}
.p-confirm-popup {
  background: #2e2e2e;
  color: white;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

  .p-confirm-popup .p-confirm-popup-content {
    padding: 1rem;
  }

  .p-confirm-popup .p-confirm-popup-footer {
    text-align: left;
    padding: 0.5rem 1rem 1rem;
  }

    .p-confirm-popup .p-confirm-popup-footer button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }

      .p-confirm-popup .p-confirm-popup-footer button:last-child {
        margin: 0;
      }

  .p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #2e2e2e;
  }

  .p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #2e2e2e;
  }

  .p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: var(--primary-background-color);
  }

  .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: var(--primary-background-color);
  }

  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-reject{
    border:2px solid white;
    background-color: red;
    color: white;
  }
  .p-button-text.p-confirm-popup-reject.p-button-sm.p-button.p-component:enabled:hover{
    border:2px solid white !important;
    background-color: red !important;
    color: white;
  }

  .p-confirm-popup .p-confirm-popup-accept{
    border: none;
    background-color: transparent;
    color: white;
  }

  .p-confirm-popup .p-confirm-popup-accept:enabled:hover{
    border: none !important;
    background-color: transparent !important;
    color: white;

  }

  /* .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  } */

.p-dialog {
  border-radius: 3px;
  /* box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); */
  /* box-shadow: 6px 7px 20px 7px rgba(0,0,0,.2); */
  border: 0 none;
}

  .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: var(--primary-background-color);
    color: var(--text-color);
    /* padding: 1.5rem; */
    padding: 18px 25px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

    .p-dialog .p-dialog-header .p-dialog-title {
      font-weight: bold;
      font-size: 24px;
    }

    .custom-dialog .p-dialog .p-dialog-header .p-dialog-header-icon {
      width: 2rem;
      height: 2rem;
      color: var(--primary-color-text);
      border-color: transparent;
      /* background: var(--very-light-gray);
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; */
      margin-right: 0.5rem;
    }

      .custom-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
        color: var(--primary-color-text);
        border-color: transparent;
        /* background: var(--very-light-gray); */
      }

      .custom-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        /* box-shadow: 0 0 0 0.1rem var(--primary-color-dark); */
      }

      .custom-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
        margin-right: 0;
      }

  .p-dialog .p-dialog-content {
    background: var(--primary-background-color);
    color: var(--text-color);
    padding: 0 1.5rem 2rem 1.5rem;
    overflow-x: hidden;
  }

  .p-dialog .p-dialog-footer {
    border-top: 1px solid #E0E0E0;
    background: var(--primary-background-color);
    color: var(--text-color);
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

    .p-dialog .p-dialog-footer button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
  }

.p-dialog-mask.p-component-overlay {
   /* background-color: var(--overlay-background-color); */
   background-color: rgb(0 0 0 / 71%);}

.p-overlaypanel {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

  .p-overlaypanel .p-overlaypanel-content {
    padding: 1rem;
  }

  .p-overlaypanel .p-overlaypanel-close {
    background: var(--primary-color-pulse);
    color: var(--primary-color-text);
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }

    .p-overlaypanel .p-overlaypanel-close:enabled:hover {
      background: var(--primary-color-dark);
      color: var(--primary-color-text);
    }

  .p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--primary-background-color);
  }

  .p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--primary-background-color);
  }

  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: var(--primary-background-color);
  }

  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: var(--primary-background-color);
  }

.p-sidebar {
  background: var(--primary-background-color);
  color: var(--text-color);
  padding: 1rem;
  border: 0 none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

  .p-sidebar .p-sidebar-close {
    width: 2rem;
    height: 2rem;
    color: var(--text-color);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

    .p-sidebar .p-sidebar-close:enabled:hover {
      color: var(--text-color);
      border-color: transparent;
      background: var(--very-light-gray);
    }

    .p-sidebar .p-sidebar-close:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

.p-sidebar-mask.p-component-overlay {
  background: var(--overlay-background-color);
}

.p-tooltip .p-tooltip-text {
  /*background: var(--text-color);*/
  /*color: var(--primary-color-text);*/
  /*background: var(--primary-color-ultralight);*/
  /*color: black;*/
  background: black;
  color: white;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width:auto;
  margin-left: -100px !important;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: black;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: black;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: black;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: black;
}

.p-fileupload .p-fileupload-buttonbar {
  background: var(--ultralight-gray);
  padding: 1rem;
  border: 1px solid var(--light-gray);
  color: var(--text-color);
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
  }

    .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

.p-fileupload .p-fileupload-content {
  background: var(--primary-background-color);
  padding: 2rem 1rem;
  border: 1px solid var(--light-gray);
  color: var(--text-color);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-fileupload .p-progressbar {
  height: 0.25rem;
}

.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
  word-break: break-all;
}

.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: var(--primary-color-dark);
  color: var(--primary-color-text);
  border-color: var(--primary-color-dark);
}

.p-fileupload-choose:not(.p-disabled):active {
  background: var(--primary-color-dark);
  color: var(--primary-color-text);
  border-color: var(--primary-color-dark);
}

.p-breadcrumb {
  background: var(--primary-background-color);
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  padding: 1rem;
}

  .p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow 0.2s;
    border-radius: 3px;
  }

    .p-breadcrumb ul li .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

    .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
    }

  .p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: var(--text-color);
  }

  .p-breadcrumb ul li:last-child .p-menuitem-text {
    color: var(--text-color);
  }

  .p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: var(--text-color);
  }

.p-contextmenu {
  padding: 0.25rem 0;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}

  .p-contextmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-contextmenu .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-contextmenu .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-contextmenu .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

    .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
      background: var(--very-light-gray);
    }

      .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--text-color);
      }

      .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
      }

      .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
      }

    .p-contextmenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

  .p-contextmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: var(--primary-background-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: var(--very-light-gray);
  }

    .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

  .p-contextmenu .p-menu-separator {
    border-top: 1px solid var(--light-gray);
    margin: 0.25rem 0;
  }

  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }

.p-megamenu {
  padding: 0.5rem;
  background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  border-radius: 3px;
}

  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    border-radius: 3px;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
      margin-left: 0.5rem;
    }

    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
      background: var(--very-light-gray);
    }

      .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--text-color);
      }

      .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
      }

      .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
      }

    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--very-light-gray);
  }

    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      color: var(--text-color);
    }

    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
      color: var(--text-color);
    }

    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
      color: var(--text-color);
    }

  .p-megamenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-megamenu .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-megamenu .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-megamenu .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

    .p-megamenu .p-menuitem-link:not(.p-disabled):hover {
      background: var(--very-light-gray);
    }

      .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--text-color);
      }

      .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
      }

      .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
      }

    .p-megamenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

  .p-megamenu .p-megamenu-panel {
    background: var(--primary-background-color);
    color: var(--text-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: var(--text-color);
    background: var(--primary-background-color);
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .p-megamenu .p-megamenu-submenu {
    padding: 0.25rem 0;
    width: 12.5rem;
  }

    .p-megamenu .p-megamenu-submenu .p-menu-separator {
      border-top: 1px solid var(--light-gray);
      margin: 0.25rem 0;
    }

  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: var(--very-light-gray);
  }

    .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.25rem 0;
  }

.p-menu {
  padding: 0.25rem 0;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  width: 12.5rem;
}

  .p-menu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--primary-color-pulse);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-menu .p-menuitem-link .p-menuitem-text {
      color: var(--primary-color-pulse);
      font-weight: bold;

    }

    .p-menu .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-menu .p-menuitem-link .p-submenu-icon {
      color: var(--primary-color-pulse);
    }
    .menu-disabled .p-menuitem-text{
        color:var(--medium-gray) !important;
        opacity: 1 !important;
    }
    .p-menu .p-menuitem-link:not(.p-disabled):hover {
      background: var(--very-light-gray);
    }

      .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--primary-color-pulse);
      }

      .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
      }

      .p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
      }

    .p-menu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

  .p-menu.p-menu-overlay {
    background: var(--primary-background-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: var(--text-color);
    background: var(--primary-background-color);
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .p-menu .p-menu-separator {
    border-top: 1px solid var(--light-gray);
    margin: 0.25rem 0;
  }

  .groundmenu .p-menu{
    z-index: 1 !important;
  }

.p-menubar {
  padding: 0.5rem;
  /*background: var(--ultralight-gray);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  border-radius: 3px;*/
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 0px
}

  .p-menubar .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-menubar .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-menubar .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-menubar .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

    .p-menubar .p-menuitem-link:not(.p-disabled):hover {
      /*background: var(--very-light-gray);*/
      background: transparent;
      /*border: 2px solid var(--primary-color-dark);*/
    }

      .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--text-color);
        /*font-weight: 500;*/
      }

      .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
        /*font-weight: 500;*/
      }

      .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
        /*font-weight: 500;*/
      }

    .p-menubar .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      /*box-shadow: inset 0 0 0 0.15rem #a6d5fa;*/
    }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    /*border-radius: 3px;*/
    border-radius: 0px;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
      margin-left: 0.5rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
      /*background: var(--very-light-gray);*/
      background: transparent;
      /*box-shadow: 0px 3px 0 -1px rgba(33, 33, 33, 0.75);*/
      box-shadow: 0px 3px 0 -1px rgba(0, 179, 195, 0.75);
    }

      .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        /*color:  var(--text-color);*/
        color: var(--primary-color-pulse);
      }

      .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        /*color:  var(--text-color);*/
        color: var(--primary-color-pulse);
      }

      .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        /*color:  var(--text-color);*/
        color: var(--primary-color-pulse);
      }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      /*box-shadow: inset 0 0 0 0.15rem #a6d5fa;*/
      /*box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);*/
      box-shadow: 0px 3px 0 -1px rgba(0, 179, 195, 0.75);
    }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    /*background: var(--very-light-gray);*/
    background: transparent;
  }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      /*color:  var(--text-color);*/
      color: var(--primary-color-pulse);
    }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
      /*color:  var(--text-color);*/
      color: var(--primary-color-pulse);
    }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
      /*color:  var(--text-color);*/
      color: var(--primary-color-pulse);
    }



  /*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active:focus > .p-menuitem-link:not(.p-disabled):hover {*/
    /*background: var(--very-light-gray);*/
    /*background: transparent;
    color: var(--primary-color-pulse);
  }*/

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: var(--primary-color-pulse);
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):focus .p-submenu-icon {
    color: var(--primary-color-pulse);
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):focus .p-menuitem-text {
    color: var(--primary-color-pulse);
  }


    /*.p-menubar .p-menubar-root-list > .p-menuitem:focus > .p-menuitem-link .p-menuitem-text,
    .p-menubar .p-menubar-root-list > .p-menuitem:focus > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      color: var(--primary-color-pulse);
    }*/

  .p-menubar .p-submenu-list {
    padding: 0.25rem 0;
    background: var(--primary-background-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 12.5rem;
  }

    .p-menubar .p-submenu-list .p-menu-separator {
      border-top: 1px solid var(--light-gray);
      margin: 0.25rem 0;
    }

    .p-menubar .p-submenu-list .p-submenu-icon {
      font-size: 0.875rem;
    }

  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: var(--very-light-gray);
  }

    .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      /*color: var(--text-color);*/
      color: var(--text-color);
    }

    .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }

    .p-menubar .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: var(--text-color);
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

      .p-menubar .p-menubar-button:hover {
        color: var(--text-color);
        background: var(--very-light-gray);
      }

      .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.25rem 0;
      background: var(--primary-background-color);
      border: 0 none;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      width: 100%;
    }

      .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid var(--light-gray);
        margin: 0.25rem 0;
      }

      .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
      }

      .p-menubar .p-menubar-root-list > .p-menuitem {
        width: 100%;
        position: static;
      }

        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
          padding: 0.75rem 1rem;
          color: var(--text-color);
          border-radius: 0;
          transition: box-shadow 0.2s;
          user-select: none;
        }

          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
            color: var(--text-color);
          }

          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
            color: var(--text-color);
            margin-right: 0.5rem;
          }

          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
            color: var(--text-color);
          }

          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
            background: var(--very-light-gray);
          }

            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
              color: var(--text-color);
            }

            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
              color: var(--text-color);
            }

            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
              color: var(--text-color);
            }

          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
          }

          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
            margin-left: auto;
            transition: transform 0.2s;
          }

        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
          transform: rotate(-180deg);
        }

      .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
      }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
          transition: transform 0.2s;
          transform: rotate(90deg);
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
          transform: rotate(-90deg);
        }

      .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
      }

      .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.25rem;
      }

      .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem;
      }

      .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem;
      }

      .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem;
      }

      .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem;
      }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
}
.fa.blue{
  color: var(--primary-color-pulse);
  margin-left: 10px;
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  border: 1px solid var(--light-gray);
  color: var(--text-color);
  background: var(--ultralight-gray);
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

  .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: 0.5rem;
  }

  .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0.5rem;
  }

  .p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: var(--very-light-gray);
  border-color: var(--light-gray);
  color: var(--text-color);
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

  .p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: var(--ultralight-gray);
    border-color: var(--light-gray);
    color: var(--text-color);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: var(--light-gray);
    background: var(--very-light-gray);
    color: var(--text-color);
  }

.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid var(--light-gray);
  background: var(--primary-background-color);
  color: var(--text-color);
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
      background: var(--very-light-gray);
    }

      .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--text-color);
      }

      .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
      }

      .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
      }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
      margin-right: 0.5rem;
    }

  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;
  }

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
    border-radius: 0;
  }

  .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
  }

  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
    border-top: 0 none;
  }

  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
    border-top: 0 none;
  }

  .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

.p-slidemenu {
  padding: 0.25rem 0;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  width: 12.5rem;
}

  .p-slidemenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-slidemenu .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-slidemenu .p-menuitem-link .p-menuitem-icon {
      color: var(--text-color);
      margin-right: 0.5rem;
    }

    .p-slidemenu .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

    .p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
      background: var(--very-light-gray);
    }

      .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--text-color);
      }

      .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
      }

      .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
      }

    .p-slidemenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

  .p-slidemenu.p-slidemenu-overlay {
    background: var(--primary-background-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-slidemenu .p-slidemenu-list {
    padding: 0.25rem 0;
    background: var(--primary-background-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: var(--very-light-gray);
  }

    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
      color: var(--text-color);
    }

    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
      color: var(--text-color);
    }

  .p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid var(--light-gray);
    margin: 0.25rem 0;
  }

  .p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
  }

  .p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: var(--text-color);
  }

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  background: var(--primary-background-color);
}

  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: var(--text-color);
    border: 1px solid var(--very-light-gray);
    background: var(--primary-background-color);
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
  }

  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: var(--text-color);
  }

  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--primary-color-ultralight);
  color: var(--text-color);
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: var(--text-color);
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid var(--light-gray);
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}
ul.p-tabmenu-nav {
margin-left: 45px;
}
.p-tabmenu .p-tabmenu-nav .p-input-icon-right {
  background: var(--primary-background-color);
  /*border: 1px solid var(--light-gray);*/
  border: 1px solid transparent;
  border-width: 0 0 2px 0;
}

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 10px;
  }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      /*border: solid var(--light-gray);*/
      border: solid var(--primary-color-pulse);
      border-width: 0 0 2px 0;
      /*border-color: transparent transparent var(--light-gray) transparent;*/
      border-color: transparent transparent transparent transparent;
      background: var(--primary-background-color);
      /*color: #6c757d;*/
      color: var(--text-color-dark);
      padding: 0 0 .25rem 0;
      font-weight: bold;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      transition: box-shadow 0.2s;
      margin: 0 1rem -2px 1rem;

    }

      .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
        /*margin-right: 0.5rem;*/
        margin-left: 0.5rem;
        order: 1;
      }

      .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        /*box-shadow: 0 0 0 0.1rem var(--primary-color-dark);*/
        box-shadow: 0 0 0 0.2rem transparent;
      }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      background: var(--primary-background-color);
      /*border-color: #6c757d;*/
      /*color: #6c757d;*/
      border-color: var(--text-color);
      color: var(--text-color);
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight:hover .p-menuitem-link {
      background: var(--primary-background-color);
      border-color: var(--primary-color-dark);
      color: var(--text-color-dark);
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      background: var(--primary-background-color);
      border-color: var(--primary-color-pulse);
      color: var(--text-color-dark);
    }

.p-tieredmenu {
  padding: 0.25rem 0;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  width: 12.5rem;
}

  .p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--primary-color-pulse);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

    .p-tieredmenu .p-menuitem-link .p-menuitem-text {
      color: var(--primary-color-pulse);
      font-weight: bold;
    }

    .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
      color: var(--primary-color-pulse);
      margin-right: 0.5rem;
    }

    .p-tieredmenu .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

    .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
      background: var(--very-light-gray);
    }

      .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: var(--text-color);
      }

      .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: var(--text-color);
      }

      .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: var(--text-color);
      }

    .p-tieredmenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem var(--primary-color-dark);
    }

  .p-tieredmenu.p-tieredmenu-overlay {
    background: var(--primary-background-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-tieredmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: var(--primary-background-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: var(--very-light-gray);
  }

    .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
    }

    .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: var(--text-color);
    }

  .p-tieredmenu .p-menu-separator {
    border-top: 1px solid var(--light-gray);
    margin: 0.25rem 0;
  }

  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
  }

.p-inline-message {
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 3px;
}

  .p-inline-message.p-inline-message-info {
    background: var(--info-color-background);
    border: solid var(--info-color-border);
    border-width: 0px;
    color: var(--info-color);
  }

    .p-inline-message.p-inline-message-info .p-inline-message-icon {
      color: var(--info-color);
    }

  .p-inline-message.p-inline-message-success {
    background: var(--success-color-background);
    border: solid var(--success-color-border);
    border-width: 0px;
    color: var(--success-color);
  }

    .p-inline-message.p-inline-message-success .p-inline-message-icon {
      color: var(--success-color);
    }

  .p-inline-message.p-inline-message-warn {
    background: var(--warn-color-background);
    border: solid var(--warn-color-border);
    border-width: 0px;
    color: var(--warn-color);
  }

    .p-inline-message.p-inline-message-warn .p-inline-message-icon {
      color: var(--warn-color);
    }

  .p-inline-message.p-inline-message-error {
    background: var(--error-color-background);
    border: solid #e60017;
    border-width: 0px;
    color: var(--error-color);
  }

    .p-inline-message.p-inline-message-error .p-inline-message-icon {
      color: var(--error-color);
    }

  .p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .p-inline-message .p-inline-message-text {
    font-size: 1rem;
  }

  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }

.p-message {
  margin: 1rem 0;
  border-radius: 3px;
}

  .p-message .p-message-wrapper {
    padding: 1rem 1.5rem;
  }

  .p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

    .p-message .p-message-close:hover {
      background: rgba(255, 255, 255, 0.3);
    }

    .p-message .p-message-close:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

  .p-message.p-message-info {
    background: var(--info-color-background);
    border: solid var(--info-color-border);
    border-width: 0 0 0 6px;
    color: var(--info-color);
  }

    .p-message.p-message-info .p-message-icon {
      color: var(--info-color);
    }

    .p-message.p-message-info .p-message-close {
      color: var(--info-color);
    }

  .p-message.p-message-success {
    background: var(--success-color-background);
    border: solid var(--success-color-border);
    border-width: 0 0 0 6px;
    color: var(--success-color);
  }

    .p-message.p-message-success .p-message-icon {
      color: var(--success-color);
    }

    .p-message.p-message-success .p-message-close {
      color: var(--success-color);
    }

  .p-message.p-message-warn {
    background: var(--warn-color-background);
    border: solid var(--warn-color-border);
    border-width: 0 0 0 6px;
    color: var(--warn-color);
  }

    .p-message.p-message-warn .p-message-icon {
      color: var(--warn-color);
    }

    .p-message.p-message-warn .p-message-close {
      color: var(--warn-color);
    }

  .p-message.p-message-error {
    background: var(--error-color-background);
    border: solid var(--error-color-border);
    border-width: 0 0 0 6px;
    color: var(--error-color);
  }

    .p-message.p-message-error .p-message-icon {
      color:  var(--error-color);
    }

    .p-message.p-message-error .p-message-close {
      color: var(--error-color);
    }

  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }

  .p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .p-message .p-message-summary {
    font-weight: 700;
  }

  .p-message .p-message-detail {
    margin-left: 0.5rem;
  }

.p-toast {
  opacity: 0.9;
  z-index: 999999 ;
}

  .p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
  }

    .p-toast .p-toast-message .p-toast-message-content {
      padding: 1rem;
      border-width: 0 0 0 6px;
    }

      .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
        margin: 0 0 0 1rem;
      }

      .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
        font-size: 2rem;
      }

      .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
        font-weight: 700;
      }

      .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
        margin: 0.5rem 0 0 0;
      }

    .p-toast .p-toast-message .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

      .p-toast .p-toast-message .p-toast-icon-close:hover {
        background: rgba(255, 255, 255, 0.3);
      }

      .p-toast .p-toast-message .p-toast-icon-close:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
      }

    .p-toast .p-toast-message.p-toast-message-info {
      background: var(--info-color-background);
      border: solid var(--info-color-border);
      border-width: 0 0 0 6px;
      color: var(--info-color);
    }

      .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
        color: var(--info-color);
      }

    .p-toast .p-toast-message.p-toast-message-success {
      background: var(--success-color-background);
      border: solid var(--success-color-border);
      border-width: 0 0 0 6px;
      color: var(--success-color);
    }

      .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
        color: var(--success-color);
      }

    .p-toast .p-toast-message.p-toast-message-warn {
      background: var(--warn-color-background);
      border: solid var(--warn-color-border);
      border-width: 0 0 0 6px;
      color: var(--warn-color);
    }

      .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
        color: var(--warn-color);
      }

    .p-toast .p-toast-message.p-toast-message-error {
      background: #fff;
      border: solid #f5f5f5;
      border-width: 0 0 0 6px;
      color: var(--error-color);
    }

      .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
        color: var(--error-color);
      }

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: var(--ultralight-gray);
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }

  .p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: var(--ultralight-gray);
  }

.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: var(--ultralight-gray);
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  margin: 0 0.5rem;
}

  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }

  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: var(--ultralight-gray);
  }

.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: var(--ultralight-gray);
  padding: 1rem;
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}

  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: var(--very-light-gray);
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }

    .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
      background: var(--light-gray);
    }

  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--primary-color-ultralight);
    color: var(--text-color);
  }

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.4);
  }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
      background: rgba(255, 255, 255, 0.6);
    }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--primary-color-ultralight);
    color: var(--text-color);
  }

.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: var(--ultralight-gray);
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
      background: rgba(255, 255, 255, 0.1);
      color: var(--ultralight-gray);
    }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    transition: box-shadow 0.2s;
  }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
  background-color: var(--light-gray);
  border-radius: 3px;
}

  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }

    .p-avatar.p-avatar-lg .p-avatar-icon {
      font-size: 1.5rem;
    }

  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }

    .p-avatar.p-avatar-xl .p-avatar-icon {
      font-size: 2rem;
    }

.p-avatar-group .p-avatar {
  border: 1px solid var(--primary-background-color);
}

.p-badge {
  background: var(--primary-color-pulse);
  color: var(--primary-color-text);
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

  .p-badge.p-badge-secondary {
    background-color: var(--primary-color-background);
    color: var(--primary-color-text);
  }

  .p-badge.p-badge-success {
    background-color: #689F38;
    color: var(--primary-color-text);
  }

  .p-badge.p-badge-info {
    background-color: #0288D1;
    color: var(--primary-color-text);
  }

  .p-badge.p-badge-warning {
    background-color: #FBC02D;
    color: #212529;
  }

  .p-badge.p-badge-danger {
    background-color: #D32F2F;
    color: var(--primary-color-text);
  }

  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }

  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }

.p-blockui.p-component-overlay {
  background: var(--overlay-background-color);
}

.p-chip {
  background-color: var(--primary-background-color);
  color: var(--primary-color-pulse);
  border-radius: 3px;
  padding: 0 0.5rem;
  font-size: 14px;
  border: 1px solid var(--primary-color-pulse);
}

  .p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .p-chip .p-chip-icon {
    margin-right: 0.5rem;
  }

  .p-chip .pi-chip-remove-icon {
    margin-left: 0.5rem;
  }

  .p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }

  .p-chip .pi-chip-remove-icon {
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

    .p-chip .pi-chip-remove-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
    }

.p-inplace .p-inplace-display {
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: var(--very-light-gray);
    color: var(--text-color);
  }

  .p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-dark);
  }

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: var(--light-gray);
  border-radius: 3px;
}

  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: var(--primary-color-pulse);
  }

  .p-progressbar .p-progressbar-label {
    color: var(--text-color);
    line-height: 1.5rem;
  }

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

  .p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
  }

    .p-scrolltop.p-link:hover {
      background: rgba(0, 0, 0, 0.8);
    }

  .p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: var(--ultralight-gray);
  }

.p-skeleton {
  background-color: var(--very-light-gray);
  border-radius: 3px;
}

  .p-skeleton:after {
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  }

.p-tag {
  background: var(--primary-color-pulse);
  color: var(--primary-color-text);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
}

  .p-tag.p-tag-success {
    background-color: #689F38;
    color: var(--primary-color-text);
  }

  .p-tag.p-tag-info {
    background-color: #0288D1;
    color: var(--primary-color-text);
  }

  .p-tag.p-tag-warning {
    background-color: #FBC02D;
    color: #212529;
  }

  .p-tag.p-tag-danger {
    background-color: #D32F2F;
    color: var(--primary-color-text);
  }

  .p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }

.p-terminal {
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--light-gray);
  padding: 1rem;
}

  .p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: var(--font-family-pulse);
  }

/* Customizations to the designer theme should be defined here */
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--primary-color-pulse);
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: var(--primary-color-pulse);
}

/******************************************************************************************
  Secondary Button Icon
 ******************************************************************************************/
.p-button.p-button-secondary .p-button-icon {
  color: var(--primary-color-pulse);
}

/******************************************************************************************
  Calendar - Date Picker
 ******************************************************************************************/
.p-datepicker:not(.p-disabled) table td.p-datepicker-today > span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--primary-color-dark);
  border-color: transparent;
  color: var(--primary-color-text);
}

/******************************************************************************************
  Dropdown
 ******************************************************************************************/
.p-dropdown .p-dropdown-trigger {
  color: var(--primary-color-pulse);
}

.p-dropdown-panel .p-dropdown-trigger {
  color: var(--primary-color-pulse);
}

/******************************************************************************************
  Pulse secondary button
 ******************************************************************************************/
.p-button.p-pulse-secondary .p-button-icon {
  color: var(--primary-color-pulse);
}

.p-button.p-pulse-secondary:enabled:hover .p-button-icon {
  color: var(--primary-color-text);
}

.p-button.p-pulse-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: var(--text-color-secondary);
  background: var(--primary-background-color);
  border: 1px solid var(--primary-color-pulse);
  font-weight: 400;
}

  .p-button.p-pulse-secondary:enabled:active:hover, .p-buttonset.p-button-secondary > .p-button:enabled:active:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:active:hover {
    background: var(--primary-color-dark);
    border-color: var(--primary-color-dark);
    color: var(--primary-color-text);
  }

/******************************************************************************************
  Alternative Pulse secondary button
 ******************************************************************************************/
.p-button.p-pulse-secondary-mono, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  /*color: var(--text-color-secondary);*/
  color: var(--primary-color-pulse);
  background: var(--primary-background-color);
  border: 1px solid var(--primary-color-pulse);
  font-weight: 400;
}

  .p-button.p-pulse-secondary-mono:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: var(--light-gray);
    border-color: var(--primary-color-dark);
    color: var(--text-color-secondary);
  }

.p-button.p-pulse-secondary-mono:enabled:hover .p-button-icon {
  color: var(--primary-color-pulse);
}

/******************************************************************************************
  Rounded Secondary Button
 ******************************************************************************************/
.p-button.p-button-secondary-rounded {
  border-radius: 2rem;
  background: var(--primary-background-color);
  color: var(--text-color);
}

  .p-button.p-button-secondary-rounded:hover {
    background: var(--very-light-gray);
    color: var(--text-color);
  }

/******************************************************************************************
  Progress Spinner Dialog
  Background is transparent, so it reflects the UI-blocked color.
 ******************************************************************************************/
.pulseProgressSpinnerDialog .ui-dialog {
  background-color: transparent !important;
  border: 1px;
  border-color: black;
}

  .pulseProgressSpinnerDialog .ui-dialog .ui-dialog-content {
    background-color: transparent !important;
    color: transparent !important;
    padding: 0px;
  }

/******************************************************************************************
  Opaque Progress Spinner Dialog
 ******************************************************************************************/
.pulseOpaqueProgressSpinnerDialog .ui-dialog {
  background-color: transparent !important;
  border: 1px;
  border-color: black;
}

  .pulseOpaqueProgressSpinnerDialog .ui-dialog .ui-dialog-content {
    background-color: white !important;
    color: transparent !important;
    opacity: 0.7;
  }

/******************************************************************************************
  Tiered Menu (Hamburger menu)
  Top-tier and sub-menu item widths are set, so item text does not wrap in the menu.
 ******************************************************************************************/
/* adding width for top-tier menu items */
.pulseHamburgerMenu .ui-tieredmenu {
  width: 175px;
}

  /* adding width for sub-menu items */
  .pulseHamburgerMenu .ui-tieredmenu .ui-submenu-list {
    width: 225px;
  }

/******************************************************************************************
  Menubar (when placed in a Toolbar)
  Background and border color is transparent.
  Sub-menu item widths are set, so item text does not wrap in the menu.
 ******************************************************************************************/
/* menubar has no border and a transparent background when used in a toolbar */
.pulseToolbarMenubar .ui-menubar {
  background-color: transparent !important;
  border: 0px solid transparent !important;
}

  /* adding width for sub-menu items */
  .pulseToolbarMenubar .ui-menubar .ui-submenu-list {
    width: 225px;
  }

/******************************************************************************************
  Toolbar Icon Help Menu
  Menu width is set, so item text does not wrap in the menu.
 ******************************************************************************************/
/* adding width for menu items */
.pulseHelpToolbarMenu .ui-menu {
  width: 225px;
}

/******************************************************************************************
  MultiSelect
 ******************************************************************************************/
/* same colors as delected item (only checkbox has Pulse primary coloring) */
.pulseMultiSelect .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: var(--text-color);
  background-color: var(--primary-color-text);
}

/* same as not selected hover color */
.pulseMultiSelect .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:not(.ui-state-disabled):hover {
  color: var(--text-color);
  background-color: var(--very-light-gray);
}

/******************************************************************************************
  Workbench Toolbar
 ******************************************************************************************/
.pulseWorkbenchToolbar .ui-toolbar {
  border: 0px;
  padding: 0px;
}

/******************************************************************************************
  Inline Message Error
 ******************************************************************************************/
.InquireValidationErrorMsg .ui-message.ui-widget.ui-corner-all.ui-message-error.ng-star-inserted {
  width: 285px;
}

/******************************************************************************************
  Calendar - Date Picker (shadow & border)
 ******************************************************************************************/
.p-datepicker {
  padding: 0.5rem;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color-dark);
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

/******************************************************************************************
  Calendar - Date Picker (smaller version)
 ******************************************************************************************/
.pulseDatePicker .p-datepicker {
  /*padding: 0.5rem;*/
  padding: 0.25rem;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color-dark);
  border-radius: 3px;
}

  .pulseDatePicker .p-datepicker:not(.p-datepicker-inline) {
    background: var(--primary-background-color);
    border: 0 none;
    /*box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);*/
  }

  .pulseDatePicker .p-datepicker-header {
    /*padding: 0.5rem;*/
    padding: 0.25rem;
    color: var(--text-color);
    background: var(--primary-background-color);
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid var(--light-gray);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

    .pulseDatePicker .p-datepicker-header .p-datepicker-prev,
    .pulseDatePicker .p-datepicker-header .p-datepicker-next {
      /*width: 2rem;*/
      /*height: 2rem;*/
      width: 1.5rem;
      height: 1.5rem;
      color: var(--text-color);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .pulseDatePicker .p-datepicker-header .p-datepicker-title {
      /*line-height: 2rem;*/
      line-height: 1.5rem;
    }

      .pulseDatePicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        /*margin-right: 0.5rem;*/
        margin-right: 0.25rem;
      }

  .pulseDatePicker table {
    font-size: 1rem;
    /*margin: 0.5rem 0;*/
    margin: 0.25rem 0;
  }

    .pulseDatePicker table th {
      /*padding: 0.5rem;*/
      padding: 0.25rem;
    }

      .pulseDatePicker table th > span {
        /*width: 2.5rem;*/
        /*height: 2.5rem;*/
        width: 1.5rem;
        height: 1.5rem;
      }

    .pulseDatePicker table td {
      /*padding: 0.5rem;*/
      padding: 0.25rem;
    }

      .pulseDatePicker table td > span {
        /*width: 2.5rem;*/
        /*height: 2.5rem;*/
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        transition: box-shadow 0.2s;
        border: 1px solid transparent;
      }

  .pulseDatePicker .p-timepicker {
    border-top: 1px solid var(--light-gray);
    /*padding: 0.5rem;*/
    padding: 0.25rem;
  }

    .pulseDatePicker .p-timepicker button {
      /*width: 2rem;*/
      /*height: 2rem;*/
      width: 1.5rem;
      height: 1.5rem;
      color: var(--text-color);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .pulseDatePicker .p-timepicker span {
      /*font-size: 1.25rem;*/
      font-size: 1rem;
    }

    .pulseDatePicker .p-timepicker > div {
      /*padding: 0 0.5rem;*/
      padding: 0 0.25rem;
    }

  .pulseDatePicker .p-monthpicker {
    /*margin: 0.5rem 0;*/
    margin: 0.25rem 0;
  }

    .pulseDatePicker .p-monthpicker .p-monthpicker-month {
      /*padding: 0.5rem;*/
      padding: 0.25rem;
      transition: box-shadow 0.2s;
      border-radius: 3px;
    }


  .pulseDatePicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid var(--light-gray);
    /*padding-right: 0.5rem;*/
    /*padding-left: 0.5rem;*/
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    padding-top: 0;
    padding-bottom: 0;
  }

/******************************************************************************************
  Input Switch for use in .pulseTable DataTable - less padding
 ******************************************************************************************/
.pulseInputSwitch .p-inputswitch {
  width: 2.125rem;
  height: .875rem;
}

  .pulseInputSwitch .p-inputswitch-slider {
    border-radius: 15px;
  }

    .pulseInputSwitch .p-inputswitch-slider:before {
      width: .625rem;
      height: .625rem;
      left: 0.125rem;
      margin-top: -0.3125rem;
    }

.pulseInputSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

/******************************************************************************************
  Checkbox for use in .pulseTable DataTable - less padding
 ******************************************************************************************/
.pulseCheckbox .p-checkbox {
  width: 1em;
  height: 1em;
  margin-bottom: .125em !important;
}

.pulseCheckbox .p-checkbox-box {
  border: 1px solid var(--primary-color-dark);
  background: var(--primary-background-color);
  width: 1em;
  height: 1em;
  /*color: var(--text-color);*/
  border-radius: 0px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

  .pulseCheckbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: var(--primary-color-text);
    font-size: .8em;
  }

/******************************************************************************************
  DataTable - extra padding
 ******************************************************************************************/
.pulseTable .p-datatable-tbody > tr > td {
  text-align: left;
/*  border: 1px solid var(--medium-gray);
*/  border-width: 0 0 1px 0;
  /* Default */
  /*padding: 1rem 1rem;*/
  /* No Clip Footer - full screen */
  padding: .55rem .55rem .55rem .55rem !important;
  font-size: 12px;
  overflow: hidden;
}

.pulseTable .p-sortable-column .p-sortable-column-icon {
  /* color: var(--primary-color-pulse); */
  margin-left: 0.5rem;
  background: transparent;
  color: #65b3bd;
  opacity: 1;
  font-size: 10px;
}

.pulseTable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: var(--text-color);
  margin-left: 0.5rem;
  background: transparent;
}

.pulseTable .p-sortable-column:not(.p-highlight):hover {
  /* background: var(--very-light-gray);
  color: var(--text-color); */
}

  .pulseTable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: var(--text-color);
  }

.pulseTable .p-sortable-column.p-highlight {
  /*background: var(--very-light-gray);*/
  /* background: transparent; */
  /*color: var(--text-color);*/
  /* color: var(--primary-color-pulse); */
  color: #028292;
  opacity: 1;
}

  .pulseTable .p-sortable-column.p-highlight .p-sortable-column-icon {
    /* background: transparent; */
    /*color: var(--text-color);*/
    /* color: var(--primary-color-pulse); */
    color: #028292;
    opacity: 1;
  }

  .pulseTable .p-sortable-column.p-highlight:hover {
    /*background: var(--very-light-gray);
    color: var(--text-color);*/
    color: var(--primary-color-pulse);
  }

    .pulseTable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
      background: transparent;
      /*color: var(--text-color);*/
      color: var(--primary-color-pulse);
    }

.pulseTable .p-sortable-column {
  outline: 0 none;
}

  .pulseTable .p-sortable-column:focus {
    background: transparent;
    box-shadow: none;
    outline: 0 none;
  }

.pulseTable .p-column-filter-menu-button {
  color: var(--text-color-dark);
}

  .pulseTable .p-column-filter-menu-button:hover {
    background: var(--primary-color-ultralight);
  }

  .pulseTable .p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: unset;
    background: transparent;
  }

    .pulseTable .p-column-filter-menu-button:focus:hover {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: unset;
      background: var(--primary-color-ultralight);
    }

/*.pulseTable .p-column-filter-menu-button {
  margin-right: .5rem;
}*/

.p-datatable .p-sortable-column:focus {
  /*box-shadow: inset 0 0 0 0.15rem var(--primary-color-ultralight);*/
  box-shadow: unset;
}

.p-datatable .p-datatable-tbody > tr {
  outline: 0 0 1px 0 var(--medium-gray);
}

.pulseTable .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: var(--primary-color-ultralight);
  /*color: var(--text-color);*/
  color: var(--primary-color-text);
}

/******************************************************************************************
  pulseTable varient - prevents paginator from wrapping
  (pulseTable when not full width of the screen - prevents paginator from wrapping)
 ******************************************************************************************/
/* .pulseTableNoWrapPaginator .pulseTable {
} */

.pulseTableNoWrapPaginator .p-paginator-top {
  flex-wrap: nowrap !important;
  flex: none !important;
  overflow: hidden;
}

  .pulseTableNoWrapPaginator .p-paginator-top .p-paginator-left-content {
    flex-wrap: nowrap !important;
    flex: none !important;
    overflow: hidden;
  }

  .pulseTableNoWrapPaginator .p-paginator-top .p-paginator-right-content {
    flex-wrap: nowrap !important;
    flex: none !important;
    overflow: hidden;
  }

.pulseTableNoWrapPaginator .p-paginator-bottom {
  flex-wrap: nowrap !important;
  flex: none !important;
  overflow: hidden;
}

  .pulseTableNoWrapPaginator .p-paginator-bottom .p-paginator-left-content {
    flex-wrap: nowrap !important;
    flex: none !important;
    overflow: hidden;
  }

  .pulseTableNoWrapPaginator .p-paginator-bottom .p-paginator-right-content {
    flex-wrap: nowrap !important;
    flex: none !important;
    overflow: hidden;
  }

/******************************************************************************************
  Table Ellipse (...)
 ******************************************************************************************/
.table-ellipse {
  width: 1.4rem !important;
  box-shadow: unset !important;
  padding: 0.95rem 0.95rem !important;
}

  .table-ellipse .p-column-filter-menu-button:hover {
    background: var(--light-gray) !important;
  }

.table-ellipse-button {
  float: right;
  width: 1.75rem;
  height: 1.75rem;
  margin: -.25rem;
  box-shadow: unset;
}

/******************************************************************************************
  Teams Table - the padding above messes up the teams table
 ******************************************************************************************/
.teamsTable .pulseTable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--medium-gray);
  border-width: 0 0 1px 0;
  padding: .95rem .95rem .95rem .95rem !important;
}

.teamsTable .p-datatable-tbody > tr:hover {
  background-color: var(--primary-color-ultralight);
}

/******************************************************************************************
  Pulse Message - left justified contents.
 ******************************************************************************************/
.pulseMessage .p-inline-message.p-component {
  justify-content: unset;
}

/******************************************************************************************
  Dialog - Padding adjustments.
 ******************************************************************************************/
.p-dialog .p-dialog-header {
  /* padding:25px; */
  padding: 18px 25px;
}

.p-dialog .p-dialog-content {
  padding:30px;
}

.p-confirm-dialog .p-dialog-content{
  padding: 10px!important;
}

.p-dialog .p-dialog-footer {
  padding-top: 0;
  padding-right: 50px;
  padding-bottom: 25px;
  padding-left: 50px;
}

  .p-dialog .p-dialog-footer button {
    margin: 0 0 0 1rem;
    width: auto;
  }

/******************************************************************************************
  Create Dialog Specific Avatar - xlarge size increased.
 ******************************************************************************************/
.pulseAvatar .p-avatar {
}

  .pulseAvatar .p-avatar.p-avatar-xl {
    width: 6rem;
    height: 6rem;
    font-size: 3rem;
  }

  /* insure a round image without masking or image manipulation */
.p-avatar img {
  border-radius: 50%;
  object-fit:cover;
}


/******************************************************************************************
  Profile Edit Specific Avatar - xlarge size increased.
 ******************************************************************************************/
.pulseProfileEditAvatar .p-avatar {
}

  .pulseProfileEditAvatar .p-avatar.p-avatar-xl {
    width: 14rem;
    height: 14rem;
    font-size: 7rem;
  }

/******************************************************************************************
  Create Specific Dropdown Style
******************************************************************************************/
.pulseContactDialogDropdown .p-dropdown {
  width: 12rem !important;
}

/* Color the background of a control when dirty or invalid */
.ng-dirty-yellow.ng-dirty.ng-invalid > .p-dropdown {
  border-color: var(--error-color-border-dark) !important;
  border: 1px solid;
  /*width: 98% !important;*/
}

/* Validation failed style for calendar controls */
.pulse-calendar-invalid input {
  border-color: var(--error-color-border-dark) !important;
  border: 1px solid;
}

/******************************************************************************************
  Invalid Inputs
******************************************************************************************/
.ng-invalid {
  /*background-color: var(--error-color-background) !important;*/
  /*border-color: var(--error-color-border-dark) !important;*/
  /*box-shadow: inset 0 0 0 1px var(--error-color-border) !important;*/
  /* Warning Colors */
  border-color: var(--error-color-border-dark) !important;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  /*border-color: var(--error-color);*/
  border-color: var(--error-color-border-dark) !important;
}

/******************************************************************************************
  Error Message Dialog
******************************************************************************************/
.pulseErrorDialog .p-dialog {
}

.pulseErrorDialog .p-dialog-header {
  background: var(--error-color-background);
  color: var(--text-color);
}

/******************************************************************************************
  Input Switch for use in page headings
 ******************************************************************************************/
.pulseInputSwitchHeading .p-inputswitch {
  /*width: 3rem;*/
  /*height: 1.75rem;*/

  /*width: 2.25rem;*/
  /*height: 1.3125rem;*/

  width: 1.8rem;
  height: 1.05rem;
}

.pulseInputSwitchHeading .p-inputswitch-slider {
  /*border-radius: 30px;*/
  /*border-radius: 22.5px;*/
  border-radius: 18px;
}

  .pulseInputSwitchHeading .p-inputswitch-slider:before {
    /*width: 1.25rem;*/
    /*height: 1.25rem;*/
    /*left: 0.25rem;*/
    /*margin-top: -0.625rem;*/
    /*width: .9375rem;*/
    /*height: .9375rem;*/
    /*left: 0.1875rem;*/
    /*margin-top: -0.4688rem;*/
    width: .75rem;
    height: .75rem;
    left: 0.15rem;
    margin-top: -0.375rem;
  }

.pulseInputSwitchHeading .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  /*transform: translateX(1.1rem);*/
  /*transform: translateX(0.825rem);*/
  transform: translateX(0.66rem);
}


/******************************************************************************************
  Spinner dialog while pages are loading data
 ******************************************************************************************/
.pulseLoadingDialog .p-dialog {
  border-radius: 3px;
  background: transparent;
  box-shadow: unset;
  border: 0 none;
  overflow: hidden !important;
}

.pulseLoadingDialog .p-dialog-content {
  background: transparent;
  /*color: var(--text-color);*/
  color: #555555;
  padding: 1rem;
  overflow: hidden !important;
}

/******************************************************************************************
  Where Clause Description Label Style
 ******************************************************************************************/
/* Looks like a PrimeNG Chip */
.wcd {
  border-radius: .75em .75em;
  padding: .25em .5em .25em .5em;
  color: var(--text-color);
  background-color: var(--light-gray);
  height: 1.5em;
}

/* Ugly dark labels per Pulse Demo screen design */
/*.wcd {
  padding: .25em;
  border-radius: 5px 5px;
  color: white;
  background-color: #808080;
  height: 1.5em;
}*/


/******************************************************************************************
  Calendar - Date Picker (smaller version)
 ******************************************************************************************/
.pulseDatePickerSm .p-datepicker {
  /*padding: 0.5rem;*/
  padding: 0.165rem;
  background: var(--primary-background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color-dark);
  border-radius: 3px;
  font-size:.6666rem;
}

  .pulseDatePickerSm .p-datepicker:not(.p-datepicker-inline) {
    background: var(--primary-background-color);
    border: 0 none;
    /*box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);*/
  }

.pulseDatePickerSm .p-datepicker-header {
  /*padding: 0.5rem;*/
  padding: 0.165rem;
  color: var(--text-color);
  background: var(--primary-background-color);
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid var(--light-gray);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

  .pulseDatePickerSm .p-datepicker-header .p-datepicker-prev,
  .pulseDatePickerSm .p-datepicker-header .p-datepicker-next {
    /*width: 2rem;*/
    /*height: 2rem;*/
    width: 1rem;
    height: 1rem;
    color: var(--text-color);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

  .pulseDatePickerSm .p-datepicker-header .p-datepicker-title {
    /*line-height: 2rem;*/
    line-height: 1rem;
  }

    .pulseDatePickerSm .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      /*margin-right: 0.5rem;*/
      margin-right: 0.165rem;
    }

.pulseDatePickerSm table {
  font-size: 0.6666rem;
  /*margin: 0.5rem 0;*/
  margin: 0.165rem 0;
}

  .pulseDatePickerSm table th {
    /*padding: 0.5rem;*/
    padding: 0.165rem;
  }

    .pulseDatePickerSm table th > span {
      /*width: 2.5rem;*/
      /*height: 2.5rem;*/
      width: 1rem;
      height: 1rem;
    }

  .pulseDatePickerSm table td {
    /*padding: 0.5rem;*/
    padding: 0.165rem;
  }

    .pulseDatePickerSm table td > span {
      /*width: 2.5rem;*/
      /*height: 2.5rem;*/
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      transition: box-shadow 0.2s;
      border: 1px solid transparent;
    }

.pulseDatePickerSm .p-timepicker {
  border-top: 1px solid var(--light-gray);
  /*padding: 0.5rem;*/
  padding: 0.165rem;
}

  .pulseDatePickerSm .p-timepicker button {
    /*width: 2rem;*/
    /*height: 2rem;*/
    width: 1rem;
    height: 1rem;
    color: var(--text-color);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

  .pulseDatePickerSm .p-timepicker span {
    /*font-size: 1.25rem;*/
    font-size: 0.6666rem;
  }

  .pulseDatePickerSm .p-timepicker > div {
    /*padding: 0 0.5rem;*/
    padding: 0 0.165rem;
  }

.pulseDatePickerSm .p-monthpicker {
  /*margin: 0.5rem 0;*/
  margin: 0.165rem 0;
}

  .pulseDatePickerSm .p-monthpicker .p-monthpicker-month {
    /*padding: 0.5rem;*/
    padding: 0.165rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
  }


.pulseDatePickerSm.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid var(--light-gray);
  /*padding-right: 0.5rem;*/
  /*padding-left: 0.5rem;*/
  padding-right: 0.165rem;
  padding-left: 0.25rem;
  padding-top: 0;
  padding-bottom: 0;
}


/******************************************************************************************
  Stripo Plugin
 ******************************************************************************************/
#externalSystemContainer {
  color: unset;
  /*background-color: darkgrey;*/
  background-color: var(--light-gray);
  padding: 5px 0 5px 20px;
}

#stripoSettingsContainer {
  width: 400px;
  float: left;
  border-left: #E5E5E5 1px solid;
  border-right: #E5E5E5 1px solid;
}

#stripoPreviewContainer {
  width: calc(100% - 400px);
  float: left;
}

.notification-zone {
  position: fixed;
  width: 400px;
  z-index: 99999;
  right: 20px;
  bottom: 80px;
}

.control-button {
  border-radius: 17px;
  padding: 5px 10px;
  border-color: grey;
  margin-right: .5rem;
}


/******************************************************************************************
  Stripo Merge Tag Selection
 ******************************************************************************************/
.mergeTagAnchor {
  text-decoration: unset;
  color: var(--link-color);
  background: transparent;
  border: transparent;
}

  .mergeTagAnchor:hover {
    text-decoration: underline;
  }

.mergeTagGroup .p-column-filter-menu-button {
  padding: 4px;
  border-radius: 7px !important;
  color: var(--link-color);
  text-decoration: unset;
  margin-left: .5rem;
  margin-right: .5rem;
}

/******************************************************************************************
  Stripo Image Library
 ******************************************************************************************/
.libraryThumbnail {
  display: inline-block;
  width: 154px;
  height: 110px;
  /*cursor: pointer;*/
  padding: 4px;
  background-color: #ffffff;
  border: 1px solid var(--primary-color-pulse);
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  line-height: 100px;
  text-align: center;
}

.libraryImage {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  min-width: 8rem;
  max-width: 8rem;
  min-height: 6rem;
  max-height: 6rem;
  object-fit: contain;
}

.libraryImageAvatar {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  min-width: 6rem;
  max-width: 6rem;
  min-height: 6rem;
  max-height: 6rem;
  object-fit: cover;
  border-radius: 50%;
}

/******************************************************************************************
  Slide Menu for Merge Tags
 ******************************************************************************************/
.mergeTagSlideMenu .p-slidemenu {
}

  /* width MUST MATCH p-slideMenu element [menuWidth] attribute*/
  .mergeTagSlideMenu .p-slidemenu.p-slidemenu-overlay {
    width: 375px !important;
  }

.mergeTagSlideMenuGroup {
  font-weight: 700;
  background-color: var(--very-light-gray) !important;
  font-size: 17px !important;
}

/******************************************************************************************
  Text Message Preview/Editor
 ******************************************************************************************/
.cellPhoneDiv {
  background-image: url('/assets/images/CellPhone.png');
  width: 265px;
  height: 506px;
  max-width: 265px;
  max-height: 506px;
  margin: 0 auto;
}

.cellPhoneText {
  resize: none;
  border: none;
  padding: 20px;
  font-size: 14px;
  width: 100%;
  height: 170px;
  margin: 0;
  font-weight: 700;
  border-color: var(--primary-color-pulse);
}

.emoji {
  width: 3rem;
  height: 3rem;
  color: var(--text-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

  margin: 0px !important;
  cursor: pointer !important;
  box-shadow: unset !important;
  font-size: 1.5rem !important;
}

  .emoji:hover {
    color: var(--text-color);
    border-color: transparent;
    background: var(--light-gray);
  }

/******************************************************************************************
  Back Button
 ******************************************************************************************/
.back-button {
  padding: 4px !important;
  background-color: transparent !important;
  color: var(--primary-color-pulse);
  border: unset !important;
  margin-left: .5em !important;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}

/* Each card in a quadrant layout */
.summary-card {
  background-color: var(--primary-background-color);
  padding: 15px;
  height: 100%;
  border-width: 1px;
  border-color: var(--light-gray);
  border-style: none outset outset none;
}

.modal-text p{
  font-size: 16px;
  font-style: normal;
}
.alert-header{
  font-size:22px;
  font-weight: bold;

}
.pi-times:before {
  height: 16px;
  width: 16px;
}

.p-dialog-header-close-icon.pi .pi-times { color:#6e11f2!important;
}

.white_hover{ color: #fff !important;}

.esdev-app .esdev-option-panel{ z-index: 0 !important;}
.esd-block-text{
  font-size: 16px;
}
a.p-button-secondary:hover{color: #6e11f2 !important;}

#gruondDropdown .ng-trigger.ng-trigger-overlayAnimation.p-dropdown-panel.p-component.ng-star-inserted {
  z-index: 1 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

/* .pulseTablescroll .p-datatable-tbody>tr>td:first-child, .pulseTablescroll .p-sortable-column:first-child{
  position: absolute;
  left: 0;
  background-color: #fff;
  top: auto;

  border-top: 1px solid var(--very-light-gray);
  width: 160px;
  height: 48px;
}
.pulseTablescroll .p-sortable-column:first-child{
  height:37px !important;
}
.pulseTablescroll .p-sortable-column:nth-child(2), .pulseTablescroll .p-datatable-tbody>tr>td:nth-child(2){
  padding-left: 180px!important;
} */

/* .pulseTable .fixed{
  position: absolute;
    left: 0;
    background-color: #fff;
    top: auto;
    border-top: 1px solid var(--very-light-gray);
    width: 160px;
    height: 48px;
} */

.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
  color: #6e11f2 !important;
  font-weight: 500;
}

/* #masterContacts .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext{
  height:35px;
  font-size: 16px;
  background-color: red;
}

#masterContacts  .p-multiselect-panel .p-multiselect-items .p-multiselect-item{
  background-color: red;
} */

/* 
#ownerID .p-multiselect .p-multiselect-trigger{
  display:none;
} */

/* for .p-multiselect token of purple color */

.filter-nav .p-multiselect.p-multiselect-chip .p-multiselect-token{
  margin-top: 1px;
  border: 1px solid #6e11f2!important;
  background: #6e11f2!important;
  color: white;
}

.filter-nav .p-multiselect .p-multiselect-label.p-placeholder{
 
  color: #797979;
}

.filter-nav .p-multiselect .p-multiselect-label .p-multiselect-token .pi-times-circle:before {
  content: "\e90b";
  font-size: 13px;
}
 .filter-nav .p-multiselect-header .p-multiselect-filter-container .p-inputtext{
  height:35px;
  font-size: 16px;
}
::ng-deep .p-overlaypanel{ z-index: 99 !important;}
.p-overlaypanel{ z-index: 99 !important;}


body > app-root > div > div.contentwapper > div.content > app-person > p-tabview {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
