.autoComplete_wrapper {
    display: inline-block;
    position: relative;
  }
  
  .autoComplete_wrapper > input {
    height: 3rem;
    width: 370px;
    margin: 0;
    padding: 0 3.2rem 0 2rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 1rem;
    text-overflow: ellipsis;
    /* color: rgba(255, 122, 122, 0.3); */
    outline: none;
    border-radius: 10rem;
    border: 0.05rem solid rgba(255, 122, 122, 0.5);
    background-image: url(./assets/images/search.svg);
    background-size: 1.4rem;
    background-position: right 1.05rem top 0.8rem;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-color: #fff;
    transition: all 0.4s ease;
    -webkit-transition: all -webkit-transform 0.4s ease;
  }
  
  .autoComplete_wrapper > input::placeholder {
    /* color: rgba(255, 122, 122, 0.5); */
    transition: all 0.3s ease;
    -webkit-transition: all -webkit-transform 0.3s ease;
  }
  
  .autoComplete_wrapper > input:hover::placeholder {
    /* color: rgba(255, 122, 122, 0.6); */
    transition: all 0.3s ease;
    -webkit-transition: all -webkit-transform 0.3s ease;
  }
  
  .autoComplete_wrapper > input:focus::placeholder {
    padding: 0.1rem 0.6rem;
    font-size: 0.95rem;
    /* color: rgba(255, 122, 122, 0.4); */
  }
  
  .autoComplete_wrapper > input:focus::selection {
    /* background-color: rgba(255, 122, 122, 0.15); */
  }
  
  .autoComplete_wrapper > input::selection {
    /* background-color: rgba(255, 122, 122, 0.15); */
  }
  
  .autoComplete_wrapper > input:hover {
    /* color: rgba(255, 122, 122, 0.8); */
    transition: all 0.3s ease;
    -webkit-transition: all -webkit-transform 0.3s ease;
  }
  
  .autoComplete_wrapper > input:focus {
    /* color: rgba(255, 122, 122, 1);
    border: 0.06rem solid rgba(255, 122, 122, 0.8); */
  }
  
  .autoComplete_wrapper > ul {
    position: absolute;
    max-height: 226px;
    overflow-y: scroll;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin: 0.5rem 0 0 0;
    padding: 0;
    z-index: 1;
    list-style: none;
    border-radius: 0.6rem;
    background-color: #fff;
    border: 1px solid rgba(33, 33, 33, 0.07);
    box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
    outline: none;
    transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
  }
  
  .autoComplete_wrapper > ul[hidden],
  .autoComplete_wrapper > ul:empty {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  
  .autoComplete_wrapper > ul > li {
    margin: 0.3rem;
    padding: 0.3rem 0.5rem;
    text-align: left;
    font-size: 1rem;
    /* color: #212121; */
    border-radius: 0.35rem;
    /* background-color: rgba(255, 255, 255, 1); */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease;
  }
  
  .autoComplete_wrapper > ul > li mark {
    background-color: transparent;
    /* color: rgba(255, 122, 122, 1); */
    font-weight: bold;
  }
  
  .autoComplete_wrapper > ul > li:hover {
    cursor: pointer;
    /* background-color: rgba(255, 122, 122, 0.15); */
  }
  
  .autoComplete_wrapper > ul > li[aria-selected="true"] {
    /* background-color: rgba(255, 122, 122, 0.15); */
  }
  
  @media only screen and (max-width: 600px) {
    .autoComplete_wrapper > input {
      width: 18rem;
    }
  }