@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons Outlined"), local("MaterialIcons-Outlined"), url("../assets/fonts/MaterialIcons-Outlined.ttf") format("truetype"), url("../assets/fonts/MaterialIcons-Outlined.woff") format("woff");
}

[class^="mio"], [class*=" mio"] {
  font-family: 'Material Icons Outlined' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 1.25em;
  vertical-align: bottom;
  line-height: 1;
  text-transform: none;
  letter-spacing: 0;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

  .mio:before {
    --webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

.mio_fw {
  width: 1.28571429em;
  text-align: center;
}

.mio_spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.mio_d_rotation:before {
  content: "\e9fd";
}

.mio_4k:before {
  content: "\e9fe";
}

.mio_360:before {
  content: "\e9ff";
}

.mio_zoom_in:before {
  content: "\ea53";
}

.mio_zoom_out:before {
  content: "\ea54";
}

.mio_zoom_out_map:before {
  content: "\ea55";
}

.mio_youtube_searched_for:before {
  content: "\ea56";
}

.mio_wallpaper:before {
  content: "\ea57";
}

.mio_warning:before {
  content: "\ea58";
}

.mio_watch:before {
  content: "\ea59";
}

.mio_watch_later:before {
  content: "\ea5a";
}

.mio_waves:before {
  content: "\ea5b";
}

.mio_wb_auto:before {
  content: "\ea5c";
}

.mio_wb_cloudy:before {
  content: "\ea5d";
}

.mio_wb_incandescent:before {
  content: "\ea5e";
}

.mio_wb_iridescent:before {
  content: "\ea5f";
}

.mio_wb_sunny:before {
  content: "\ea60";
}

.mio_wc:before {
  content: "\ea61";
}

.mio_web:before {
  content: "\ea62";
}

.mio_web_asset:before {
  content: "\ea63";
}

.mio_weekend:before {
  content: "\ea64";
}

.mio_whatshot:before {
  content: "\ea65";
}

.mio_where_to_vote:before {
  content: "\ea66";
}

.mio_widgets:before {
  content: "\ea67";
}

.mio_wifi:before {
  content: "\ea68";
}

.mio_wifi_lock:before {
  content: "\ea69";
}

.mio_wifi_off:before {
  content: "\ea6a";
}

.mio_wifi_tethering:before {
  content: "\ea6b";
}

.mio_work:before {
  content: "\ea6c";
}

.mio_work_off:before {
  content: "\ea6d";
}

.mio_work_outline:before {
  content: "\ea6e";
}

.mio_wrap_text:before {
  content: "\ea6f";
}

.mio_verified_user:before {
  content: "\ea70";
}

.mio_vertical_align_bottom:before {
  content: "\ea71";
}

.mio_vertical_align_center:before {
  content: "\ea72";
}

.mio_vertical_align_top:before {
  content: "\ea73";
}

.mio_vertical_split:before {
  content: "\ea74";
}

.mio_vibration:before {
  content: "\ea75";
}

.mio_video_call:before {
  content: "\ea76";
}

.mio_videocam:before {
  content: "\ea77";
}

.mio_videocam_off:before {
  content: "\ea78";
}

.mio_videogame_asset:before {
  content: "\ea79";
}

.mio_video_label:before {
  content: "\ea7a";
}

.mio_video_library:before {
  content: "\ea7b";
}

.mio_view_agenda:before {
  content: "\ea7c";
}

.mio_view_array:before {
  content: "\ea7d";
}

.mio_view_carousel:before {
  content: "\ea7e";
}

.mio_view_column:before {
  content: "\ea7f";
}

.mio_view_comfy:before {
  content: "\ea80";
}

.mio_view_compact:before {
  content: "\ea81";
}

.mio_view_day:before {
  content: "\ea82";
}

.mio_view_headline:before {
  content: "\ea83";
}

.mio_view_list:before {
  content: "\ea84";
}

.mio_view_module:before {
  content: "\ea85";
}

.mio_view_quilt:before {
  content: "\ea86";
}

.mio_view_stream:before {
  content: "\ea87";
}

.mio_view_week:before {
  content: "\ea88";
}

.mio_vignette:before {
  content: "\ea89";
}

.mio_visibility:before {
  content: "\ea8a";
}

.mio_visibility_off:before {
  content: "\ea8b";
}

.mio_voice_chat:before {
  content: "\ea8c";
}

.mio_voicemail:before {
  content: "\ea8d";
}

.mio_voice_over_off:before {
  content: "\ea8e";
}

.mio_volume_down:before {
  content: "\ea8f";
}

.mio_volume_mute:before {
  content: "\ea90";
}

.mio_volume_off:before {
  content: "\ea91";
}

.mio_volume_up:before {
  content: "\ea92";
}

.mio_vpn_key:before {
  content: "\ea93";
}

.mio_vpn_lock:before {
  content: "\ea94";
}

.mio_unarchive:before {
  content: "\ea95";
}

.mio_undo:before {
  content: "\ea96";
}

.mio_unfold_less:before {
  content: "\ea97";
}

.mio_unfold_more:before {
  content: "\ea98";
}

.mio_unsubscribe:before {
  content: "\ea99";
}

.mio_update:before {
  content: "\ea9a";
}

.mio_usb:before {
  content: "\ea9b";
}

.mio_tab:before {
  content: "\ea9c";
}

.mio_table_chart:before {
  content: "\ea9d";
}

.mio_tablet:before {
  content: "\ea9e";
}

.mio_tablet_android:before {
  content: "\ea9f";
}

.mio_tablet_mac:before {
  content: "\eaa0";
}

.mio_tab_unselected:before {
  content: "\eaa1";
}

.mio_tag_faces:before {
  content: "\eaa2";
}

.mio_tap_and_play:before {
  content: "\eaa3";
}

.mio_terrain:before {
  content: "\eaa4";
}

.mio_text_fields:before {
  content: "\eaa5";
}

.mio_text_format:before {
  content: "\eaa6";
}

.mio_text_rotate_up:before {
  content: "\eaa7";
}

.mio_text_rotate_vertical:before {
  content: "\eaa8";
}

.mio_text_rotation_down:before {
  content: "\eaa9";
}

.mio_text_rotation_none:before {
  content: "\eaaa";
}

.mio_textsms:before {
  content: "\eaab";
}

.mio_texture:before {
  content: "\eaac";
}

.mio_theaters:before {
  content: "\eaad";
}

.mio_thumb_down:before {
  content: "\eaae";
}

.mio_thumb_down_alt:before {
  content: "\eaaf";
}

.mio_thumbs_up_down:before {
  content: "\eab0";
}

.mio_thumb_up:before {
  content: "\eab1";
}

.mio_thumb_up_alt:before {
  content: "\eab2";
}

.mio_timelapse:before {
  content: "\eab3";
}

.mio_timeline:before {
  content: "\eab4";
}

.mio_timer:before {
  content: "\eab5";
}

.mio_timer_3:before {
  content: "\eab6";
}

.mio_timer_10:before {
  content: "\eab7";
}

.mio_timer_off:before {
  content: "\eab8";
}

.mio_time_to_leave:before {
  content: "\eab9";
}

.mio_title:before {
  content: "\eaba";
}

.mio_toc:before {
  content: "\eabb";
}

.mio_today:before {
  content: "\eabc";
}

.mio_toggle_off:before {
  content: "\eabd";
}

.mio_toggle_on:before {
  content: "\eabe";
}

.mio_toll:before {
  content: "\eabf";
}

.mio_tonality:before {
  content: "\eac0";
}

.mio_touch_app:before {
  content: "\eac1";
}

.mio_toys:before {
  content: "\eac2";
}

.mio_track_changes:before {
  content: "\eac3";
}

.mio_traffic:before {
  content: "\eac4";
}

.mio_train:before {
  content: "\eac5";
}

.mio_tram:before {
  content: "\eac6";
}

.mio_transfer_within_a_station:before {
  content: "\eac7";
}

.mio_transform:before {
  content: "\eac8";
}

.mio_transit_enterexit:before {
  content: "\eac9";
}

.mio_translate:before {
  content: "\eaca";
}

.mio_trending_down:before {
  content: "\eacb";
}

.mio_trending_flat:before {
  content: "\eacc";
}

.mio_trending_up:before {
  content: "\eacd";
}

.mio_trip_origin:before {
  content: "\eace";
}

.mio_tune:before {
  content: "\eacf";
}

.mio_turned_in:before {
  content: "\ead0";
}

.mio_turned_in_not:before {
  content: "\ead1";
}

.mio_tv:before {
  content: "\ead2";
}

.mio_tv_off:before {
  content: "\ead3";
}

.mio_switch_video:before {
  content: "\ead4";
}

.mio_sync:before {
  content: "\ead5";
}

.mio_sync_disabled:before {
  content: "\ead6";
}

.mio_sync_problem:before {
  content: "\ead7";
}

.mio_system_update:before {
  content: "\ead8";
}

.mio_streetview:before {
  content: "\ead9";
}

.mio_strikethrough_s:before {
  content: "\eada";
}

.mio_style:before {
  content: "\eadb";
}

.mio_subdirectory_arrow_left:before {
  content: "\eadc";
}

.mio_subdirectory_arrow_right:before {
  content: "\eadd";
}

.mio_subject:before {
  content: "\eade";
}

.mio_subscriptions:before {
  content: "\eadf";
}

.mio_subtitles:before {
  content: "\eae0";
}

.mio_subway:before {
  content: "\eae1";
}

.mio_supervised_user_circle:before {
  content: "\eae2";
}

.mio_supervisor_account:before {
  content: "\eae3";
}

.mio_surround_sound:before {
  content: "\eae4";
}

.mio_swap_calls:before {
  content: "\eae5";
}

.mio_swap_horiz:before {
  content: "\eae6";
}

.mio_swap_horizontal_circle:before {
  content: "\eae7";
}

.mio_swap_vert:before {
  content: "\eae8";
}

.mio_swap_vertical_circle:before {
  content: "\eae9";
}

.mio_switch_camera:before {
  content: "\eaea";
}

.mio_speaker_notes_off:before {
  content: "\eaeb";
}

.mio_speaker_phone:before {
  content: "\eaec";
}

.mio_spellcheck:before {
  content: "\eaed";
}

.mio_star:before {
  content: "\eaee";
}

.mio_star_border:before {
  content: "\eaef";
}

.mio_star_half:before {
  content: "\eaf0";
}

.mio_star_rate:before {
  content: "\eaf1";
}

.mio_stars:before {
  content: "\eaf2";
}

.mio_stay_current_landscape:before {
  content: "\eaf3";
}

.mio_stay_current_portrait:before {
  content: "\eaf4";
}

.mio_stay_primary_landscape:before {
  content: "\eaf5";
}

.mio_stay_primary_portrait:before {
  content: "\eaf6";
}

.mio_stop:before {
  content: "\eaf7";
}

.mio_stop_screen_share:before {
  content: "\eaf8";
}

.mio_storage:before {
  content: "\eaf9";
}

.mio_store:before {
  content: "\eafa";
}

.mio_store_mall_directory:before {
  content: "\eafb";
}

.mio_straighten:before {
  content: "\eafc";
}

.mio_speaker:before {
  content: "\eafd";
}

.mio_speaker_group:before {
  content: "\eafe";
}

.mio_speaker_notes:before {
  content: "\eaff";
}

.mio_sim_card:before {
  content: "\eb00";
}

.mio_skip_next:before {
  content: "\eb01";
}

.mio_skip_previous:before {
  content: "\eb02";
}

.mio_slideshow:before {
  content: "\eb03";
}

.mio_slow_motion_video:before {
  content: "\eb04";
}

.mio_smartphone:before {
  content: "\eb05";
}

.mio_smoke_free:before {
  content: "\eb06";
}

.mio_smoking_rooms:before {
  content: "\eb07";
}

.mio_sms:before {
  content: "\eb08";
}

.mio_sms_failed:before {
  content: "\eb09";
}

.mio_snooze:before {
  content: "\eb0a";
}

.mio_sort:before {
  content: "\eb0b";
}

.mio_sort_by_alpha:before {
  content: "\eb0c";
}

.mio_spa:before {
  content: "\eb0d";
}

.mio_space_bar:before {
  content: "\eb0e";
}

.mio_signal_cellular_connected_no_internet_3_bar .path1:before {
  content: "\eb0f";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_cellular_connected_no_internet_3_bar .path2:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_cellular_connected_no_internet_4_bar:before {
  content: "\eb11";
}

.mio_signal_cellular_no_sim:before {
  content: "\eb12";
}

.mio_signal_cellular_null:before {
  content: "\eb13";
}

.mio_signal_cellular_off:before {
  content: "\eb14";
}

.mio_signal_wifi_0_bar:before {
  content: "\eb15";
}

.mio_signal_wifi_1_bar .path1:before {
  content: "\eb16";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_wifi_1_bar .path2:before {
  content: "\eb17";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_wifi_1_bar_lock .path1:before {
  content: "\eb18";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_wifi_1_bar_lock .path2:before {
  content: "\eb19";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_wifi_2_bar .path1:before {
  content: "\eb1a";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_wifi_2_bar .path2:before {
  content: "\eb1b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_wifi_2_bar_lock .path1:before {
  content: "\eb1c";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_wifi_2_bar_lock .path2:before {
  content: "\eb1d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_wifi_3_bar .path1:before {
  content: "\eb1e";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_wifi_3_bar .path2:before {
  content: "\eb1f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_wifi_3_bar_lock .path1:before {
  content: "\eb20";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_wifi_3_bar_lock .path2:before {
  content: "\eb21";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_wifi_4_bar:before {
  content: "\eb22";
}

.mio_signal_wifi_4_bar_lock:before {
  content: "\eb23";
}

.mio_signal_wifi_off:before {
  content: "\eb24";
}

.mio_shopping_cart:before {
  content: "\eb25";
}

.mio_shop_two:before {
  content: "\eb26";
}

.mio_short_text:before {
  content: "\eb27";
}

.mio_show_chart:before {
  content: "\eb28";
}

.mio_shuffle:before {
  content: "\eb29";
}

.mio_shutter_speed:before {
  content: "\eb2a";
}

.mio_signal_cellular_0_bar:before {
  content: "\eb2b";
}

.mio_signal_cellular_1_bar .path1:before {
  content: "\eb2c";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_cellular_1_bar .path2:before {
  content: "\eb2d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_cellular_2_bar .path1:before {
  content: "\eb2e";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_cellular_2_bar .path2:before {
  content: "\eb2f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_cellular_3_bar .path1:before {
  content: "\eb30";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_cellular_3_bar .path2:before {
  content: "\eb31";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_cellular_4_bar:before {
  content: "\eb32";
}

.mio_signal_cellular_alt:before {
  content: "\eb33";
}

.mio_signal_cellular_connected_no_internet_0_bar .path1:before {
  content: "\eb34";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_cellular_connected_no_internet_0_bar .path2:before {
  content: "\eb35";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_cellular_connected_no_internet_1_bar .path1:before {
  content: "\eb36";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_cellular_connected_no_internet_1_bar .path2:before {
  content: "\eb37";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_signal_cellular_connected_no_internet_2_bar .path1:before {
  content: "\eb38";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_signal_cellular_connected_no_internet_2_bar .path2:before {
  content: "\eb39";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_settings_ethernet:before {
  content: "\eb3a";
}

.mio_settings_input_antenna:before {
  content: "\eb3b";
}

.mio_settings_input_component:before {
  content: "\eb3c";
}

.mio_settings_input_composite:before {
  content: "\eb3d";
}

.mio_settings_input_hdmi:before {
  content: "\eb3e";
}

.mio_settings_input_svideo:before {
  content: "\eb3f";
}

.mio_settings_overscan:before {
  content: "\eb40";
}

.mio_settings_phone:before {
  content: "\eb41";
}

.mio_settings_power:before {
  content: "\eb42";
}

.mio_settings_remote:before {
  content: "\eb43";
}

.mio_settings_system_daydream:before {
  content: "\eb44";
}

.mio_settings_voice:before {
  content: "\eb45";
}

.mio_share:before {
  content: "\eb46";
}

.mio_shop:before {
  content: "\eb47";
}

.mio_shopping_basket:before {
  content: "\eb48";
}

.mio_search:before {
  content: "\eb49";
}

.mio_security:before {
  content: "\eb4a";
}

.mio_select_all:before {
  content: "\eb4b";
}

.mio_send:before {
  content: "\eb4c";
}

.mio_sentiment_dissatisfied:before {
  content: "\eb4d";
}

.mio_sentiment_satisfied:before {
  content: "\eb4e";
}

.mio_sentiment_satisfied_alt:before {
  content: "\eb4f";
}

.mio_sentiment_very_dissatisfied:before {
  content: "\eb50";
}

.mio_sentiment_very_satisfied:before {
  content: "\eb51";
}

.mio_settings:before {
  content: "\eb52";
}

.mio_settings_applications:before {
  content: "\eb53";
}

.mio_settings_backup_restore:before {
  content: "\eb54";
}

.mio_settings_bluetooth:before {
  content: "\eb55";
}

.mio_settings_brightness:before {
  content: "\eb56";
}

.mio_settings_cell:before {
  content: "\eb57";
}

.mio_satellite:before {
  content: "\eb58";
}

.mio_save:before {
  content: "\eb59";
}

.mio_save_alt:before {
  content: "\eb5a";
}

.mio_scanner:before {
  content: "\eb5b";
}

.mio_scatter_plot:before {
  content: "\eb5c";
}

.mio_schedule:before {
  content: "\eb5d";
}

.mio_school:before {
  content: "\eb5e";
}

.mio_score:before {
  content: "\eb5f";
}

.mio_screen_lock_landscape:before {
  content: "\eb60";
}

.mio_screen_lock_portrait:before {
  content: "\eb61";
}

.mio_screen_lock_rotation:before {
  content: "\eb62";
}

.mio_screen_rotation:before {
  content: "\eb63";
}

.mio_screen_share:before {
  content: "\eb64";
}

.mio_sd_card:before {
  content: "\eb65";
}

.mio_sd_storage:before {
  content: "\eb66";
}

.mio_radio:before {
  content: "\eb67";
}

.mio_radio_button_checked:before {
  content: "\eb68";
}

.mio_radio_button_unchecked:before {
  content: "\eb69";
}

.mio_rate_review:before {
  content: "\eb6a";
}

.mio_receipt:before {
  content: "\eb6b";
}

.mio_recent_actors:before {
  content: "\eb6c";
}

.mio_record_voice_over:before {
  content: "\eb6d";
}

.mio_redeem:before {
  content: "\eb6e";
}

.mio_redo:before {
  content: "\eb6f";
}

.mio_refresh:before {
  content: "\eb70";
}

.mio_remove:before {
  content: "\eb71";
}

.mio_remove_circle:before {
  content: "\eb72";
}

.mio_remove_circle_outline:before {
  content: "\eb73";
}

.mio_remove_from_queue:before {
  content: "\eb74";
}

.mio_remove_red_eye:before {
  content: "\eb75";
}

.mio_remove_shopping_cart:before {
  content: "\eb76";
}

.mio_reorder:before {
  content: "\eb77";
}

.mio_repeat:before {
  content: "\eb78";
}

.mio_repeat_one:before {
  content: "\eb79";
}

.mio_replay:before {
  content: "\eb7a";
}

.mio_replay_5:before {
  content: "\eb7b";
}

.mio_replay_10:before {
  content: "\eb7c";
}

.mio_replay_30:before {
  content: "\eb7d";
}

.mio_reply:before {
  content: "\eb7e";
}

.mio_reply_all:before {
  content: "\eb7f";
}

.mio_report:before {
  content: "\eb80";
}

.mio_report_off:before {
  content: "\eb81";
}

.mio_report_problem:before {
  content: "\eb82";
}

.mio_restaurant:before {
  content: "\eb83";
}

.mio_restaurant_menu:before {
  content: "\eb84";
}

.mio_restore:before {
  content: "\eb85";
}

.mio_restore_from_trash:before {
  content: "\eb86";
}

.mio_restore_page:before {
  content: "\eb87";
}

.mio_ring_volume:before {
  content: "\eb88";
}

.mio_room:before {
  content: "\eb89";
}

.mio_room_service:before {
  content: "\eb8a";
}

.mio_rotate_90_degrees_ccw:before {
  content: "\eb8b";
}

.mio_rotate_left:before {
  content: "\eb8c";
}

.mio_rotate_right:before {
  content: "\eb8d";
}

.mio_rounded_corner:before {
  content: "\eb8e";
}

.mio_router:before {
  content: "\eb8f";
}

.mio_rowing:before {
  content: "\eb90";
}

.mio_rss_feed:before {
  content: "\eb91";
}

.mio_rv_hookup:before {
  content: "\eb92";
}

.mio_query_builder:before {
  content: "\eb93";
}

.mio_question_answer:before {
  content: "\eb94";
}

.mio_queue:before {
  content: "\eb95";
}

.mio_queue_music:before {
  content: "\eb96";
}

.mio_queue_play_next:before {
  content: "\eb97";
}

.mio_pages:before {
  content: "\eb98";
}

.mio_pageview:before {
  content: "\eb99";
}

.mio_palette:before {
  content: "\eb9a";
}

.mio_panorama:before {
  content: "\eb9b";
}

.mio_panorama_fish_eye:before {
  content: "\eb9c";
}

.mio_panorama_horizontal:before {
  content: "\eb9d";
}

.mio_panorama_vertical:before {
  content: "\eb9e";
}

.mio_panorama_wide_angle:before {
  content: "\eb9f";
}

.mio_pan_tool:before {
  content: "\eba0";
}

.mio_party_mode:before {
  content: "\eba1";
}

.mio_pause:before {
  content: "\eba2";
}

.mio_pause_circle_filled:before {
  content: "\eba3";
}

.mio_pause_circle_outline:before {
  content: "\eba4";
}

.mio_pause_presentation:before {
  content: "\eba5";
}

.mio_payment:before {
  content: "\eba6";
}

.mio_people:before {
  content: "\eba7";
}

.mio_people_outline:before {
  content: "\eba8";
}

.mio_perm_camera_mic:before {
  content: "\eba9";
}

.mio_perm_contact_calendar:before {
  content: "\ebaa";
}

.mio_perm_data_setting:before {
  content: "\ebab";
}

.mio_perm_device_information:before {
  content: "\ebac";
}

.mio_perm_identity:before {
  content: "\ebad";
}

.mio_perm_media:before {
  content: "\ebae";
}

.mio_perm_phone_msg:before {
  content: "\ebaf";
}

.mio_perm_scan_wifi:before {
  content: "\ebb0";
}

.mio_person:before {
  content: "\ebb1";
}

.mio_person_add:before {
  content: "\ebb2";
}

.mio_person_add_disabled:before {
  content: "\ebb3";
}

.mio_personal_video:before {
  content: "\ebb4";
}

.mio_person_outline:before {
  content: "\ebb5";
}

.mio_person_pin:before {
  content: "\ebb6";
}

.mio_person_pin_circle:before {
  content: "\ebb7";
}

.mio_pets:before {
  content: "\ebb8";
}

.mio_phone:before {
  content: "\ebb9";
}

.mio_phone_android:before {
  content: "\ebba";
}

.mio_phone_bluetooth_speaker:before {
  content: "\ebbb";
}

.mio_phone_callback:before {
  content: "\ebbc";
}

.mio_phone_forwarded:before {
  content: "\ebbd";
}

.mio_phone_in_talk:before {
  content: "\ebbe";
}

.mio_phone_iphone:before {
  content: "\ebbf";
}

.mio_phonelink:before {
  content: "\ebc0";
}

.mio_phonelink_erase:before {
  content: "\ebc1";
}

.mio_phonelink_lock:before {
  content: "\ebc2";
}

.mio_phonelink_off:before {
  content: "\ebc3";
}

.mio_phonelink_ring:before {
  content: "\ebc4";
}

.mio_phonelink_setup:before {
  content: "\ebc5";
}

.mio_phone_locked:before {
  content: "\ebc6";
}

.mio_phone_missed:before {
  content: "\ebc7";
}

.mio_phone_paused:before {
  content: "\ebc8";
}

.mio_photo:before {
  content: "\ebc9";
}

.mio_photo_album:before {
  content: "\ebca";
}

.mio_photo_camera:before {
  content: "\ebcb";
}

.mio_photo_filter:before {
  content: "\ebcc";
}

.mio_photo_library:before {
  content: "\ebcd";
}

.mio_photo_size_select_actual:before {
  content: "\ebce";
}

.mio_photo_size_select_large:before {
  content: "\ebcf";
}

.mio_photo_size_select_small:before {
  content: "\ebd0";
}

.mio_picture_as_pdf:before {
  content: "\ebd1";
}

.mio_picture_in_picture:before {
  content: "\ebd2";
}

.mio_picture_in_picture_alt:before {
  content: "\ebd3";
}

.mio_pie_chart:before {
  content: "\ebd4";
}

.mio_pin_drop:before {
  content: "\ebd5";
}

.mio_place:before {
  content: "\ebd6";
}

.mio_play_arrow:before {
  content: "\ebd7";
}

.mio_play_circle_filled:before {
  content: "\ebd8";
}

.mio_play_circle_filled_white:before {
  content: "\ebd9";
}

.mio_play_circle_outline:before {
  content: "\ebda";
}

.mio_play_for_work:before {
  content: "\ebdb";
}

.mio_playlist_add:before {
  content: "\ebdc";
}

.mio_playlist_add_check:before {
  content: "\ebdd";
}

.mio_playlist_play:before {
  content: "\ebde";
}

.mio_plus_one:before {
  content: "\ebdf";
}

.mio_poll:before {
  content: "\ebe0";
}

.mio_polymer:before {
  content: "\ebe1";
}

.mio_pool:before {
  content: "\ebe2";
}

.mio_portable_wifi_off:before {
  content: "\ebe3";
}

.mio_portrait:before {
  content: "\ebe4";
}

.mio_power:before {
  content: "\ebe5";
}

.mio_power_input:before {
  content: "\ebe6";
}

.mio_power_off:before {
  content: "\ebe7";
}

.mio_power_settings_new:before {
  content: "\ebe8";
}

.mio_pregnant_woman:before {
  content: "\ebe9";
}

.mio_present_to_all:before {
  content: "\ebea";
}

.mio_print:before {
  content: "\ebeb";
}

.mio_print_disabled:before {
  content: "\ebec";
}

.mio_priority_high:before {
  content: "\ebed";
}

.mio_public:before {
  content: "\ebee";
}

.mio_publish:before {
  content: "\ebef";
}

.mio_offline_bolt:before {
  content: "\ebf0";
}

.mio_offline_pin:before {
  content: "\ebf1";
}

.mio_ondemand_video:before {
  content: "\ebf2";
}

.mio_opacity:before {
  content: "\ebf3";
}

.mio_open_in_browser:before {
  content: "\ebf4";
}

.mio_open_in_new:before {
  content: "\ebf5";
}

.mio_open_with:before {
  content: "\ebf6";
}

.mio_outlined_flag:before {
  content: "\ebf7";
}

.mio_nature:before {
  content: "\ebf8";
}

.mio_nature_people:before {
  content: "\ebf9";
}

.mio_navigate_before:before {
  content: "\ebfa";
}

.mio_navigate_next:before {
  content: "\ebfb";
}

.mio_navigation:before {
  content: "\ebfc";
}

.mio_near_me:before {
  content: "\ebfd";
}

.mio_network_cell .path1:before {
  content: "\ebfe";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_network_cell .path2:before {
  content: "\ebff";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_network_check:before {
  content: "\ec00";
}

.mio_network_locked:before {
  content: "\ec01";
}

.mio_network_wifi .path1:before {
  content: "\ec02";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_network_wifi .path2:before {
  content: "\ec03";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_new_releases:before {
  content: "\ec04";
}

.mio_next_week:before {
  content: "\ec05";
}

.mio_nfc:before {
  content: "\ec06";
}

.mio_no_encryption:before {
  content: "\ec07";
}

.mio_no_meeting_room:before {
  content: "\ec08";
}

.mio_no_sim:before {
  content: "\ec09";
}

.mio_note:before {
  content: "\ec0a";
}

.mio_note_add:before {
  content: "\ec0b";
}

.mio_notes:before {
  content: "\ec0c";
}

.mio_notification_important:before {
  content: "\ec0d";
}

.mio_notifications:before {
  content: "\ec0e";
}

.mio_notifications_active:before {
  content: "\ec0f";
}

.mio_notifications_none:before {
  content: "\ec10";
}

.mio_notifications_off:before {
  content: "\ec11";
}

.mio_notifications_paused:before {
  content: "\ec12";
}

.mio_not_interested:before {
  content: "\ec13";
}

.mio_not_listed_location:before {
  content: "\ec14";
}

.mio_mail:before {
  content: "\ec15";
}

.mio_mail_outline:before {
  content: "\ec16";
}

.mio_map:before {
  content: "\ec17";
}

.mio_markunread:before {
  content: "\ec18";
}

.mio_markunread_mailbox:before {
  content: "\ec19";
}

.mio_maximize:before {
  content: "\ec1a";
}

.mio_meeting_room:before {
  content: "\ec1b";
}

.mio_memory:before {
  content: "\ec1c";
}

.mio_menu:before {
  content: "\ec1d";
}

.mio_merge_type:before {
  content: "\ec1e";
}

.mio_message:before {
  content: "\ec1f";
}

.mio_mic:before {
  content: "\ec20";
}

.mio_mic_none:before {
  content: "\ec21";
}

.mio_mic_off:before {
  content: "\ec22";
}

.mio_minimize:before {
  content: "\ec23";
}

.mio_missed_video_call:before {
  content: "\ec24";
}

.mio_mms:before {
  content: "\ec25";
}

.mio_mobile_friendly:before {
  content: "\ec26";
}

.mio_mobile_off:before {
  content: "\ec27";
}

.mio_mobile_screen_share:before {
  content: "\ec28";
}

.mio_mode_comment:before {
  content: "\ec29";
}

.mio_monetization_on:before {
  content: "\ec2a";
}

.mio_money:before {
  content: "\ec2b";
}

.mio_money_off:before {
  content: "\ec2c";
}

.mio_monochrome_photos:before {
  content: "\ec2d";
}

.mio_mood:before {
  content: "\ec2e";
}

.mio_mood_bad:before {
  content: "\ec2f";
}

.mio_more:before {
  content: "\ec30";
}

.mio_more_horiz:before {
  content: "\ec31";
}

.mio_more_vert:before {
  content: "\ec32";
}

.mio_motorcycle:before {
  content: "\ec33";
}

.mio_mouse:before {
  content: "\ec34";
}

.mio_move_to_inbox:before {
  content: "\ec35";
}

.mio_movie:before {
  content: "\ec36";
}

.mio_movie_creation:before {
  content: "\ec37";
}

.mio_movie_filter:before {
  content: "\ec38";
}

.mio_multiline_chart:before {
  content: "\ec39";
}

.mio_music_note:before {
  content: "\ec3a";
}

.mio_music_off:before {
  content: "\ec3b";
}

.mio_music_video:before {
  content: "\ec3c";
}

.mio_my_location:before {
  content: "\ec3d";
}

.mio_label:before {
  content: "\ec3e";
}

.mio_label_important:before {
  content: "\ec3f";
}

.mio_label_off:before {
  content: "\ec40";
}

.mio_landscape:before {
  content: "\ec41";
}

.mio_language:before {
  content: "\ec42";
}

.mio_laptop:before {
  content: "\ec43";
}

.mio_laptop_chromebook:before {
  content: "\ec44";
}

.mio_laptop_mac:before {
  content: "\ec45";
}

.mio_laptop_windows:before {
  content: "\ec46";
}

.mio_last_page:before {
  content: "\ec47";
}

.mio_launch:before {
  content: "\ec48";
}

.mio_layers:before {
  content: "\ec49";
}

.mio_layers_clear:before {
  content: "\ec4a";
}

.mio_leak_add:before {
  content: "\ec4b";
}

.mio_leak_remove:before {
  content: "\ec4c";
}

.mio_lens:before {
  content: "\ec4d";
}

.mio_library_add:before {
  content: "\ec4e";
}

.mio_library_books:before {
  content: "\ec4f";
}

.mio_library_music:before {
  content: "\ec50";
}

.mio_linear_scale:before {
  content: "\ec51";
}

.mio_line_style:before {
  content: "\ec52";
}

.mio_line_weight:before {
  content: "\ec53";
}

.mio_link:before {
  content: "\ec54";
}

.mio_linked_camera:before {
  content: "\ec55";
}

.mio_link_off:before {
  content: "\ec56";
}

.mio_list:before {
  content: "\ec57";
}

.mio_list_alt:before {
  content: "\ec58";
}

.mio_live_help:before {
  content: "\ec59";
}

.mio_live_tv:before {
  content: "\ec5a";
}

.mio_local_activity:before {
  content: "\ec5b";
}

.mio_local_airport:before {
  content: "\ec5c";
}

.mio_local_atm:before {
  content: "\ec5d";
}

.mio_local_bar:before {
  content: "\ec5e";
}

.mio_local_cafe:before {
  content: "\ec5f";
}

.mio_local_car_wash:before {
  content: "\ec60";
}

.mio_local_convenience_store:before {
  content: "\ec61";
}

.mio_local_dining:before {
  content: "\ec62";
}

.mio_local_drink:before {
  content: "\ec63";
}

.mio_local_florist:before {
  content: "\ec64";
}

.mio_local_gas_station:before {
  content: "\ec65";
}

.mio_local_grocery_store:before {
  content: "\ec66";
}

.mio_local_hospital:before {
  content: "\ec67";
}

.mio_local_hotel:before {
  content: "\ec68";
}

.mio_local_laundry_service:before {
  content: "\ec69";
}

.mio_local_library:before {
  content: "\ec6a";
}

.mio_local_mall:before {
  content: "\ec6b";
}

.mio_local_movies:before {
  content: "\ec6c";
}

.mio_local_offer:before {
  content: "\ec6d";
}

.mio_local_parking:before {
  content: "\ec6e";
}

.mio_local_pharmacy:before {
  content: "\ec6f";
}

.mio_local_phone:before {
  content: "\ec70";
}

.mio_local_pizza:before {
  content: "\ec71";
}

.mio_local_play:before {
  content: "\ec72";
}

.mio_local_post_office:before {
  content: "\ec73";
}

.mio_local_printshop:before {
  content: "\ec74";
}

.mio_local_see:before {
  content: "\ec75";
}

.mio_local_shipping:before {
  content: "\ec76";
}

.mio_local_taxi:before {
  content: "\ec77";
}

.mio_location_city:before {
  content: "\ec78";
}

.mio_location_disabled:before {
  content: "\ec79";
}

.mio_location_off:before {
  content: "\ec7a";
}

.mio_location_on:before {
  content: "\ec7b";
}

.mio_location_searching:before {
  content: "\ec7c";
}

.mio_lock:before {
  content: "\ec7d";
}

.mio_lock_open:before {
  content: "\ec7e";
}

.mio_looks:before {
  content: "\ec7f";
}

.mio_looks_3:before {
  content: "\ec80";
}

.mio_looks_4:before {
  content: "\ec81";
}

.mio_looks_5:before {
  content: "\ec82";
}

.mio_looks_6:before {
  content: "\ec83";
}

.mio_looks_one:before {
  content: "\ec84";
}

.mio_looks_two:before {
  content: "\ec85";
}

.mio_loop:before {
  content: "\ec86";
}

.mio_loupe:before {
  content: "\ec87";
}

.mio_low_priority:before {
  content: "\ec88";
}

.mio_loyalty:before {
  content: "\ec89";
}

.mio_keyboard:before {
  content: "\ec8a";
}

.mio_keyboard_arrow_down:before {
  content: "\ec8b";
}

.mio_keyboard_arrow_left:before {
  content: "\ec8c";
}

.mio_keyboard_arrow_right:before {
  content: "\ec8d";
}

.mio_keyboard_arrow_up:before {
  content: "\ec8e";
}

.mio_keyboard_backspace:before {
  content: "\ec8f";
}

.mio_keyboard_capslock:before {
  content: "\ec90";
}

.mio_keyboard_hide:before {
  content: "\ec91";
}

.mio_keyboard_return:before {
  content: "\ec92";
}

.mio_keyboard_tab:before {
  content: "\ec93";
}

.mio_keyboard_voice:before {
  content: "\ec94";
}

.mio_kitchen:before {
  content: "\ec95";
}

.mio_image:before {
  content: "\ec96";
}

.mio_image_aspect_ratio:before {
  content: "\ec97";
}

.mio_image_search:before {
  content: "\ec98";
}

.mio_important_devices:before {
  content: "\ec99";
}

.mio_import_contacts:before {
  content: "\ec9a";
}

.mio_import_export:before {
  content: "\ec9b";
}

.mio_inbox:before {
  content: "\ec9c";
}

.mio_indeterminate_check_box:before {
  content: "\ec9d";
}

.mio_info:before {
  content: "\ec9e";
}

.mio_input:before {
  content: "\ec9f";
}

.mio_insert_chart:before {
  content: "\eca0";
}

.mio_insert_chart_outlined:before {
  content: "\eca1";
}

.mio_insert_comment:before {
  content: "\eca2";
}

.mio_insert_drive_file:before {
  content: "\eca3";
}

.mio_insert_emoticon:before {
  content: "\eca4";
}

.mio_insert_invitation:before {
  content: "\eca5";
}

.mio_insert_link:before {
  content: "\eca6";
}

.mio_insert_photo:before {
  content: "\eca7";
}

.mio_invert_colors:before {
  content: "\eca8";
}

.mio_invert_colors_off:before {
  content: "\eca9";
}

.mio_iso:before {
  content: "\ecaa";
}

.mio_hd:before {
  content: "\ecab";
}

.mio_hdr_off:before {
  content: "\ecac";
}

.mio_hdr_on:before {
  content: "\ecad";
}

.mio_hdr_strong:before {
  content: "\ecae";
}

.mio_hdr_weak:before {
  content: "\ecaf";
}

.mio_headset:before {
  content: "\ecb0";
}

.mio_headset_mic:before {
  content: "\ecb1";
}

.mio_healing:before {
  content: "\ecb2";
}

.mio_hearing:before {
  content: "\ecb3";
}

.mio_help:before {
  content: "\ecb4";
}

.mio_help_outline:before {
  content: "\ecb5";
}

.mio_highlight:before {
  content: "\ecb6";
}

.mio_highlight_off:before {
  content: "\ecb7";
}

.mio_high_quality:before {
  content: "\ecb8";
}

.mio_history:before {
  content: "\ecb9";
}

.mio_home:before {
  content: "\ecba";
}

.mio_horizontal_split:before {
  content: "\ecbb";
}

.mio_hotel:before {
  content: "\ecbc";
}

.mio_hot_tub:before {
  content: "\ecbd";
}

.mio_hourglass_empty:before {
  content: "\ecbe";
}

.mio_hourglass_full:before {
  content: "\ecbf";
}

.mio_how_to_reg:before {
  content: "\ecc0";
}

.mio_how_to_vote:before {
  content: "\ecc1";
}

.mio_http:before {
  content: "\ecc2";
}

.mio_https:before {
  content: "\ecc3";
}

.mio_gamepad:before {
  content: "\ecc4";
}

.mio_games:before {
  content: "\ecc5";
}

.mio_gavel:before {
  content: "\ecc6";
}

.mio_gesture:before {
  content: "\ecc7";
}

.mio_get_app:before {
  content: "\ecc8";
}

.mio_gif:before {
  content: "\ecc9";
}

.mio_golf_course:before {
  content: "\ecca";
}

.mio_gps_fixed:before {
  content: "\eccb";
}

.mio_gps_not_fixed:before {
  content: "\eccc";
}

.mio_gps_off:before {
  content: "\eccd";
}

.mio_grade:before {
  content: "\ecce";
}

.mio_gradient:before {
  content: "\eccf";
}

.mio_grain:before {
  content: "\ecd0";
}

.mio_graphic_eq:before {
  content: "\ecd1";
}

.mio_grid_off:before {
  content: "\ecd2";
}

.mio_grid_on:before {
  content: "\ecd3";
}

.mio_group:before {
  content: "\ecd4";
}

.mio_group_add:before {
  content: "\ecd5";
}

.mio_group_work:before {
  content: "\ecd6";
}

.mio_g_translate:before {
  content: "\ecd7";
}

.mio_face:before {
  content: "\ecd8";
}

.mio_fastfood:before {
  content: "\ecd9";
}

.mio_fast_forward:before {
  content: "\ecda";
}

.mio_fast_rewind:before {
  content: "\ecdb";
}

.mio_favorite:before {
  content: "\ecdc";
}

.mio_favorite_border:before {
  content: "\ecdd";
}

.mio_featured_play_list:before {
  content: "\ecde";
}

.mio_featured_video:before {
  content: "\ecdf";
}

.mio_feedback:before {
  content: "\ece0";
}

.mio_fiber_dvr:before {
  content: "\ece1";
}

.mio_fiber_manual_record:before {
  content: "\ece2";
}

.mio_fiber_new:before {
  content: "\ece3";
}

.mio_fiber_pin:before {
  content: "\ece4";
}

.mio_fiber_smart_record:before {
  content: "\ece5";
}

.mio_file_copy:before {
  content: "\ece6";
}

.mio_filter:before {
  content: "\ece7";
}

.mio_filter_1:before {
  content: "\ece8";
}

.mio_filter_2:before {
  content: "\ece9";
}

.mio_filter_3:before {
  content: "\ecea";
}

.mio_filter_4:before {
  content: "\eceb";
}

.mio_filter_5:before {
  content: "\ecec";
}

.mio_filter_6:before {
  content: "\eced";
}

.mio_filter_7:before {
  content: "\ecee";
}

.mio_filter_8:before {
  content: "\ecef";
}

.mio_filter_9:before {
  content: "\ecf0";
}

.mio_filter_9_plus:before {
  content: "\ecf1";
}

.mio_filter_b_and_w:before {
  content: "\ecf2";
}

.mio_filter_center_focus:before {
  content: "\ecf3";
}

.mio_filter_drama:before {
  content: "\ecf4";
}

.mio_filter_frames:before {
  content: "\ecf5";
}

.mio_filter_hdr:before {
  content: "\ecf6";
}

.mio_filter_list:before {
  content: "\ecf7";
}

.mio_filter_none:before {
  content: "\ecf8";
}

.mio_filter_tilt_shift:before {
  content: "\ecf9";
}

.mio_filter_vintage:before {
  content: "\ecfa";
}

.mio_find_in_page:before {
  content: "\ecfb";
}

.mio_find_replace:before {
  content: "\ecfc";
}

.mio_fingerprint:before {
  content: "\ecfd";
}

.mio_first_page:before {
  content: "\ecfe";
}

.mio_fitness_center:before {
  content: "\ecff";
}

.mio_flag:before {
  content: "\ed00";
}

.mio_flare:before {
  content: "\ed01";
}

.mio_flash_auto:before {
  content: "\ed02";
}

.mio_flash_off:before {
  content: "\ed03";
}

.mio_flash_on:before {
  content: "\ed04";
}

.mio_flight:before {
  content: "\ed05";
}

.mio_flight_land:before {
  content: "\ed06";
}

.mio_flight_takeoff:before {
  content: "\ed07";
}

.mio_flip:before {
  content: "\ed08";
}

.mio_flip_to_back:before {
  content: "\ed09";
}

.mio_flip_to_front:before {
  content: "\ed0a";
}

.mio_folder:before {
  content: "\ed0b";
}

.mio_folder_open:before {
  content: "\ed0c";
}

.mio_folder_shared:before {
  content: "\ed0d";
}

.mio_folder_special:before {
  content: "\ed0e";
}

.mio_font_download:before {
  content: "\ed0f";
}

.mio_format_align_center:before {
  content: "\ed10";
}

.mio_format_align_justify:before {
  content: "\ed11";
}

.mio_format_align_left:before {
  content: "\ed12";
}

.mio_format_align_right:before {
  content: "\ed13";
}

.mio_format_bold:before {
  content: "\ed14";
}

.mio_format_clear:before {
  content: "\ed15";
}

.mio_format_color_fill .path1:before {
  content: "\ed16";
  color: rgb(0, 0, 0);
}

.mio_format_color_fill .path2:before {
  content: "\ed17";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.36;
}

.mio_format_color_reset:before {
  content: "\ed18";
}

.mio_format_color_text .path1:before {
  content: "\ed19";
  color: rgb(0, 0, 0);
  opacity: 0.36;
}

.mio_format_color_text .path2:before {
  content: "\ed1a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_format_indent_decrease:before {
  content: "\ed1b";
}

.mio_format_indent_increase:before {
  content: "\ed1c";
}

.mio_format_italic:before {
  content: "\ed1d";
}

.mio_format_line_spacing:before {
  content: "\ed1e";
}

.mio_format_list_bulleted:before {
  content: "\ed1f";
}

.mio_format_list_numbered:before {
  content: "\ed20";
}

.mio_format_list_numbered_rtl:before {
  content: "\ed21";
}

.mio_format_paint:before {
  content: "\ed22";
}

.mio_format_quote:before {
  content: "\ed23";
}

.mio_format_shapes:before {
  content: "\ed24";
}

.mio_format_size:before {
  content: "\ed25";
}

.mio_format_strikethrough:before {
  content: "\ed26";
}

.mio_format_textdirection_l_to_r:before {
  content: "\ed27";
}

.mio_format_textdirection_r_to_l:before {
  content: "\ed28";
}

.mio_format_underlined:before {
  content: "\ed29";
}

.mio_forum:before {
  content: "\ed2a";
}

.mio_forward:before {
  content: "\ed2b";
}

.mio_forward_5:before {
  content: "\ed2c";
}

.mio_forward_10:before {
  content: "\ed2d";
}

.mio_forward_30:before {
  content: "\ed2e";
}

.mio_free_breakfast:before {
  content: "\ed2f";
}

.mio_fullscreen:before {
  content: "\ed30";
}

.mio_fullscreen_exit:before {
  content: "\ed31";
}

.mio_functions:before {
  content: "\ed32";
}

.mio_edit:before {
  content: "\e900";
}

.mio_edit_attributes:before {
  content: "\e901";
}

.mio_edit_location:before {
  content: "\e902";
}

.mio_eject:before {
  content: "\e903";
}

.mio_email:before {
  content: "\e904";
}

.mio_enhanced_encryption:before {
  content: "\e905";
}

.mio_equalizer:before {
  content: "\e906";
}

.mio_error:before {
  content: "\e907";
}

.mio_error_outline:before {
  content: "\e908";
}

.mio_euro_symbol:before {
  content: "\e909";
}

.mio_event:before {
  content: "\e90a";
}

.mio_event_available:before {
  content: "\e90b";
}

.mio_event_busy:before {
  content: "\e90c";
}

.mio_event_note:before {
  content: "\e90d";
}

.mio_event_seat:before {
  content: "\e90e";
}

.mio_ev_station:before {
  content: "\e90f";
}

.mio_exit_to_app:before {
  content: "\e910";
}

.mio_expand_less:before {
  content: "\e911";
}

.mio_expand_more:before {
  content: "\e912";
}

.mio_explicit:before {
  content: "\e913";
}

.mio_explore:before {
  content: "\e914";
}

.mio_explore_off:before {
  content: "\e915";
}

.mio_exposure:before {
  content: "\e916";
}

.mio_exposure_neg_1:before {
  content: "\e917";
}

.mio_exposure_neg_2:before {
  content: "\e918";
}

.mio_exposure_plus_1:before {
  content: "\e919";
}

.mio_exposure_plus_2:before {
  content: "\e91a";
}

.mio_exposure_zero:before {
  content: "\e91b";
}

.mio_extension:before {
  content: "\e91c";
}

.mio_dashboard:before {
  content: "\e91d";
}

.mio_data_usage:before {
  content: "\e91e";
}

.mio_date_range:before {
  content: "\e91f";
}

.mio_dehaze:before {
  content: "\e920";
}

.mio_delete:before {
  content: "\e921";
}

.mio_delete_forever:before {
  content: "\e922";
}

.mio_delete_outline:before {
  content: "\e923";
}

.mio_delete_sweep:before {
  content: "\e924";
}

.mio_departure_board:before {
  content: "\e925";
}

.mio_description:before {
  content: "\e926";
}

.mio_desktop_access_disabled:before {
  content: "\e927";
}

.mio_desktop_mac:before {
  content: "\e928";
}

.mio_desktop_windows:before {
  content: "\e929";
}

.mio_details:before {
  content: "\e92a";
}

.mio_developer_board:before {
  content: "\e92b";
}

.mio_developer_mode:before {
  content: "\e92c";
}

.mio_device_hub:before {
  content: "\e92d";
}

.mio_devices:before {
  content: "\e92e";
}

.mio_devices_other:before {
  content: "\e92f";
}

.mio_device_unknown:before {
  content: "\e930";
}

.mio_dialer_sip:before {
  content: "\e931";
}

.mio_dialpad:before {
  content: "\e932";
}

.mio_directions:before {
  content: "\e933";
}

.mio_directions_bike:before {
  content: "\e934";
}

.mio_directions_boat:before {
  content: "\e935";
}

.mio_directions_bus:before {
  content: "\e936";
}

.mio_directions_car:before {
  content: "\e937";
}

.mio_directions_railway:before {
  content: "\e938";
}

.mio_directions_run:before {
  content: "\e939";
}

.mio_directions_subway:before {
  content: "\e93a";
}

.mio_directions_transit:before {
  content: "\e93b";
}

.mio_directions_walk:before {
  content: "\e93c";
}

.mio_disc_full:before {
  content: "\e93d";
}

.mio_dns:before {
  content: "\e93e";
}

.mio_dock:before {
  content: "\e93f";
}

.mio_domain:before {
  content: "\e940";
}

.mio_domain_disabled:before {
  content: "\e941";
}

.mio_done:before {
  content: "\e942";
}

.mio_done_all:before {
  content: "\e943";
}

.mio_done_outline:before {
  content: "\e944";
}

.mio_donut_large:before {
  content: "\e945";
}

.mio_donut_small:before {
  content: "\e946";
}

.mio_drafts:before {
  content: "\e947";
}

.mio_drag_handle:before {
  content: "\e948";
}

.mio_drag_indicator:before {
  content: "\e949";
}

.mio_drive_eta:before {
  content: "\e94a";
}

.mio_duo:before {
  content: "\e94b";
}

.mio_dvr:before {
  content: "\e94c";
}

.mio_cached:before {
  content: "\e94d";
}

.mio_cake:before {
  content: "\e94e";
}

.mio_calendar_today:before {
  content: "\e94f";
}

.mio_calendar_view_day:before {
  content: "\e950";
}

.mio_call:before {
  content: "\e951";
}

.mio_call_end:before {
  content: "\e952";
}

.mio_call_made:before {
  content: "\e953";
}

.mio_call_merge:before {
  content: "\e954";
}

.mio_call_missed:before {
  content: "\e955";
}

.mio_call_missed_outgoing:before {
  content: "\e956";
}

.mio_call_received:before {
  content: "\e957";
}

.mio_call_split:before {
  content: "\e958";
}

.mio_call_to_action:before {
  content: "\e959";
}

.mio_camera:before {
  content: "\e95a";
}

.mio_camera_alt:before {
  content: "\e95b";
}

.mio_camera_enhance:before {
  content: "\e95c";
}

.mio_camera_front:before {
  content: "\e95d";
}

.mio_camera_rear:before {
  content: "\e95e";
}

.mio_camera_roll:before {
  content: "\e95f";
}

.mio_cancel:before {
  content: "\e960";
}

.mio_cancel_presentation:before {
  content: "\e961";
}

.mio_card_giftcard:before {
  content: "\e962";
}

.mio_card_membership:before {
  content: "\e963";
}

.mio_card_travel:before {
  content: "\e964";
}

.mio_casino:before {
  content: "\e965";
}

.mio_cast:before {
  content: "\e966";
}

.mio_cast_connected:before {
  content: "\e967";
}

.mio_cast_for_education:before {
  content: "\e968";
}

.mio_category:before {
  content: "\e969";
}

.mio_cell_wifi .path1:before {
  content: "\e96a";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_cell_wifi .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_center_focus_strong:before {
  content: "\e96c";
}

.mio_center_focus_weak:before {
  content: "\e96d";
}

.mio_change_history:before {
  content: "\e96e";
}

.mio_chat:before {
  content: "\e96f";
}

.mio_chat_bubble:before {
  content: "\e970";
}

.mio_chat_bubble_outline:before {
  content: "\e971";
}

.mio_check:before {
  content: "\e972";
}

.mio_check_box:before {
  content: "\e973";
}

.mio_check_box_outline_blank:before {
  content: "\e974";
}

.mio_check_circle:before {
  content: "\e975";
}

.mio_check_circle_outline:before {
  content: "\e976";
}

.mio_chevron_left:before {
  content: "\e977";
}

.mio_chevron_right:before {
  content: "\e978";
}

.mio_child_care:before {
  content: "\e979";
}

.mio_child_friendly:before {
  content: "\e97a";
}

.mio_chrome_reader_mode:before {
  content: "\e97b";
}

.mio_class:before {
  content: "\e97c";
}

.mio_clear:before {
  content: "\e97d";
}

.mio_clear_all:before {
  content: "\e97e";
}

.mio_close:before {
  content: "\e97f";
}

.mio_closed_caption:before {
  content: "\e980";
}

.mio_cloud:before {
  content: "\e981";
}

.mio_cloud_circle:before {
  content: "\e982";
}

.mio_cloud_done:before {
  content: "\e983";
}

.mio_cloud_download:before {
  content: "\e984";
}

.mio_cloud_off:before {
  content: "\e985";
}

.mio_cloud_queue:before {
  content: "\e986";
}

.mio_cloud_upload:before {
  content: "\e987";
}

.mio_code:before {
  content: "\e988";
}

.mio_collections:before {
  content: "\e989";
}

.mio_collections_bookmark:before {
  content: "\e98a";
}

.mio_colorize:before {
  content: "\e98b";
}

.mio_color_lens:before {
  content: "\e98c";
}

.mio_comment:before {
  content: "\e98d";
}

.mio_commute:before {
  content: "\e98e";
}

.mio_compare:before {
  content: "\e98f";
}

.mio_compare_arrows:before {
  content: "\e990";
}

.mio_compass_calibration:before {
  content: "\e991";
}

.mio_computer:before {
  content: "\e992";
}

.mio_confirmation_number:before {
  content: "\e993";
}

.mio_contact_mail:before {
  content: "\e994";
}

.mio_contact_phone:before {
  content: "\e995";
}

.mio_contacts:before {
  content: "\e996";
}

.mio_contact_support:before {
  content: "\e997";
}

.mio_control_camera:before {
  content: "\e998";
}

.mio_control_point:before {
  content: "\e999";
}

.mio_control_point_duplicate:before {
  content: "\e99a";
}

.mio_copyright:before {
  content: "\e99b";
}

.mio_create:before {
  content: "\e99c";
}

.mio_create_new_folder:before {
  content: "\e99d";
}

.mio_credit_card:before {
  content: "\e99e";
}

.mio_crop:before {
  content: "\e99f";
}

.mio_crop_3_2:before {
  content: "\e9a0";
}

.mio_crop_5_4:before {
  content: "\e9a1";
}

.mio_crop_7_5:before {
  content: "\e9a2";
}

.mio_crop_16_9:before {
  content: "\e9a3";
}

.mio_crop_din:before {
  content: "\e9a4";
}

.mio_crop_free:before {
  content: "\e9a5";
}

.mio_crop_landscape:before {
  content: "\e9a6";
}

.mio_crop_original:before {
  content: "\e9a7";
}

.mio_crop_portrait:before {
  content: "\e9a8";
}

.mio_crop_rotate:before {
  content: "\e9a9";
}

.mio_crop_square:before {
  content: "\e9aa";
}

.mio_backspace:before {
  content: "\e9ab";
}

.mio_backup:before {
  content: "\e9ac";
}

.mio_ballot:before {
  content: "\e9ad";
}

.mio_bar_chart:before {
  content: "\e9ae";
}

.mio_battery_20 .path1:before {
  content: "\e9af";
  color: rgb(0, 0, 0);
}

.mio_battery_20 .path2:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_30 .path1:before {
  content: "\e9b1";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_30 .path2:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_50 .path1:before {
  content: "\e9b3";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_50 .path2:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_60 .path1:before {
  content: "\e9b5";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_60 .path2:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_80 .path1:before {
  content: "\e9b7";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_80 .path2:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_90 .path1:before {
  content: "\e9b9";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_90 .path2:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_alert:before {
  content: "\e9bb";
}

.mio_battery_charging_20 .path1:before {
  content: "\e9bc";
  color: rgb(0, 0, 0);
}

.mio_battery_charging_20 .path2:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_charging_30 .path1:before {
  content: "\e9be";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_charging_30 .path2:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_charging_50 .path1:before {
  content: "\e9c0";
  color: rgb(0, 0, 0);
}

.mio_battery_charging_50 .path2:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_charging_60 .path1:before {
  content: "\e9c2";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_charging_60 .path2:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_charging_80 .path1:before {
  content: "\e9c4";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_charging_80 .path2:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_charging_90 .path1:before {
  content: "\e9c6";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.mio_battery_charging_90 .path2:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.mio_battery_charging_full:before {
  content: "\e9c8";
}

.mio_battery_full:before {
  content: "\e9c9";
}

.mio_battery_std:before {
  content: "\e9ca";
}

.mio_battery_unknown:before {
  content: "\e9cb";
}

.mio_beach_access:before {
  content: "\e9cc";
}

.mio_beenhere:before {
  content: "\e9cd";
}

.mio_block:before {
  content: "\e9ce";
}

.mio_bluetooth:before {
  content: "\e9cf";
}

.mio_bluetooth_audio:before {
  content: "\e9d0";
}

.mio_bluetooth_connected:before {
  content: "\e9d1";
}

.mio_bluetooth_disabled:before {
  content: "\e9d2";
}

.mio_bluetooth_searching:before {
  content: "\e9d3";
}

.mio_blur_circular:before {
  content: "\e9d4";
}

.mio_blur_linear:before {
  content: "\e9d5";
}

.mio_blur_off:before {
  content: "\e9d6";
}

.mio_blur_on:before {
  content: "\e9d7";
}

.mio_book:before {
  content: "\e9d8";
}

.mio_bookmark:before {
  content: "\e9d9";
}

.mio_bookmark_border:before {
  content: "\e9da";
}

.mio_bookmarks:before {
  content: "\e9db";
}

.mio_border_all:before {
  content: "\e9dc";
}

.mio_border_bottom:before {
  content: "\e9dd";
}

.mio_border_clear:before {
  content: "\e9de";
}

.mio_border_color .path1:before {
  content: "\e9df";
  color: rgb(0, 0, 0);
}

.mio_border_color .path2:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.36;
}

.mio_border_horizontal:before {
  content: "\e9e1";
}

.mio_border_inner:before {
  content: "\e9e2";
}

.mio_border_left:before {
  content: "\e9e3";
}

.mio_border_outer:before {
  content: "\e9e4";
}

.mio_border_right:before {
  content: "\e9e5";
}

.mio_border_style:before {
  content: "\e9e6";
}

.mio_border_top:before {
  content: "\e9e7";
}

.mio_border_vertical:before {
  content: "\e9e8";
}

.mio_branding_watermark:before {
  content: "\e9e9";
}

.mio_brightness_1:before {
  content: "\e9ea";
}

.mio_brightness_2:before {
  content: "\e9eb";
}

.mio_brightness_3:before {
  content: "\e9ec";
}

.mio_brightness_4:before {
  content: "\e9ed";
}

.mio_brightness_5:before {
  content: "\e9ee";
}

.mio_brightness_6:before {
  content: "\e9ef";
}

.mio_brightness_7:before {
  content: "\e9f0";
}

.mio_brightness_auto:before {
  content: "\e9f1";
}

.mio_brightness_high:before {
  content: "\e9f2";
}

.mio_brightness_low:before {
  content: "\e9f3";
}

.mio_brightness_medium:before {
  content: "\e9f4";
}

.mio_broken_image:before {
  content: "\e9f5";
}

.mio_brush:before {
  content: "\e9f6";
}

.mio_bubble_chart:before {
  content: "\e9f7";
}

.mio_bug_report:before {
  content: "\e9f8";
}

.mio_build:before {
  content: "\e9f9";
}

.mio_burst_mode:before {
  content: "\e9fa";
}

.mio_business:before {
  content: "\e9fb";
}

.mio_business_center:before {
  content: "\e9fc";
}

.mio_access_alarm:before {
  content: "\ea00";
}

.mio_access_alarms:before {
  content: "\ea01";
}

.mio_accessibility:before {
  content: "\ea02";
}

.mio_accessibility_new:before {
  content: "\ea03";
}

.mio_accessible:before {
  content: "\ea04";
}

.mio_accessible_forward:before {
  content: "\ea05";
}

.mio_access_time:before {
  content: "\ea06";
}

.mio_account_balance:before {
  content: "\ea07";
}

.mio_account_balance_wallet:before {
  content: "\ea08";
}

.mio_account_box:before {
  content: "\ea09";
}

.mio_account_circle:before {
  content: "\ea0a";
}

.mio_ac_unit:before {
  content: "\ea0b";
}

.mio_adb:before {
  content: "\ea0c";
}

.mio_add:before {
  content: "\ea0d";
}

.mio_add_alarm:before {
  content: "\ea0e";
}

.mio_add_alert:before {
  content: "\ea0f";
}

.mio_add_a_photo:before {
  content: "\ea10";
}

.mio_add_box:before {
  content: "\ea11";
}

.mio_add_circle:before {
  content: "\ea12";
}

.mio_add_circle_outline:before {
  content: "\ea13";
}

.mio_add_comment:before {
  content: "\ea14";
}

.mio_add_location:before {
  content: "\ea15";
}

.mio_add_photo_alternate:before {
  content: "\ea16";
}

.mio_add_shopping_cart:before {
  content: "\ea17";
}

.mio_add_to_home_screen:before {
  content: "\ea18";
}

.mio_add_to_photos:before {
  content: "\ea19";
}

.mio_add_to_queue:before {
  content: "\ea1a";
}

.mio_adjust:before {
  content: "\ea1b";
}

.mio_airline_seat_flat:before {
  content: "\ea1c";
}

.mio_airline_seat_flat_angled:before {
  content: "\ea1d";
}

.mio_airline_seat_individual_suite:before {
  content: "\ea1e";
}

.mio_airline_seat_legroom_extra:before {
  content: "\ea1f";
}

.mio_airline_seat_legroom_normal:before {
  content: "\ea20";
}

.mio_airline_seat_legroom_reduced:before {
  content: "\ea21";
}

.mio_airline_seat_recline_extra:before {
  content: "\ea22";
}

.mio_airline_seat_recline_normal:before {
  content: "\ea23";
}

.mio_airplanemode_active:before {
  content: "\ea24";
}

.mio_airplanemode_inactive:before {
  content: "\ea25";
}

.mio_airplay:before {
  content: "\ea26";
}

.mio_airport_shuttle:before {
  content: "\ea27";
}

.mio_alarm:before {
  content: "\ea28";
}

.mio_alarm_add:before {
  content: "\ea29";
}

.mio_alarm_off:before {
  content: "\ea2a";
}

.mio_alarm_on:before {
  content: "\ea2b";
}

.mio_album:before {
  content: "\ea2c";
}

.mio_all_inbox:before {
  content: "\ea2d";
}

.mio_all_inclusive:before {
  content: "\ea2e";
}

.mio_all_out:before {
  content: "\ea2f";
}

.mio_alternate_email:before {
  content: "\ea30";
}

.mio_android:before {
  content: "\ea31";
}

.mio_announcement:before {
  content: "\ea32";
}

.mio_apps:before {
  content: "\ea33";
}

.mio_archive:before {
  content: "\ea34";
}

.mio_arrow_back:before {
  content: "\ea35";
}

.mio_arrow_back_ios:before {
  content: "\ea36";
}

.mio_arrow_downward:before {
  content: "\ea37";
}

.mio_arrow_drop_down:before {
  content: "\ea38";
}

.mio_arrow_drop_down_circle:before {
  content: "\ea39";
}

.mio_arrow_drop_up:before {
  content: "\ea3a";
}

.mio_arrow_forward:before {
  content: "\ea3b";
}

.mio_arrow_forward_ios:before {
  content: "\ea3c";
}

.mio_arrow_left:before {
  content: "\ea3d";
}

.mio_arrow_right:before {
  content: "\ea3e";
}

.mio_arrow_right_alt:before {
  content: "\ea3f";
}

.mio_arrow_upward:before {
  content: "\ea40";
}

.mio_art_track:before {
  content: "\ea41";
}

.mio_aspect_ratio:before {
  content: "\ea42";
}

.mio_assessment:before {
  content: "\ea43";
}

.mio_assignment:before {
  content: "\ea44";
}

.mio_assignment_ind:before {
  content: "\ea45";
}

.mio_assignment_late:before {
  content: "\ea46";
}

.mio_assignment_return:before {
  content: "\ea47";
}

.mio_assignment_returned:before {
  content: "\ea48";
}

.mio_assignment_turned_in:before {
  content: "\ea49";
}

.mio_assistant:before {
  content: "\ea4a";
}

.mio_assistant_photo:before {
  content: "\ea4b";
}

.mio_atm:before {
  content: "\ea4c";
}

.mio_attach_file:before {
  content: "\ea4d";
}

.mio_attachment:before {
  content: "\ea4e";
}

.mio_attach_money:before {
  content: "\ea4f";
}

.mio_audiotrack:before {
  content: "\ea50";
}

.mio_autorenew:before {
  content: "\ea51";
}

.mio_av_timer:before {
  content: "\ea52";
}
