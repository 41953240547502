.pul {
    font-family: 'primeicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pul:before {
    --webkit-backface-visibility:hidden;
    backface-visibility: hidden;
}

.pul-fw {
    width: 1.28571429em;
    text-align: center;
}

.pul-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.pul-all-reports:before {
  content: url('../assets/images/all-reports.svg');
}

.pul-all-reports-white:before {
  content: url('../assets/images/all-reports-white.svg');
}

.pul-all-reports-primary:before {
  content: url('../assets/images/all-reports-primary.svg');
}

.pul-campaign:before {
  content: url('../assets/images/campaign.svg');
}

.pul-campaign-white:before {
  content: url('../assets/images/campaign-white.svg');
}

.pul-campaign-primary:before {
  content: url('../assets/images/campaign-primary.svg');
}


